import { useSubscription } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { API_FACILITY_COUNT_SUBSCRIPTION, API_TOTAL_FACILITY_SUBSCRIPTION } from "../../graphql/query";
import { useTranslation } from 'react-i18next';

function generatePayload(data) {
    let state = [];
    if (data)
        data?.forEach(item => {
            if (item?.api_facilities_aggregate?.aggregate?.count > 0)
                state.push({ count: item?.api_facilities_aggregate?.aggregate?.count, name: item?.name })
        })
    return state;
}


const RegisteredFacility = ({ boxColor, facilityFilter }) => {

    const { data } = useSubscription(API_FACILITY_COUNT_SUBSCRIPTION, {
        variables: {
            facility_id: facilityFilter?.name === 'All' ? facilityFilter?.id : [facilityFilter?.id]
        }
    });

    const [registeredFacility, setRegisteredFacility] = useState(null);
    const [totalFacilities, setTotalFacilities] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        const facilities = generatePayload(data?.api_state);
        if (facilities.length > 0) {
            setRegisteredFacility(facilities);
            setTotalFacilities(facilities.reduce((acc, item) => acc + item?.count, 0));
        }
    }, [data, facilityFilter])

    return (
        <Grid container
            display='flex'
            flexDirection='column'
            backgroundColor={boxColor}
            borderRadius='16px'
        >
            <Grid padding='20px'>
                <Grid container mb='24px' justifyContent='space-between'>
                    <Typography variant="h5"
                        component='h5'
                        sx={{
                            textTransform: 'capitalize',
                            marginBottom: '24px',
                            fontWeight: '600',
                            fontSize: '20px'
                        }}>
                                {t('registerFacilityStatus')}
                    </Typography>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='overline'  sx={{ lineHeight: '16px', textTransform: 'uppercase', marginBottom: '10px', fontSize:'14px', fontWeight:'500' , color:'#5C677D'}}>
                        {t('totalNumberOfFacilities')}
                        </Typography>
                        <Typography variant='h5' component='h5' sx={{ fontWeight: '700', fontSize:'24px' }}>
                            {totalFacilities}
                        </Typography>
                    </Grid>
                </Grid>
                {registeredFacility &&
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Typography variant='body3' component='p' textTransform='capitalize' sx={{fontWeight: '500', fontSize:'14px'}}>
                        {t('numberOfRegistrationByFacilitiesType')}
                        </Typography>
                        <Grid container spacing={2} marginTop='5px'>
                            {
                                registeredFacility?.map((item, index) => <Grid item md={4} xs={6} key={index}>
                                    <Grid container backgroundColor='#EFF4FF' padding='12px 16px' borderRadius='8px' justifyContent='space-between' flexWrap='nowrap' >
                                        <Typography variant='overline' component='span' mr={0.6} sx={{ textTransform: 'capitalize', lineHeight: '16px' ,fontSize:'14px', fontWeight:'500',colro:'#5C677D'}}>
                                            {item?.name}
                                        </Typography>
                                        <Typography variant="overline" component='span' sx={{ lineHeight: '16px', fontWeight: '700' ,fontSize:'14px'}}>
                                            {item?.count}
                                        </Typography>
                                    </Grid>
                                </Grid>)
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid >
    );
}
export default RegisteredFacility;