import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomInput from '../customComponents/CustomInput';
import CustomFileUpload from '../customComponents/CustomFileUpload';
import CustomMultilineInput from '../customComponents/CustomMultiLineInput';


const CreateNewApp = ({ onSave }) => {
    const [formValues, setFormValues] = useState({});

    const handleInputChange = (inputName, inputValue) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [inputName]:
                inputValue !== null
                    ? typeof inputValue === "string" || typeof inputValue === "number"
                        ? inputValue
                        : typeof inputValue === "boolean"
                            ? Boolean(inputValue)
                            : { ...inputValue }
                    : "", // Set to blank value if inputValue is null
        }));
    };

    const handleSave = async () => {
        onSave();
    }
    return (
        <Box p={0}>
            <Typography p={0} variant="body1" color="initial">Create your app</Typography>
            <Box style={{ marginTop: "16px" }}>
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"App name"} placeholder={"Enter your app name"} required={true} onValueChange={(value) => handleInputChange('app_name', value)} />
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"Short description"} placeholder={"Add a short description"} required={true} onValueChange={(value) => handleInputChange('short_desc', value)} />
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomMultilineInput label={"Long description"} placeholder={"Add a detailed description for your app that will appear on the App Detail page. Your description can be formatted with bold text, lists, and other elements."} required={true} onValueChange={(value) => handleInputChange('long_desc', value)} />
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomFileUpload label={"Please upload the app icon and preview"} placeholder={"Add a short description"} required={false} onValueChange={(value) => handleInputChange('app_icon', value)} />
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"Privacy policy URL"} placeholder={"yourdomain.com/privacy/policy"} required={true} onValueChange={(value) => handleInputChange('privacy_policy', value)} />
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"Terms of service URL"} placeholder={"yourdomain.com/terms"} required={true} onValueChange={(value) => handleInputChange('terms', value)} />
                </Grid>
                <Grid item xs={12} mb={"16px"} justifyContent="center" display={"flex"}>
                    <Button
                        variant="contained"
                        style={{
                            borderRadius: "28px",
                            padding: "16px",
                            height: "40px",
                            backgroundColor: "#467CEB",
                            textTransform: "capitalize",
                        }}
                        onClick={handleSave}
                    >
                        {"save"}
                    </Button>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreateNewApp