


export const GbtOn = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
                </defs>
                <g transform="translate(0.397)">
                    <circle class="a" cx="25" cy="25" r="25" transform="translate(-0.397)" />
                    <g transform="translate(13 14)">
                        <path class="b" d="M358.7,59.053a12.344,12.344,0,0,0-3.192-2.813h-12.9A12.361,12.361,0,1,0,358.7,59.053Zm-9.64,17.677A9.946,9.946,0,0,1,342.8,59.053h12.514a9.946,9.946,0,0,1-6.255,17.677Z" transform="translate(-336.7 -56.24)" />
                        <path class="b" d="M362.832,72.944A2.142,2.142,0,1,0,360.69,70.8,2.141,2.141,0,0,0,362.832,72.944Zm0-2.966a.824.824,0,1,1-.824.824A.824.824,0,0,1,362.832,69.978Z" transform="translate(-353.37 -64.87)" />
                        <path class="b" d="M382.392,72.944A2.142,2.142,0,1,0,380.25,70.8,2.141,2.141,0,0,0,382.392,72.944Zm0-2.966a.824.824,0,1,1-.824.824A.822.822,0,0,1,382.392,69.978Z" transform="translate(-366.961 -64.87)" />
                        <path class="b" d="M362.13,99.5a2.45,2.45,0,1,0,2.45,2.45A2.448,2.448,0,0,0,362.13,99.5Zm0,3.39a.943.943,0,1,1,.943-.943A.943.943,0,0,1,362.13,102.89Z" transform="translate(-352.668 -86.299)" />
                        <path class="b" d="M381.69,99.5a2.45,2.45,0,1,0,2.45,2.45A2.448,2.448,0,0,0,381.69,99.5Zm0,3.39a.943.943,0,1,1,.943-.943A.942.942,0,0,1,381.69,102.89Z" transform="translate(-366.259 -86.299)" />
                        <path class="b" d="M374.361,85.22a2.45,2.45,0,1,0-2.45,2.45A2.45,2.45,0,0,0,374.361,85.22Zm-2.45.94a.943.943,0,1,1,.943-.943A.944.944,0,0,1,371.91,86.16Z" transform="translate(-359.464 -74.675)" />
                        <path class="b" d="M354.691,85.22a2.45,2.45,0,1,0-2.45,2.45A2.45,2.45,0,0,0,354.691,85.22Zm-2.453.94a.943.943,0,1,1,.943-.943A.943.943,0,0,1,352.237,86.16Z" transform="translate(-345.796 -74.675)" />
                        <path class="b" d="M391.47,82.76a2.45,2.45,0,1,0,2.45,2.45A2.45,2.45,0,0,0,391.47,82.76Zm0,3.393a.943.943,0,1,1,.943-.943A.943.943,0,0,1,391.47,86.153Z" transform="translate(-373.055 -74.668)" />
                    </g>
                </g>
            </svg>
        </>
    );
}