import React from 'react'

const VehicleBlue = () => {
    return (

        <svg width="110" height="115" viewBox="0 0 110 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_30315)">
                <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="8.5" width="83" height="83" rx="41.5" stroke="#B5CBF7" stroke-width="3" />
                <path d="M70.5799 53.8371V57.7891H68.2239C67.8439 55.8511 66.1719 54.3691 64.1199 54.3691C62.0679 54.3691 60.3579 55.8511 60.0159 57.7891H57.6599V44.8691H61.6879C62.4859 44.8691 63.2079 45.2491 63.6259 45.9331L65.9819 49.6951C66.4379 50.4171 67.1219 50.9491 67.9199 51.2531L69.0979 51.6711C69.9719 52.0131 70.5799 52.8871 70.5799 53.8371ZM39.4199 57.7891H42.9159C43.2959 55.8511 44.9679 54.3691 47.0199 54.3691C49.0719 54.3691 50.7819 55.8511 51.1239 57.7891H56.1399V52.4691H39.4199V57.7891ZM53.8599 50.9491H56.1399V38.7891H53.8599V50.9491ZM41.6999 38.7891H39.4199V50.9491H41.6999V38.7891ZM64.1199 55.8891C62.6379 55.8891 61.4599 57.0671 61.4599 58.5491C61.4599 60.0311 62.6379 61.2091 64.1199 61.2091C65.6019 61.2091 66.7799 60.0311 66.7799 58.5491C66.7799 57.0671 65.6019 55.8891 64.1199 55.8891ZM47.0199 55.8891C45.5379 55.8891 44.3599 57.0671 44.3599 58.5491C44.3599 60.0311 45.5379 61.2091 47.0199 61.2091C48.5019 61.2091 49.6799 60.0311 49.6799 58.5491C49.6799 57.0671 48.5019 55.8891 47.0199 55.8891ZM52.3399 47.9091H43.2199V50.9491H52.3399V47.9091ZM52.3399 43.3491H43.2199V46.3891H52.3399V43.3491ZM52.3399 38.7891H43.2199V41.8291H52.3399V38.7891Z" fill="#467CEB" />
            </g>
            <circle cx="55" cy="50" r="49.8613" stroke="#467CEB" stroke-width="0.277344" stroke-dasharray="1.66 1.66" />
            <defs>
                <filter id="filter0_d_9271_30315" x="0" y="7" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_30315" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_30315" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default VehicleBlue