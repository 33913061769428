import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';
import config from '../config';

export const fetchEditVehicleForm = createAsyncThunk('editVehicle/fetchEditVehicleForm', async (vehicleId, { rejectWithValue }) => {
  try {
    const response = await api.get(config.TELEMATICS_URL + `vehicle/edit/${vehicleId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching edt station form', error);
    throw rejectWithValue(error);
  }
});

const EditVehicleSlice = createSlice({
  name: 'editVehicle',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditVehicleForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchEditVehicleForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditVehicleForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default EditVehicleSlice.reducer;
