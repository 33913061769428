import React from "react";
import { Grid, Typography } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

const FilterButton = ({ onClick }) => (
  <Grid
    onClick={onClick}
    style={{
      width: "100px",
      display: "flex",
      padding: "8px 16px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderRadius: "20px",
      border: "1px solid var(--light-mode-gray-30, #EAEBEE)",
      background: "var(--color-styles-white, #FFF)",
      cursor: "pointer",
    }}
  >
    <TuneIcon
      style={{
        display: "flex",
        width: "14px",
        height: "14px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    />
    <Typography
      style={{
        color: "var(--light-mode-gray-80, #5C677D)",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",
      }}
    >
      Filters
    </Typography>
  </Grid>
);

export default FilterButton;
