import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';
import config from '../config';

export const fetchDealerList = createAsyncThunk('dealerList/fetchDealerList', async () => {
  try {
    const response = await api(config.TELEMATICS_URL + 'dealers/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data;
  } catch (error) {
    console.error('Error fetching station list:', error);
    throw error;
  }
});

const DealerListSlice = createSlice({
  name: 'dealerList',
  initialState: {
    data: [],
    isLoading: false,
    selectedDealer: { name: 'All', id: [] },
    error: null,
  },
  reducers: {
    setSelectedDealer: (state, action) => {
      state.selectedDealer = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealerList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDealerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        if (action.payload?.data?.dealers.length > 0) {
          const allFilter = { name: 'All', id: action.payload.data.dealers.map(item => item.id) };
          state.dealers = [allFilter, ...(action.payload.data.dealers)];
          state.selectedDealer = allFilter;
        }
      })
      .addCase(fetchDealerList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedDealer } = DealerListSlice.actions;
export default DealerListSlice.reducer;
