import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";
import { toast } from 'react-toastify';

export const callDynamicTableApi = createAsyncThunk(
  "callDynamicTableApi",
  async (url) => {
    try {
      const response = await api.post(`${url}`);
      return response.data;
    } catch (error) {
      console.error("Error", error);
      return error.response.data;
    }
  }
);

const DynamicTableApiCallSlice = createSlice({
  name: "dynamicTableApiCall",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(callDynamicTableApi.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(callDynamicTableApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        if (action.payload?.success)
          toast.success(action.payload?.message);
        else
          toast.error(action.payload?.message);

      })
      .addCase(callDynamicTableApi.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default DynamicTableApiCallSlice.reducer;
