import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import RouteMap from './RouteMap';


const VehicleTabs = ({ data }) => {
    const mapData = data?.tab_content?.find(map => map.type == "map");
    const vehicle_Data = data?.tab_content?.filter(item => item.type == "display")
    return (
        <>
            {data?.name === "Overview" && (
                <RouteMap mapData={mapData} vehicle_Data={vehicle_Data} />
            )}
            {data?.name === "Details" && (
                data?.tab_content.length == 0 ? "No Data Found" : (
                    <TableContainer style={{ width: '100%', overflowX: 'auto' }}>
                        <Table style={{ minWidth: '100%' }}>
                            <TableBody>
                                {data.tab_content?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.value}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
            {data?.name === "Documents" && (
                data?.tab_content.length == 0 ? "No Data Found" : (
                    <TableContainer style={{ width: '100%', overflowX: 'auto' }}>
                        <Table style={{ minWidth: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Value
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.tab_content?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.value}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
        </>
    );
};

export default VehicleTabs;