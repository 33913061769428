import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const CustomHidden = ({
  label,
  placeholder,
  col_size,
  required,
  value: propValue,
  onValueChange,
}) => {
  const [value, setValue] = useState("");
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setValue(propValue!== null ? propValue:"");
  }, [propValue]);


  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onValueChange(newValue);
    setHelperText("");
  };

  const onBlur = () => {
    if (required && !value.trim()) {
      setHelperText("Please enter a value");
    }
  };

  return (
    <Grid item xs={col_size} style={{display: "none"}}>
      <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      <TextField
        style={{ marginLeft: "0" }}
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        helperText={
          <Typography
            variant="caption"
            color="error"
            style={{ marginTop: "5px", marginLeft: "0" }}
          >
            {helperText}
          </Typography>
        }
        error={Boolean(helperText)}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          style: {
            borderRadius: "12px",
            display: "flex",
            height: "44px",
            padding: "12px",
            alignItems: "center",
            gap: "12px",
          },
        }}
        required={required}
      />
    </Grid>
  );
};

export default CustomHidden;
