import React from "react";

const ProfitIcon = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 110 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6118_40727)">
          <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
          <rect
            x="13.5"
            y="8.5"
            width="83"
            height="83"
            rx="41.5"
            stroke="#B5CBF7"
            stroke-width="3"
          />
          <g filter="url(#filter1_b_6118_40727)">
            <path
              d="M43 59C43 59.5625 43.4375 60 44 60H69C70.0625 60 71 60.9375 71 62C71 63.125 70.0625 64 69 64H44C41.1875 64 39 61.8125 39 59V38C39 36.9375 39.875 36 41 36C42.0625 36 43 36.9375 43 38V59ZM60.375 51.4375C59.625 52.25 58.3125 52.25 57.5625 51.4375L54 47.875L48.375 53.4375C47.625 54.25 46.3125 54.25 45.5625 53.4375C44.75 52.6875 44.75 51.375 45.5625 50.625L52.5625 43.625C53.3125 42.8125 54.625 42.8125 55.375 43.625L59 47.1875L65.5625 40.625C66.3125 39.8125 67.625 39.8125 68.375 40.625C69.1875 41.375 69.1875 42.6875 68.375 43.4375L60.375 51.4375Z"
              fill="#467CEB"
            />
          </g>
        </g>
        <circle
          cx="55"
          cy="50"
          r="49.8613"
          stroke="#467CEB"
          stroke-width="0.277344"
          stroke-dasharray="1.66 1.66"
        />
        <defs>
          <filter
            id="filter0_d_6118_40727"
            x="0"
            y="7"
            width="110"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6118_40727"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6118_40727"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_6118_40727"
            x="26.4783"
            y="23.4783"
            width="57.0435"
            height="53.0435"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.26087" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6118_40727"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6118_40727"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default ProfitIcon;
