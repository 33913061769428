import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import api from '../../apiSetup/apiCall';
import config from '../../config';

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
        backgroundColor: 'white',
    },
});

export default function VehicleSearch() {
    const [vehicleData, setVehicleData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const debounceTimeout = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (inputValue.length > 0) {
            const fetchVehicleData = async () => {
                setLoading(true);
                try {
                    const response = await api(config.TELEMATICS_URL + `search/vehicles/${inputValue}/`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (response && response.data) {
                        setVehicleData(response.data.results);
                    } else {
                        setVehicleData([]);
                    }
                } catch (error) {
                    console.error('Error fetching vehicle data:', error);
                    setVehicleData([]);
                } finally {
                    setLoading(false);
                }
            };

            // Debounce the API call
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }

            debounceTimeout.current = setTimeout(fetchVehicleData, 500);
        } else {
            setVehicleData([]);
        }

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [inputValue]);

    const handleSelect = (event, value) => {
        if (value) {
            navigate(`/telematics/dealer-management/${value.dealer}/${value.sub_dealer}/${value.id}`);
        }
    };

    return (
        <StyledAutocomplete
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            onChange={handleSelect}
            disablePortal
            id="vehicle-search"
            options={vehicleData}
            getOptionLabel={(option) =>
                `${option.license_plate_number}${option.chasis_number ? ` | ${option.chasis_number}` : ''}`
            }
            sx={{ width: "300px" }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search Vehicle"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading ? <CircularProgress size={24} /> : null}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            size="small"
        />
    );
}