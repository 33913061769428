import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import api from "../../apiSetup/apiCall";
import DynamicFormModal from "./DynamicFormModal";
import { useDispatch } from "react-redux";
import { setUserId } from "../../slice/EditUserSlice";
import { setUserPhone } from "../../slice/EditCouponSlice";

const CustomPhoneNumber = ({
  label,
  placeholder,
  col_size,
  required,
  value: propValue,
  onValueChange,
  errorMessage,
  isError
}) => {
  const [value, setValue] = useState("");
  const [helperText, setHelperText] = useState("");

  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [endPoint, setEndPoint] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(propValue!== null ? propValue:"");
  }, [propValue]);

  // useEffect(() => {
  //   onValueChange("");
  // }, []);


  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, "");
    setValue(newValue);
    onValueChange(newValue);
    setHelperText("");
  };

  const handleModalOpen = () => {
    const modalType = localStorage.getItem("dynamicModalType");
    let userType = "";
      if(modalType === "addUser") {
        userType = "editUser"
      }
      else if(modalType === "addAdmin") {
        userType = "editAdmin"
      }
    localStorage.setItem("dynamicModalType", userType);
    setIsDynamicModalOpen(true);
  };

  const onBlur = async () => {
    if (required && !value.trim()) {
      setHelperText("Please enter a phone number");
      return;
    }

    try {
      const modalType = localStorage.getItem("dynamicModalType")
      let userType = "";
      if(modalType === "addUser") {
        userType = "user"
      }
      else if(modalType === "addAdmin" || modalType === "editCoupon") {
        userType = "admin"
      }
      if((modalType === "addUser" || modalType === "addAdmin" || modalType === "editCoupon") && value !== "") {
        const response = await api.get(`prometheus/search/${userType}/${value}/`);
        if (modalType!== "editCoupon")
        dispatch(setUserId(response?.data?.data?.user_id))
        else 
        dispatch(setUserPhone(response?.data?.data?.phone))
        if (response?.data?.data?.api !== false && modalType!== "editCoupon") {
          setEndPoint(response?.data?.data?.api)
          handleModalOpen();
        }
    }
    } catch (error) {
      console.error("Error in API calls:", error);
    }
  };

  return (
    <>
    <Grid item xs={col_size}>
      <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      <TextField
        style={{ marginLeft: "0" }}
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        helperText={<Typography
          variant="caption"
          color="error"
          style={{ marginTop: "5px", marginLeft: "0" }}
        >
          {isError ? errorMessage :  helperText}
        </Typography>}
        error={isError}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          style: {
            borderRadius: "12px",
            display: "flex",
            height: "44px",
            padding: "12px",
            alignItems: "center",
            gap: "12px",
          },
        }}
        FormHelperTextProps={{
          style: { marginLeft: "0" }
        }}
        required={required}
      />
    </Grid>
    <DynamicFormModal
      isOpen={isDynamicModalOpen}
      onClose={() => setIsDynamicModalOpen(false)}
      editTabEndpoint={endPoint}             
    />
  </>
  );
};

export default CustomPhoneNumber;

