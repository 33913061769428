import React from "react";
import CustomSearch from "../customComponents/CustomSearch";

const SearchButton = ({ placeholder, handleOnSearch, value }) => {
  return (
    <CustomSearch
      placeholder={placeholder}
      onSearch={handleOnSearch}
      value={value}
    />
  );
};

export default SearchButton;
