import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import api from "../../apiSetup/apiCall";
import { getCurrentLocation } from "../../utilis/common";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const mapContainerStyle = { width: "100%", height: "calc(100vh - 120px)" };

const DealersMap = ({ dealerData }) => {
    const [dealersList, setDealersList] = useState([]);
    const [currentLocation, setCurrentLocation] = useState({ latitude: null, longitude: null });
    const [loading, setLoading] = useState(true)

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    const transformLocation = (location) => {
        if (location && location !== undefined) {
            return { lat: parseFloat(location.split(',')[0]), lng: parseFloat(location.split(',')[1]) };
        }
        return null;
    };

    const loadFacilityList = async () => {
        try {
            if (dealerData.dealers.length > 0) {
                const transformedData = dealerData.dealers.map(dealers => ({
                    ...dealers,
                    location: transformLocation(dealers.coordinates),
                }));
                setDealersList(transformedData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleOnLoad = useCallback((map) => {
        if (!window.google) {
            console.error('Google Maps JavaScript API not loaded.');
            return;
        }

        const bounds = new window.google.maps.LatLngBounds();
        dealersList.forEach(({ location }) => {
            if (location) {
                bounds.extend(location);
            }
        });
        map.fitBounds(bounds); // show all the marker
    }, []);

    useEffect(() => {
        loadFacilityList();
    }, [dealerData]);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const location = await getCurrentLocation();
                setCurrentLocation({ latitude: location.latitude, longitude: location.longitude });
                setLoading(false)
            } catch (error) {
                console.error('Error getting current location:', error);
            }
        };

        fetchLocation();
    }, []);

    if (!dealersList.length || !currentLocation.latitude || !currentLocation.longitude) {
        return null;
    }

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    const { latitude, longitude } = currentLocation;

    const customMarkerSvg = `
    <svg width="31" height="48" viewBox="0 0 31 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 27C22.9561 27 29 20.956 29 13.5C29 6.04395 22.9561 0 15.5 0C8.04395 0 2 6.04395 2 13.5C2 20.956 8.04395 27 15.5 27ZM19.55 10.8C19.55 11.8741 19.1233 12.9043 18.3638 13.6638C17.6043 14.4233 16.5741 14.85 15.5 14.85C14.4259 14.85 13.3957 14.4233 12.6362 13.6638C11.8767 12.9043 11.45 11.8741 11.45 10.8C11.45 9.72587 11.8767 8.69574 12.6362 7.93622C13.3957 7.17669 14.4259 6.75 15.5 6.75C16.5741 6.75 17.6043 7.17669 18.3638 7.93622C19.1233 8.69574 19.55 9.72587 19.55 10.8ZM7.4 20.25C8.34231 18.9917 9.56508 17.9704 10.9712 17.2674C12.3773 16.5643 13.9279 16.1988 15.5 16.2C17.0721 16.1988 18.6227 16.5643 20.0288 17.2674C21.4349 17.9704 22.6577 18.9917 23.6 20.25C22.6577 21.5083 21.4349 22.5296 20.0288 23.2326C18.6227 23.9357 17.0721 24.3012 15.5 24.3C13.9279 24.3012 12.3773 23.9357 10.9712 23.2326C9.56508 22.5296 8.34231 21.5083 7.4 20.25Z" fill="#1849D6"/>
        <path d="M14.7369 34.8287L11.4793 34.8287L11.4663 34.8285L10.4056 34.1789L7.99484 32.7006L5.51091 31.1805L14.7367 31.1781L14.7367 31.7349L14.7369 34.8287Z" fill="#001233"/>
        <path d="M9.79023 34.8252L8.4401 33.8905L7.53412 34.8274L6.23039 33.8905L5.15206 34.8274L4.82303 34.825L3.89915 33.2872L3.7248 32.9931L5.51224 31.1792L7.99617 32.6992L8.00081 32.699L10.4114 34.1731C10.4092 34.1753 10.407 34.1775 10.407 34.1775C10.148 34.4542 9.79023 34.8252 9.79023 34.8252Z" fill="#00102E"/>
        <path d="M24.2247 36.8523L24.2247 30.3778L24.2247 29.136C24.2247 28.3714 23.6037 27.7505 22.839 27.7503L16.1237 27.7525C15.3591 27.7525 14.7404 28.3712 14.7404 29.1358L14.7382 31.1753L14.7382 31.7322L14.7404 31.7344L14.7382 34.8258L14.7382 36.8499C14.7382 37.6144 15.3591 38.2353 16.1239 38.2356L22.839 38.238C23.6037 38.2378 24.2247 37.6168 24.2247 36.8523ZM22.2602 36.3374C21.836 36.3374 21.491 35.9925 21.4913 35.5684L21.4935 30.7668L21.4935 30.4309C21.491 30.0086 21.8358 29.6639 22.2602 29.6641C22.4722 29.6639 22.6624 29.7481 22.8014 29.8871C22.9404 30.0261 23.0268 30.2185 23.0268 30.4307L23.0268 30.55L23.0268 35.5704C23.027 35.9949 22.6823 36.3396 22.2602 36.3374Z" fill="#467CEB"/>
        <path opacity="0.1" d="M22.8386 38.2383L16.1233 38.2361C15.3587 38.2361 14.7378 37.6152 14.7376 36.8504V34.8263L11.4805 34.8263L11.4674 34.8265L10.4068 34.1769C10.1482 34.4531 9.79027 34.8243 9.79027 34.8243L8.44014 33.8896L7.53415 34.8265L6.23043 33.8896L5.15209 34.8265L4.82284 34.8243L3.89918 33.2864L7.99598 32.6986L8.0004 32.6986L14.7378 31.7329L14.74 31.7351L21.4929 30.7673L21.4907 35.569C21.4907 35.9933 21.8356 36.3382 22.2597 36.338C22.6817 36.3402 23.0264 35.9955 23.0264 35.5712L23.0264 30.5507L24.2241 30.3784L24.2241 36.8528C24.2241 37.6174 23.6032 38.2383 22.8386 38.2383Z" fill="black"/>
    </svg>
`;

    const customMarkerUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(customMarkerSvg);


    return (
        <div>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }} style={mapContainerStyle}>
                    <CircularProgress />
                </Box>
            ) : (
                <GoogleMap
                    onLoad={handleOnLoad}
                    center={{ lat: latitude, lng: longitude }}
                    zoom={5}
                    mapContainerStyle={mapContainerStyle}
                >
                    {dealersList.map((dealer) => {
                        if (dealer.location) {
                            return (
                                <Marker
                                    key={dealer.id}
                                    position={dealer.location}
                                    icon={customMarkerUrl}
                                >
                                </Marker>
                            );
                        }
                        return null;
                    })}
                </GoogleMap>
            )}
        </div>
    );
}

export default DealersMap