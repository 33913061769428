import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, Typography, TextField } from "@mui/material";

const CustomAutoComplete = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  error,
  errorMessage,
  required,
  col_size,
  onValueChange,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  return (
    <Grid item xs={col_size}>
      <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      <Autocomplete
        fullWidth
        options={options}
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            style={{ marginTop: "11px" }}
            {...params}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={error && errorMessage}
            required={required}
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: "8px",
                height: "42px",
                marginTop: "6px",
                ...(params.InputProps ? params.InputProps.style : {}),
              },
            }}
          />
        )}
      />
    </Grid>
  );
};

export default CustomAutoComplete;
