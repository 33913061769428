import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchEditCouponForm = createAsyncThunk('editCoupon/fetchEditCouponForm', async (selectedCouponEdit,{rejectWithValue}) => {
    try {
        const response = await api.get(`prometheus/station/edit/coupon/${selectedCouponEdit}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching edit coupon form', error);
        return rejectWithValue(error.message);
    }
    });

const EditCouponSlice = createSlice({
  name: 'editCoupon',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    setUserPhone: (state, action) => {
      state.data.data.tabs[0].form_input[1].value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditCouponForm.pending, (state) => {
        state.isLoading = true;
        state.error = false
      })
      .addCase(fetchEditCouponForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditCouponForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export const {setUserPhone} = EditCouponSlice.actions;

export default EditCouponSlice.reducer;
