import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const billingDetailsData = createAsyncThunk('billingDetails/fetchBillingDetailsData', async (selectedChargerLink) => {
  try {
    const response = await api.get(`/${selectedChargerLink}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching billing details data:', error);
    throw error;
  }
});

const BillingDetailsSlice = createSlice({
  name: 'billingDetails',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(billingDetailsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(billingDetailsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(billingDetailsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default BillingDetailsSlice.reducer;
