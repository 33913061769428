import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchUserTableData = createAsyncThunk('user/fetchUserTableData', async (facilityId) => {
    try {
        const response = await api.get(`prometheus/facility/${facilityId}/list/user/`);
        return response.data;
      } catch (error) {
        console.error('Error fetching user details', error);
        throw error;
      }
});

const UserTableSlice = createSlice({
  name: 'userTable',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTableData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserTableData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchUserTableData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default UserTableSlice.reducer;
