import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  styled,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import CustomCard from "./CustomCard";
// import TuneIcon from "@mui/icons-material/Tune";
import AddIcon from "@mui/icons-material/Add";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Fab from "@mui/material/Fab";
import DynamicTable from "./customComponents/DynamicTable";
// import CustomFilter from "./customComponents/CustomFilter";
import CustomSearch from "./customComponents/CustomSearch";
import CustomTabs from "./customComponents/CustomTabs";
import ProfileSection from "./ProfileSection";
import DetailsTable from "./customComponents/DetailsTable.js";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import CustomModal from "./customComponents/customModal";
import CustomCalendar from "./customComponents/CustomCalendar";
import AddChargerButton from "./buttonComponents/AddChargerButton";
import AddUserButton from "./buttonComponents/AddUserButton";
import SearchButton from "./buttonComponents/SearchButton";
import FilterButton from "./buttonComponents/FilterButton";
import ExportExcel from "./buttonComponents/ExportExcel";
import DownloadButton from "./buttonComponents/DownloadButton";
import { useSelector, useDispatch } from "react-redux";
import { fetchTableData } from "../slice/TableSlice";
import EmptyData from "./EmptyData";
import noStations from "../assets/emptyData/noStations.svg";
import * as XLSX from "xlsx";
import DynamicFormModal from "./customComponents/DynamicFormModal.js";
import NewDatePicker from "./NewDatePicker.js";
import AddAdminButton from "./buttonComponents/AddAdminButton.js";
import { setSelectedFacility } from "../slice/StationListSlice.js";
import ChangeDashboardView from "./dashboard/ChangeDashboardView.js";


const ChargingStations = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showLogs, setShowLogs] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState(null);
  const [timePeriodModalOpen, setTimePeriodModalOpen] = useState(false);
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [searchedStationList, setSearchedStationList] = useState([]);
  const [searchedChargerData, setSearchedChargerData] = useState([]);
  const [id, setId] = useState();
  // const [isInitialLoad, setIsInitialLoad] = useState(true);       // Previous Logic
  // const [selectedStation, setSelectedStation] = useState(null);   // Previous Logic
  const [searchText, setSearchText] = useState("");
  const [tableSearchText, setTableSearchText] = useState("");
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalType, setDynamicModalType] = useState("");

  const loginStatus = useSelector((state) => state.login);
  const userStatus = useSelector((state) => state.user);
  const facilityStatus = useSelector((state) => state.stationList);
  const detailsStatus = useSelector((state) => state.table);
  const logsStatus = useSelector((state) => state.chargerLogs);
  const facility = useSelector((state) => state.stationList.data.data);
  const tableData = useSelector((state) => state.table.data.data);
  const loggedData = useSelector((state) => state.chargerLogs.data.data);
  const { selectedFacility } = useSelector(state => state.stationList);
  const { selectedDate } = useSelector(state => state.datePicker);

  const timeoutRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    // Update searchedStationList when stationList changes
    setSearchedStationList(facility);
    setId(facility?.[0]?.id);
  }, [facility]);

  useEffect(() => {
    const currentTabData = tableData?.tabs[tabValue];
    setSearchedChargerData(currentTabData ? currentTabData.table_content : []);
  }, [tableData, tabValue]);

  const isLoading = loginStatus.isLoading || userStatus.isLoading || facilityStatus.isLoading;

  const isTableLoading = detailsStatus.isLoading || logsStatus.isLoading;

  const timePeriods = [
    "This Week",
    "Past Week",
    "Last 30 days",
    "This Month",
    "Past Month",
  ];

  const apiTabs = tableData && tableData.tabs ? tableData.tabs : [];

  // const filterStationData = [
  //   {
  //     name: "state",
  //     label: "Select State",
  //     displayName: "State",
  //     options: ["State A", "State B"],
  //   },
  //   {
  //     name: "city",
  //     label: "Select City",
  //     displayName: "City",
  //     options: ["City A", "City B"],
  //   },
  //   {
  //     name: "cpo",
  //     label: "Select CPO",
  //     displayName: "CPO",
  //     options: ["CPO A", "CPO B"],
  //   },
  //   {
  //     name: "client",
  //     label: "Select Client",
  //     displayName: "Client",
  //     options: ["Client A", "Client B"],
  //   },
  // ];

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const filteredStationList = facility.filter((data) => {
      const searchTextLowerCase = searchText.toLowerCase();
      return Object.values(data).some(value => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTextLowerCase);
        } else if (typeof value === "number") {
          return value.toString().toLowerCase().includes(searchTextLowerCase);
        } else if (value && typeof value === "object") {
          return Object.values(value).some(val => {
            if (typeof val === "string") {
              return val.toLowerCase().includes(searchTextLowerCase);
            } else if (typeof val === "number") {
              return val.toString().toLowerCase().includes(searchTextLowerCase);
            }
            return false;
          });
        }
        return false;
      });
    }
      // data.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchedStationList(filteredStationList);
  };

  const handleTabSearch = (searchText) => {
    setTableSearchText(searchText);
    const currentTabData = apiTabs[tabValue];
    if (currentTabData && currentTabData.table_content) {
      const filteredChargerData = currentTabData.table_content.filter((data) => {
        const searchTextLowerCase = searchText.toLowerCase();
        return Object.values(data).some(value => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(searchTextLowerCase);
          } else if (typeof value === "number") {
            return value.toString().toLowerCase().includes(searchTextLowerCase);
          } else if (value && typeof value === "object") {
            return Object.values(value).some(val => {
              if (typeof val === "string") {
                return val.toLowerCase().includes(searchTextLowerCase);
              } else if (typeof val === "number") {
                return val.toString().toLowerCase().includes(searchTextLowerCase);
              }
              return false;
            });
          }
          return false;
        });
      });
      setSearchedChargerData(filteredChargerData);
    }
  };
  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
    setTableSearchText("");
    const currentTabData = apiTabs[newValue];
    if (currentTabData && currentTabData.table_content) {
      setSearchedChargerData(currentTabData.table_content);
    }
  };

  const handleisFabModalOpen = () => {
    const newApiCallType = "addCharger";
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsDynamicModalOpen(true);
  };

  const handleStationModalOpen = () => {
    const newApiCallType = "addStation";
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsDynamicModalOpen(true);
  };

  const handleUserModalOpen = () => {
    const newApiCallType = "addUser";
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsDynamicModalOpen(true);
  };

  const handleAdminModalOpen = () => {
    const newApiCallType = "addAdmin";
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsDynamicModalOpen(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleApplyFilter = () => {
    // Implement filter logic here
    handleCloseModal();
  };
  const handleResetFilter = () => {
    // Implement your logic for resetting filters
  };

  const handleTimePeriodModalOpen = () => {
    setTimePeriodModalOpen(true);
  };

  const handleTimePeriodModalClose = () => {
    setTimePeriodModalOpen(false);
  };

  const handleCustomDateClick = () => {
    setTimePeriodModalOpen(false);
    setCalendarModalOpen(true);
  };

  const handleCalendarModalClose = () => {
    setCalendarModalOpen(false);
  };

  const handleCalendarApply = () => {
    // Implement logic for applying calendar selection
    handleCalendarModalClose();
  };

  const handleCalendarClear = () => {
    // Implement logic for clearing calendar selection
  };

  const handleExportToExcelClick = () => {
    const ws = XLSX.utils.json_to_sheet([]);
    const wb = XLSX.utils.book_new();

    const currentTabData = tableData.tabs[tabValue];
    let buttonTabs = [];
    if (Array.isArray(currentTabData.table_content) && currentTabData.table_content.length > 0) {
      const values = Object.entries(currentTabData.table_content[0])
      values?.forEach(item => {
        if (item[1]?.type === "button" || item[1]?.type === "toggle")
          buttonTabs.push(item[0])
      })
    }

    const headers = currentTabData.headers
      .filter(header => header.id !== "id" && header.id !== "edit" && header.id !== "currency" && !buttonTabs.includes(header.id))
      .map(header => header?.label);

    // Add headers to the worksheet
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

    let tableContent = [];
    if (Array.isArray(currentTabData.table_content)) {
      tableContent = currentTabData.table_content.map(item => {
        const rowData = currentTabData.headers
          .filter(header => header.id !== "id" && header.id !== "edit" && header.id !== "currency" && !buttonTabs.includes(header.id))
          .map(header => {
            const value = item[header.id];
            return typeof value === "object" ? value?.label : value;
          });
        return rowData;
      });
    }

    // Add table content to the worksheet
    XLSX.utils.sheet_add_aoa(ws, tableContent, { origin: "A2" });

    let sheetName = 'Sheet1';
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    let fileName = 'file';
    if (currentTabData.name === "Chargers" || currentTabData.name === "Admins")
      fileName = `${selectedFacility?.name}_${currentTabData?.name}`
    else if (currentTabData.name === "Users")
      fileName = `${selectedFacility?.name}_restricted_users`
    else if (currentTabData.name === "Sessions") {
      const start_date = selectedDate?.start;
      const end_date = selectedDate?.end;
      const date_string = `${start_date.getDate()}-${start_date.getMonth()}-${start_date.getFullYear()} - ${end_date.getDate()}-${end_date.getMonth()}-${end_date.getFullYear()}`
      fileName = `${selectedFacility?.name}_${date_string}`
    }
    // Save Excel file
    fileName = `${fileName.toLowerCase()}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };


  const handleDownloadClick = () => {

    const ws = XLSX.utils.json_to_sheet([]);
    const wb = XLSX.utils.book_new();

    const headers = loggedData?.tabs?.[0]?.headers
      .filter(header => header.id !== "extra")
      .map(header => header?.label);

    // Add headers to the worksheet
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

    let tableContent = [];
    if (Array.isArray(loggedData?.tabs?.[0]?.table_content)) {
      tableContent = loggedData?.tabs?.[0]?.table_content.map(item => {
        const rowData = loggedData?.tabs?.[0]?.headers
          .filter(header => header.id !== "extra")
          .map(header => {
            const value = item[header.id];
            return Array.isArray(value) ? JSON.stringify(value[0]?.content) : value;
          });
        return rowData;
      });
    }

    // Add table content to the worksheet
    XLSX.utils.sheet_add_aoa(ws, tableContent, { origin: "A2" });
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const device_id = ((loggedData?.tabs[0]?.name)?.split(" "))
    const fileName = (device_id?.length > 0 ? device_id.at(-1) : 'charger')?.toLowerCase();
    XLSX.writeFile(wb, `${fileName}_logs_data.xlsx`);
  };

  const handleShowLogsChange = (value) => {
    setShowLogs(value);
  };

  const debouncedHandleCardClick = (stationId, selectedDate) => {
    const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
    const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
    dispatch(fetchTableData({ stationId: stationId, startDate: startISOString, endDate: endISOString }));
  };

  const handleCardClick = (stationId) => {
    const selectedStation = searchedStationList.find(
      (station) => station.id === stationId
    );
    if (selectedStation) {
      localStorage.setItem("selectedStation", JSON.stringify(selectedStation));  // Previous Logic
      // setSelectedStation(selectedStation);    // Previous Logic
      dispatch(setSelectedFacility(selectedStation));
      setSearchText((prevSearchText) => prevSearchText);
    }
    setTableSearchText("");
    setTabValue(0);
    setShowLogs(false);
    setId(stationId);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      debouncedHandleCardClick(stationId, selectedDate);
    }, 100);
  };

  const handleBackArrowClick = () => {
    setShowLogs(false);
    setTableSearchText((prevSearchText) => prevSearchText);
  };

  const handleSearchButton = (searchText) => {
    handleTabSearch(searchText)
  };

  const renderButtons = () => {
    const currentTabData = apiTabs[tabValue];

    if (currentTabData && currentTabData.filters_and_buttons) {
      const placeholder = `Search ${currentTabData.name || "Item"}`;
      return currentTabData.filters_and_buttons.map((button, index) => {
        switch (button) {
          case "Search":
            return (
              <SearchButton
                key={index}
                placeholder={placeholder}
                handleOnSearch={(searchText) =>
                  handleSearchButton(searchText)
                }
                value={tableSearchText}
              />
            );
          case "Filters":
            return <FilterButton key={index} onClick={handleOpenModal} />;
          case "date_range_filter":
            return (
              <NewDatePicker facilityId={selectedFacility?.id} />
            );
          case "Export to Excel":
            return (
              <ExportExcel key={index} onClick={handleExportToExcelClick} />
            );
          case "Download":
            return (
              <DownloadButton key={index} onClick={handleExportToExcelClick} />
            );
          default:
            return null;
        }
      });
    }
  };

  const StyledFab = styled(Fab)({
    zIndex: 1,
    top: -45,
    color: "#fff",
    background: "#467CEB",
    "&:hover": {
      background: "#467CEB",
    },
  });

  // Previous Logic Commented Out


  // useEffect(() => {
  //   const storedStation = JSON.parse(localStorage.getItem("selectedStation"));
  //   if (storedStation) {
  //     setSelectedStation(storedStation);
  //     const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
  //     const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
  //     dispatch(fetchTableData({ stationId: storedStation?.id, startDate: startISOString, endDate: endISOString }));
  //   }
  // }, [dispatch, isInitialLoad]);

  // useEffect(() => {
  //   const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
  //   const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
  //   if (selectedFacility?.name !== "All") {
  //     setSelectedStation(selectedFacility);
  //     localStorage.setItem("selectedStation", JSON.stringify(selectedFacility));
  //     dispatch(fetchTableData({ stationId: selectedFacility?.id, startDate: startISOString, endDate: endISOString }));
  //   }
  //   if (searchedStationList?.length > 0 && isInitialLoad && selectedFacility?.name === "All") {
  //     setSelectedStation(searchedStationList[0]);
  //     localStorage.setItem("selectedStation", JSON.stringify(searchedStationList[0]));
  //     dispatch(fetchTableData({ stationId: searchedStationList[0].id, startDate: startISOString, endDate: endISOString }));
  //     setIsInitialLoad(false);
  //   }
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem("selectedStation");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [dispatch, searchedStationList, isInitialLoad]);

  useEffect(() => {
    const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
    const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
    if (selectedFacility?.name !== "All" && id) {
      dispatch(fetchTableData({ stationId: selectedFacility?.id, startDate: startISOString, endDate: endISOString }));
    }
  }, [dispatch, selectedDate , selectedFacility, id]);



  return (
    <>
      {isLoading && (
        <Grid sx={{ position: "absolute", top: "50%", left: "50%" }}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            alignItems={"center"}
          >
            <Grid item xs={8} sx={{ flexGrow: 1 }}>
              <Typography variant="h4" fontSize='24px' fontWeight='600'>
                {selectedFacility?.name !== "All"
                  ? `${selectedFacility?.name} - Station`
                  : "Station Management - Station List"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <ProfileSection />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"

          >
            <Grid item xs={12} pt={0}>
              <ChangeDashboardView />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            sx={{ marginTop: 1, marginLeft: "-16px" }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                borderRadius: "16px",
                background: "#FFF",
                boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                padding: "16px",
              }}
            >
              <Grid
                container
                sx={{ padding: 0 }}
                rowSpacing={3}
                columnSpacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "12px",
                  }}
                >
                  <CustomSearch
                    placeholder="Search Station"
                    onSearch={handleSearch}
                    value={searchText}
                  />
                  {/* <Grid
                    onClick={handleOpenModal}
                    sx={{
                      width: "100px",
                      display: "flex",
                      padding: "8px 16px",
                      alignItems: "center",
                      gap: "12px",
                      alignSelf: "stretch",
                      borderRadius: "20px",
                      border: "1px solid var(--light-mode-gray-30, #EAEBEE)",
                      background: "var(--color-styles-white, #FFF)",
                      cursor: "pointer",
                    }}
                  >
                    <TuneIcon
                      sx={{
                        display: "flex",
                        width: "14px",
                        height: "14px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></TuneIcon>
                    <Typography
                      sx={{
                        color: "var(--light-mode-gray-80, #5C677D)",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "18px",
                      }}
                    >
                      Filters
                    </Typography>
                  </Grid> */}
                  {/* <CustomFilter
                    open={openModal}
                    onClose={handleCloseModal}
                    onApplyFilter={handleApplyFilter}
                    onResetFilter={handleResetFilter}
                    dropdownData={filterStationData}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <h3 style={{ margin: "0" }}>
                    {searchedStationList?.length > 0
                      ? `${searchedStationList?.length} Stations`
                      : "0 Station"}
                  </h3>
                </Grid>
                {searchedStationList?.length > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      maxHeight: "calc(100vh - 260px)",
                      overflow: "auto",
                    }}
                  >
                    <PerfectScrollbar
                      sx={{
                        overflow: "auto",
                        maxHeight: "100%",
                        scrollbarWidth: "thin", // For Firefox
                      }}
                    >
                      {Array.isArray(searchedStationList) &&
                        searchedStationList?.map((station) => (
                          <CustomCard
                            key={station?.id}
                            cardData={station}
                            onClick={() => handleCardClick(station.id)}
                            isSelected={selectedFacility?.id === station.id}
                            onSelect={() => handleCardClick(station.id)}
                          />
                        ))}
                      <Grid
                        position="fixed"
                        color="primary"
                        display={userStatus?.data?.data?.add_station ? "block" : "none"}
                        sx={{ bottom: "15px", left: "25%" }}
                        onClick={handleStationModalOpen}
                      >
                        <StyledFab
                          color="#467CEB"
                          background="#467CEB"
                          aria-label="add"
                        >
                          <AddIcon sx={{ fontSize: "30px" }} />
                        </StyledFab>
                      </Grid>
                    </PerfectScrollbar>
                  </Grid>
                ) : (
                  <EmptyData
                    imagePath={noStations}
                    message="No Stations Found"
                  />
                )}
              </Grid>
            </Grid>
            {isTableLoading ? (
              <Grid sx={{ position: "absolute", top: "50%", left: "60%" }}>
                <CircularProgress />
              </Grid>
            ) : (
              selectedFacility?.name === "All" ?
                <Typography variant="h5" style={{ position: "absolute", top: "50%", left: "50%" }}>Please select a station to see the data</Typography>
                :
                <Grid
                  item
                  xs={9}
                  sx={{ paddingTop: "0" }}
                  style={{ paddingTop: "0" }}
                >
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        borderRadius: "16px",
                        background: "#fff",
                        boxShadow: "none",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          padding: "16px 16px 0 16px",
                          alignItems: "flex-start",
                          alignSelf: "stretch",
                        }}
                      >
                        <Grid sx={{ width: "100%" }}>
                          {showLogs ? (
                            <>
                              <Grid
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12px",
                                  paddingTop: "16px",
                                  paddingBottom: "32px",
                                }}
                              >
                                <ArrowBack
                                  sx={{ cursor: "pointer" }}
                                  onClick={handleBackArrowClick}
                                />
                                <Typography
                                  sx={{
                                    color: "var(--light-mode-gray-80, #001233)",
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                  }}
                                >
                                  {loggedData?.tabs[0].name}
                                </Typography>
                              </Grid>
                              {loggedData?.tabs[0]?.table_content.length > 0 &&
                                <Grid
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    onClick={handleTimePeriodModalOpen}
                                    sx={{
                                      border: "1px solid #EAEBEE",
                                      borderRadius: "8px",
                                      color: "#5C677D",
                                      textTransform: "capitalize",
                                      padding: "8px 16px",
                                    }}
                                  >
                                    Time Period{" "}
                                    <ExpandMore sx={{ marginLeft: "16px" }} />
                                  </Button>
                                  <DownloadButton onClick={handleDownloadClick} />
                                </Grid>
                              }
                              <CustomModal
                                open={timePeriodModalOpen}
                                onClose={handleTimePeriodModalClose}
                              >
                                {timePeriods.map((timePeriod) => (
                                  <Typography
                                    key={timePeriod}
                                    onClick={() => setTimePeriodModalOpen(false)}
                                    sx={{
                                      cursor: "pointer",
                                      marginBottom: "16px",
                                    }}
                                  >
                                    {timePeriod}
                                  </Typography>
                                ))}
                                {timePeriods?.length > 0 && (
                                  <Divider
                                    sx={{ margin: "8px 0", width: "100%" }}
                                  />
                                )}
                                {/* Custom Date text with + icon */}
                                <Grid
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleCustomDateClick}
                                >
                                  <AddIcon sx={{ marginRight: "8px" }} />
                                  <Typography>Custom Date</Typography>
                                </Grid>
                              </CustomModal>
                            </>
                          ) : (
                            <CustomTabs
                              formTabs={apiTabs}
                              tabValue={tabValue}
                              handleTabChange={handleTabChange}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "12px",
                          margin: "16px 20px",
                          justifyContent: "space-between",
                        }}
                      >
                        {showLogs ? null : renderButtons()}
                      </Grid>
                      {showLogs ? (
                        <DetailsTable detailsData={loggedData} />
                      ) : (

                        <DynamicTable
                          setSelectedData={setSelectedCharger}
                          selectedData={selectedCharger}
                          tabValue={tabValue}
                          searchedData={searchedChargerData}
                          tabs={tableData?.tabs}
                          onEditClick={handleisFabModalOpen}
                          onShowLogsChange={handleShowLogsChange}
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>

            )}
            <DynamicFormModal
              isOpen={isDynamicModalOpen}
              onClose={() => setIsDynamicModalOpen(false)}
              apiCallType={dynamicModalType}
              id={id}
            />
            <CustomCalendar
              open={calendarModalOpen}
              onClose={handleCalendarModalClose}
              onApply={handleCalendarApply}
              onClear={handleCalendarClear}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default ChargingStations;
