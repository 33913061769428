import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchAddUserForm = createAsyncThunk('addUser/fetchAddUserForm', async (stationId,{rejectWithValue}) => {
    try {
        const response = await api.get(`prometheus/${stationId}/user/add/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user form', error);
        throw rejectWithValue(error);
    }
    });

const AddUserSlice = createSlice({
  name: 'addUser',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddUserForm.pending, (state) => {
        state.isLoading = true;
        state.error=false;
      })
      .addCase(fetchAddUserForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddUserForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default AddUserSlice.reducer;
