import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  apiError: null,
};

export const loginUser = createAsyncThunk('login/fetchLoginDetails', async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await api.post('prometheus/auth/login/', { email, password });
    const token = response.data.data.token;
    localStorage.setItem('token', token);
    return response.data;
  } catch (error) {
    if ('isAxiosError' in error && error.response) {
      return rejectWithValue({
        message: error.message,
        status: error.response.status,
        data: error.response.data,
      });
    } else {
      return rejectWithValue(error);
    }
  }
});

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearLoginState:(state)=>
    {
      state.data=[];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.apiError = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.apiError = action.payload.data.message;
      });
  },
});


export const {clearLoginState}=LoginSlice.actions;
export default LoginSlice.reducer;
