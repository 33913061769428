import Typography from '@mui/material/Typography'
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const CustomPasswordInput = ({ label, placeholder, required, showPasswordIcon, showRegenrate, inputType, value: propValue, disabled,
    onValueChange, }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [key, setKey] = useState(null);
    const [value, setValue] = useState("");
    const [helperText, setHelperText] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    useEffect(() => {
        onValueChange("");
    }, []);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        onValueChange(newValue);
        setHelperText("");
    };

    const onBlur = () => {
        if (required && (!value || !value.trim())) { // Check if value is undefined or empty
            setHelperText("Please enter a value");
        }
    };


    const generateRandomKey = () => {
        let generatedKey = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';
        const length = 16;

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            generatedKey += characters[randomIndex];
        }

        setKey(generatedKey);
    };
    return (
        <>
            <Typography style={{ fontSize: "14px", fontWeight: "600", lineHeight: "18px" }}>
                {label}
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
            </Typography>
            <TextField
                style={{ marginLeft: "0" }}
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={onBlur}
                helperText={
                    <Typography
                        variant="caption"
                        color="error"
                        style={{ marginTop: "5px", marginLeft: "0" }}
                    >
                    </Typography>
                }
                value={key ? key : value}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                    style: {
                        borderRadius: "12px",
                        display: "flex",
                        height: "44px",
                        padding: "12px",
                        alignItems: "center",
                        gap: "12px",
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            {showRegenrate &&
                                <Button variant='contained' disableElevation style={{ marginRight: "10px", backgroundColor: "#F5F5F7", borderRadius: "8px", color: "#5C677D" }} onClick={generateRandomKey}>
                                    Regenerate
                                </Button>

                            }
                            {showPasswordIcon &&
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordVisibility}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>

                            }
                        </InputAdornment>
                    )
                }}
                required={required}
                type={showPassword || inputType ? 'text' : 'password'}
                disabled={disabled}

            />
        </>
    )
}

export default CustomPasswordInput