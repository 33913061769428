import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import TotalBooking from "../billingAndPayment/icons/TotalBooking";


const CustomBox = ({ cardText, cardValue }) => {
  return (
    <Grid item>
      <Box
        display={"flex"}
        alignItems={"center"}
        bgcolor={"white"}
        justifyContent={"space-between"}
        borderRadius={"16px"}
        padding={"24px"}
        height="130px"
      >
        <Typography>
          <Typography variant="subtitle" fontSize="12px" fontWeight="600" style={{ textWrap: "nowrap" }}>
            {cardText.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
          </Typography>
          <Typography
            variant="h5"
            fontSize="24px"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
            style={{ textWrap: "nowrap" }}
          >
            {cardValue}
          </Typography>
        </Typography>
        <TotalBooking />
      </Box>
    </Grid>
  );
};

export default CustomBox;
