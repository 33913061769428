import React from 'react'

const SubDealers = () => {
    return (
        <svg width="110" height="108" viewBox="0 0 110 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_35307)">
                <rect x="15" y="3" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="1.5" width="83" height="83" rx="41.5" stroke="#FFCBA6" stroke-width="3" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.0001 38.569C56.8419 38.569 58.5374 39.1954 59.8851 40.2467C59.1492 41.1283 58.706 42.2629 58.706 43.5009C58.706 44.6308 59.0752 45.6746 59.6991 46.5185H50.3011C50.9251 45.6746 51.2942 44.6309 51.2942 43.5009C51.2942 42.2629 50.8511 41.1283 50.1151 40.2467C51.4629 39.1954 53.1584 38.569 55.0001 38.569ZM46.2125 48.5937C49.7832 48.5937 52.804 50.9479 53.8075 54.1887C53.9839 54.7581 53.888 55.3246 53.5343 55.8044C53.1806 56.2842 52.6678 56.5432 52.0718 56.5432H40.3531C39.7571 56.5432 39.2443 56.2842 38.8906 55.8044C38.5369 55.3246 38.4411 54.7581 38.6174 54.1887C39.621 50.948 42.6419 48.5937 46.2125 48.5937ZM63.7878 39.4817C61.568 39.4817 59.7685 41.2812 59.7685 43.501C59.7685 45.7207 61.568 47.5202 63.7878 47.5202C66.0075 47.5202 67.807 45.7207 67.807 43.501C67.807 41.2812 66.0075 39.4817 63.7878 39.4817ZM46.2125 39.4817C43.9927 39.4817 42.1933 41.2812 42.1933 43.501C42.1933 45.7207 43.9927 47.5202 46.2125 47.5202C48.4323 47.5202 50.2317 45.7207 50.2317 43.501C50.2317 41.2812 48.4323 39.4817 46.2125 39.4817ZM63.7877 48.5937C67.3584 48.5937 70.3792 50.9479 71.3827 54.1887C71.5591 54.7581 71.4632 55.3246 71.1095 55.8044C70.7558 56.2842 70.2431 56.5432 69.647 56.5432H57.9284C57.3324 56.5432 56.8196 56.2842 56.4659 55.8044C56.1122 55.3246 56.0164 54.7581 56.1927 54.1887C57.1963 50.948 60.2172 48.5937 63.7877 48.5937ZM55.0002 29.457C57.2199 29.457 59.0194 31.2565 59.0194 33.4763C59.0194 35.696 57.2199 37.4955 55.0002 37.4955C52.7804 37.4955 50.9809 35.696 50.9809 33.4763C50.9809 31.2565 52.7804 29.457 55.0002 29.457Z" fill="#F4B618" />
            </g>
            <defs>
                <filter id="filter0_d_9271_35307" x="0" y="0" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_35307" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_35307" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default SubDealers