import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import GreenTruck from './icons/green-truck.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import CustomOptionsModal from "../customComponents/CustomOptionsModal"
import CircularProgress from '@mui/material/CircularProgress';
import DynamicFormModal from "../customComponents/DynamicFormModal";


const FleetVehicleCard = ({ dealer, subdealer, onClick, isSelected, cardType, loading, type }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCharger, setSelectedCharger] = useState(null);
    const [id, setId] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
    const [dynamicModalType, setDynamicModalType] = useState("");

    const userStatus = useSelector((state) => state.user.data.data);

    const handleClick = () => {
        if (!isModalOpen && !isDynamicModalOpen) {
            onClick && dealer && onClick(dealer.id, cardType);
            onClick && subdealer && onClick(subdealer.id, cardType);
        }
    };

    const handleOpenModal = (id, name, event) => {
        setAnchorEl(event.currentTarget);
        setIsModalOpen(true);
        setId(`Station ID: ${id}`);
        setSelectedCharger({ id, name, cardType });
    };

    const handleCloseModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    };

    const handleEditFormOpen = () => {
        const newApiCallType = subdealer ? "editSubDealer" : "editDealer";
        localStorage.setItem("dynamicModalType", newApiCallType);
        setDynamicModalType(newApiCallType);
        setIsModalOpen(false);
        setIsDynamicModalOpen(true);
    };

    return (
        <>
            {loading?.subDealer || loading?.dealer ? (
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: "calc(100vh - 260px)",
                        overflow: "auto",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Card
                        sx={{
                            display: "flex",
                            height: "110px",
                            marginBottom: "16px",
                            cursor: "pointer",
                            border: isSelected ? "1px solid #B5CBF7" : "1px solid #EAEBEE",
                            background: isSelected ? "#EFF4FF" : "#FFF",
                            borderRadius: "8px",
                            position: "relative",
                            boxShadow: "none",
                            alignItems: "center",
                        }}
                        onClick={handleClick}
                    >
                        {type !== "telematic" && (
                            <>
                                <IconButton
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        color: "var(--light-mode-gray-100, #001233)",
                                        display: (userStatus?.facility_admin_type === 'superadmin' || userStatus?.facility_admin_type === 'countryadmin') ? "block" : "none"
                                    }}
                                    aria-label="options"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleOpenModal(subdealer ? subdealer.id : dealer.id, subdealer ? subdealer.name : dealer.name, event);
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <CustomOptionsModal
                                    isOpen={isModalOpen}
                                    anchorEl={anchorEl}
                                    onClose={(e) => handleCloseModal(e)}
                                    // options={options}
                                    ID={id}
                                    handleEdit={handleEditFormOpen}
                                // handleDelete={handleDelete}
                                />
                            </>
                        )}
                        <Box sx={{ position: "relative", borderLeft: "3px solid #30C48F" }}>
                            <CardMedia
                                component="img"
                                sx={{ width: "40px", height: "40px", flexShrink: 0, }}
                                image={GreenTruck}
                                alt="Custom Card Image"
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <CardContent style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                                <Typography
                                    component="div"
                                    style={{
                                        overflow: "hidden",
                                        color: "var(--light-mode-gray-100, #001233)",
                                        textOverflow: "ellipsis",
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "20px",
                                    }}
                                >
                                    {dealer && dealer.name}
                                    {subdealer && subdealer.name}
                                </Typography>
                            </CardContent>
                            <Box
                                sx={{
                                    position: "relative",
                                    marginLeft: "16px",
                                    width: "100%",
                                    overflow: "hidden",
                                }}
                            >
                                <Typography variant="body1" color="initial" style={{
                                    overflow: "hidden",
                                    color: "#33415C",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "16px",
                                    cursor: 'pointer',
                                }}>
                                    {dealer && `Subdealers :  ${dealer.subdealer_count}`}
                                    {subdealer && `Vehicle :  ${subdealer.vehicle_count}`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    component="div"
                                    mt={.71}
                                    style={{
                                        overflow: "hidden",
                                        color: "var(--light-mode-gray-60, #979DAC)",
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "16px",
                                        cursor: 'pointer',
                                    }}
                                >
                                    {dealer?.address}
                                    {subdealer?.address}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <ChevronRightIcon />
                        </Box>
                    </Card>
                    <DynamicFormModal
                        isOpen={isDynamicModalOpen}
                        onClose={() => setIsDynamicModalOpen(false)}
                        apiCallType={dynamicModalType}
                        id={selectedCharger?.id}
                    />
                </>
            )}
        </>
    );
}

export default FleetVehicleCard