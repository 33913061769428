import React from "react";

const TotalTransactions = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 110 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6118_41305)">
          <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
          <rect
            x="13.5"
            y="8.5"
            width="83"
            height="83"
            rx="41.5"
            stroke="#B5CBF7"
            stroke-width="3"
          />
          <g filter="url(#filter1_b_6118_41305)">
            <path
              d="M41 45.5C39.875 45.5 39 44.625 39 43.5C39 42.4375 39.875 41.5 41 41.5H64.125L60.5625 37.9375C59.75 37.1875 59.75 35.875 60.5625 35.125C61.3125 34.3125 62.625 34.3125 63.375 35.125L70.375 42.125C71.1875 42.875 71.1875 44.1875 70.375 44.9375L63.375 51.9375C63 52.3125 62.5 52.5 62 52.5C61.4375 52.5 60.9375 52.3125 60.5625 51.9375C59.75 51.1875 59.75 49.875 60.5625 49.125L64.125 45.5H41ZM69 55.5C70.0625 55.5 71 56.4375 71 57.5C71 58.625 70.0625 59.5 69 59.5H45.8125L49.375 63.125C50.1875 63.875 50.1875 65.1875 49.375 65.9375C49 66.3125 48.5 66.5 48 66.5C47.4375 66.5 46.9375 66.3125 46.5625 65.9375L39.5625 58.9375C38.75 58.1875 38.75 56.875 39.5625 56.125L46.5625 49.125C47.3125 48.3125 48.625 48.3125 49.375 49.125C50.1875 49.875 50.1875 51.1875 49.375 51.9375L45.8125 55.5H69Z"
              fill="#467CEB"
            />
          </g>
        </g>
        <circle
          cx="55"
          cy="50"
          r="49.8613"
          stroke="#467CEB"
          stroke-width="0.277344"
          stroke-dasharray="1.66 1.66"
        />
        <defs>
          <filter
            id="filter0_d_6118_41305"
            x="0"
            y="7"
            width="110"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6118_41305"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6118_41305"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_6118_41305"
            x="26.2283"
            y="21.7908"
            width="57.481"
            height="57.231"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.26087" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6118_41305"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6118_41305"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default TotalTransactions;
