import { Typography, Grid } from '@mui/material'
import React, { useState, useEffect } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField'; // Add this import
import dayjs from 'dayjs';

const CustomDateInput = ({ label,
    placeholder,
    col_size,
    required,
    value: propValue,
    disabled,
    name,
    isError,
    errorMessage,
    onValueChange, }) => {
    const [value, setValue] = useState("");
    const [helperText, setHelperText] = useState("");


    useEffect(() => {
        if (propValue)
            setValue(dayjs(propValue));
        else
            setValue("")
        // Ensure propValue is not null or undefined
    }, [propValue]);

    // useEffect(() => {
    //     onValueChange(""); // Initialize with an empty string
    // }, []);

    const handleChange = (newValue) => {
        setValue(newValue);
        const { $D, $M, $y } = newValue;
        if (name === 'start_date')
            onValueChange(`${$y}-${$M + 1}-${$D} 00:00:00`);
        else if (name === 'end_date')
            onValueChange(`${$y}-${$M + 1}-${$D} 23:59:59`);
        else
            onValueChange(newValue)
        setHelperText("");
    };

    const onBlur = () => {
        if (required && (!value || !value.trim())) { // Check if value is undefined or empty
            setHelperText("Please enter a value");
        }
    };


    return (
        <Grid item xs={col_size}>
            <Typography style={{ fontSize: "14px", fontWeight: "600", lineHeight: "18px", marginBottom: "16px" }}>
                {label}
                {/* <span style={{ color: "red", marginLeft: "5px" }}>*</span> */}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={placeholder}
                            error={!!helperText}
                            helperText={helperText}
                            fullWidth={true}
                        />
                    )}
                    fullWidth={true}
                    sx={{ width: "100%" }}
                    disabled={disabled}
                />
            </LocalizationProvider>
            {
                isError &&
                <Typography
                    variant="caption"
                    color="error"
                    style={{ marginTop: "5px", marginLeft: "0" }}
                >
                    {errorMessage }
                </Typography>
            }
        </Grid>
    )
}

export default CustomDateInput