import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchCountryList = createAsyncThunk('countries', async () => {
  try {
    const response = await api.get('prometheus/active/countries/');
    return response.data;
  } catch (error) {
    console.error('Error fetching country list:', error);
    throw error;
  }
});

const CountrySlice = createSlice({
  name: 'country',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCountryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchCountryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default CountrySlice.reducer;
