import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProfileSection from "../ProfileSection";
import TotalTransactions from "./icons/TotalTransactions";
import Users from "./icons/Users";
import TransactionChart from "./TransactionChart";
import RevenueChart from "./RevenueChart";
import { useSubscription } from "@apollo/client";
import {
  API_CHART_DATA_WITHOUT_ALL_SUBSCRIPTION,
  API_CHART_DATA_WITH_ALL_SUBSCRIPTION,
  API_TRANSACTIONS_SUBSCRIPTION,
  API_TRANSACTIONS_WITH_ALL_SUBSCRIPTION,
  API_USERS_SUBSCRIPTION,
  API_USERS_WITH_FACILITY_SUBSCRIPTION
} from "../../graphql/query";
import UnitsConsumed from "../UnitsConsumed";
import TotalRevenue from "../TotalRevenue";
import TotalProfit from "../TotalProfit";
import FacilityFilterBtn from "../FacilityFilter";
import { useSelector } from "react-redux";
import ChargeUsageStatue from "./ChargeUsageStatue";
import UnitsConsumedChart from "./UnitsConsumedChart";
import TotalCompanyProfit from "../TotalCompanyProfit";
import { useTranslation } from 'react-i18next';
import NewDatePicker from "../NewDatePicker";
import ChargerUsageChart from "../ChargerUsageChart";
import ElectricityConsumed from "../ElectricityConsumed";
import ChangeDashboardView from "./ChangeDashboardView";
import { customizeName, greetingMessage } from "../../common.js";


const Overview = () => {
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const { selectedDate } = useSelector(state => state.datePicker);
  const { t } = useTranslation();
  const { selectedFacility, facilities } = useSelector((state) => state.stationList);

  const variable = {
    start: selectedDate?.start?.toISOString(), end: selectedDate?.end?.toISOString()
  }
  const { data } = useSubscription(selectedFacility?.name === 'All' ? API_TRANSACTIONS_WITH_ALL_SUBSCRIPTION : API_TRANSACTIONS_SUBSCRIPTION, {
    variables: { ...variable, facility_id: selectedFacility?.id }
  });
  const { data: data2 } = useSubscription(selectedFacility?.name === 'All' ? API_USERS_SUBSCRIPTION : API_USERS_WITH_FACILITY_SUBSCRIPTION,
    {
      variables: { ...variable, facility_id: selectedFacility?.id }
    });
  const userData = JSON.parse(localStorage.getItem('userData'));
  const api = !selectedFacility?.is_fleet ? API_CHART_DATA_WITH_ALL_SUBSCRIPTION : API_CHART_DATA_WITHOUT_ALL_SUBSCRIPTION;
  const { data: data3 } = useSubscription(api, {
    variables: { ...variable, facility_id: selectedFacility?.id }
  });

  useEffect(() => {
    let interval;
    if (data && data.view_facility_revenue_aggregate && data.view_facility_revenue_aggregate.aggregate) {
      setTotalTransactions(data.view_facility_revenue_aggregate.aggregate.count ?? 0);
    } else {
      setTotalTransactions(0);
      interval = setInterval(() => {
        setTotalTransactions(prev => prev + 10);
      }, 50);
    }
    return () => clearInterval(interval);
  }, [data, selectedDate, selectedFacility])

  useEffect(() => {
    let interval;
    if (data2 && data2.view_facility_revenue_aggregate && data2.view_facility_revenue_aggregate.aggregate) {
      setTotalUsers(data2.view_facility_revenue_aggregate.aggregate.count ?? 0);
    } else {
      setTotalUsers(0);
      interval = setInterval(() => {
        setTotalUsers(prev => prev + 1);
      }, 50);
    }
    return () => clearInterval(interval);
  }, [data2, selectedDate, selectedFacility])

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3} alignItems='center'>
        <Grid item xs={8} style={{ flexGrow: 1, padding: '0 20px' }}>
          <Typography variant="h4" fontSize='32px' fontWeight='600'>{greetingMessage()},
            <Typography variant="subtitle" color='#467CEB'> {customizeName(userData?.data?.name ?? '')} !
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ProfileSection />
        </Grid>
      </Grid>
      <ChangeDashboardView />
      <Grid container mb='20px' columnSpacing={2} alignItems='center'>
        <Grid item>
          <NewDatePicker />
        </Grid>
        <Grid item >
          <FacilityFilterBtn selectedFacility={selectedFacility} facilities={facilities} label={"Select Facility"} />
        </Grid>
      </Grid>
      <Grid container spacing={3} columnSpacing={3} sx={{ marginBottom: 2 }}>
        <Grid item xs={3}>
          <Grid
            container
            justifyContent="center"
            sx={{ flexGrow: 1 }}
            alignItems="center"
            rowSpacing={3}
          >
            <Grid item xs={12}>
              <TotalRevenue dateFilter={selectedDate} facilityFilter={selectedFacility} />
            </Grid>
            <Grid item xs={12}>
              <ElectricityConsumed dateFilter={selectedDate} facilityFilter={selectedFacility} />
            </Grid>
            <Grid item xs={12}>
              <TotalCompanyProfit dateFilter={selectedDate} facilityFilter={selectedFacility} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9} style={{ flexGrow: 1 }}>
        <UnitsConsumedChart dateFilter={selectedDate} facilityFilter={selectedFacility} data={data3} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" columnSpacing={3}>

        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            rowSpacing={3}
            columnSpacing={3}
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={3}>
              <TotalProfit dateFilter={selectedDate} facilityFilter={selectedFacility} />
            </Grid>
            <Grid item xs={3}>
              <UnitsConsumed dateFilter={selectedDate} facilityFilter={selectedFacility} />
            </Grid>
            <Grid item xs={3}>
              <Box
                display={"flex"}
                alignItems={"center"}
                bgcolor={"white"}
                justifyContent={"space-between"}
                borderRadius={"16px"}
                padding={"24px"}
                height='130px'
              >
                <Typography>
                  <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('totalTransactions')}</Typography>
                  <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>{totalTransactions}</Typography>
                </Typography>
                <TotalTransactions />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display={"flex"}
                alignItems={"center"}
                bgcolor={"white"}
                justifyContent={"space-between"}
                borderRadius={"16px"}
                padding={"24px"}
                height='130px'
              >
                <Typography>
                  <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('totalUsers')}</Typography>
                  <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>{totalUsers}</Typography>
                </Typography>
                <Users />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="start"
            sx={{ flexGrow: 1 }}
            alignItems="center"
            rowSpacing={3}
            columnSpacing={3}
          >
            <Grid item xs={8.5}>
              <TransactionChart dateFilter={selectedDate} facilityFilter={selectedFacility} data={data3} height='411' />
            </Grid>
            <Grid item md={3.5}>
              <ChargeUsageStatue facilityFilterID={selectedFacility.id} facilityFilterName={selectedFacility.name} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} pt={2}>
        <Grid item xs={12}>
          <RevenueChart dateFilter={selectedDate} facilityFilter={selectedFacility} data={data3} height={226} />
        </Grid>
        {
          selectedFacility?.name !== 'All' &&
          <Grid item xs={12} pt={2}>
            <ChargerUsageChart dateFilter={selectedDate} facilityFilter={selectedFacility} />
          </Grid>
        }
      </Grid>
    </>
  );
};
export default Overview;
