import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useFetchDataOnPageUrl = (fetchData, targetPath) => {
    const location = useLocation();
    const intervalIdRef = useRef(null);

    useEffect(() => {
        const startFetching = () => {
            if (location.pathname === targetPath) {
                fetchData();

                intervalIdRef.current = setInterval(() => {
                    if (location.pathname === targetPath) {
                        fetchData();
                    }
                }, 20000);
            }
        };

        startFetching();

        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
        };
    }, [location.pathname, targetPath]);
};

export default useFetchDataOnPageUrl;