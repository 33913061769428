import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const chargerLogsData = createAsyncThunk('chargerlogs/fetchLogsData', async (selectedChargerLink) => {
  try {
    const response = await api.get(`/${selectedChargerLink}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching logs data:', error);
    throw error;
  }
});

const ChargerLogsSlice = createSlice({
  name: 'chargerLogs',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chargerLogsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(chargerLogsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(chargerLogsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default ChargerLogsSlice.reducer;
