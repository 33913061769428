import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";

const AlertModal = ({
  open,
  onClose,
  message,
  alertType,
  onModalClose,
  dynamicDispatch,
}) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "left",
  });

  useEffect(() => {
    setSnackbarState((prevState) => ({ ...prevState, open }));
  }, [open]);

  const handleCloseSnackbar = (event, reason) => {
    setSnackbarState({ ...snackbarState, open: false });
    if (alertType === "success") {
      onClose();
      dynamicDispatch();
      onModalClose();
    } else {
      onClose();
    }
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <Snackbar
      open={snackbarState.open}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ zIndex: "999999999" }}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={alertType === "error" ? "error" : "success"}
        variant="filled"
        sx={{ width: "100%" }}
      >
        <AlertTitle>{alertType === "error" ? "Error" : "Success"}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertModal;
