import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const CustomInput = ({
  label,
  placeholder,
  col_size,
  required,
  value: propValue,
  onValueChange,
  disabled,
  errorMessage,
  isError
}) => {
  const [value, setValue] = useState("");
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setValue(propValue!== null ? propValue:"");
  }, [propValue]);

  // useEffect(() => {
  //   onValueChange("");
  // }, []);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onValueChange(newValue);
    setHelperText("");
  };

  const handleBlur = () => {
    if (required && (!value || !value.trim())) { // Check if value is undefined or empty
      setHelperText("Please enter a value");
    }
  };


  return (
    <Grid item xs={col_size}>
      <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      <TextField
        style={{ marginLeft: "0" }}
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={<Typography
          variant="caption"
          color="error"
          style={{ marginTop: "5px", marginLeft: "0" }}
        >
          {isError ? errorMessage :  helperText}
        </Typography>}
        error={isError}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          style: {
            borderRadius: "12px",
            display: "flex",
            height: "44px",
            padding: "12px",
            alignItems: "center",
            gap: "12px",
            border: helperText ? "1px solid red" : "none",
          },
        }}
        FormHelperTextProps={{
          style: { marginLeft: "0" }
        }}
        required={required}
        disabled={disabled}
      />
    </Grid>
  );
};

export default CustomInput;
