import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const CustomFormTabs = ({ props }) => {
  const renderTabs = (tabs) => {
    return tabs?.map((tab, index) => (
      <Tab label={tab.name} key={index} value={index}>
        {tab.tabs ? renderTabs(tab.tabs) : null}
      </Tab>
    ));
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: 320, sm: 700 },
        bgcolor: "white",
        borderBottom: "1px solid #EAEBEE",
        zIndex:111,
      }}
      style={{maxWidth: "100%"}}
    >
      {props && (
        <Tabs
        value={props?.tabValue}
        onChange={props?.handleTabChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        {renderTabs(props?.formTabs)}
      </Tabs>
      )}
      
    </Box>
  );
};

export default CustomFormTabs;
