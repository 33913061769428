import React from "react";

const RevenueIcon = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 110 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6118_40699)">
          <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
          <rect
            x="13.5"
            y="8.5"
            width="83"
            height="83"
            rx="41.5"
            stroke="#B5CBF7"
            stroke-width="3"
          />
          <g filter="url(#filter1_b_6118_40699)">
            <path
              d="M69 38C71.1875 38 73 39.8125 73 42V58C73 60.25 71.1875 62 69 62H41C38.75 62 37 60.25 37 58V42C37 39.8125 38.75 38 41 38H69ZM45 58C45 55.8125 43.1875 54 41 54V58H45ZM41 46C43.1875 46 45 44.25 45 42H41V46ZM69 58V54C66.75 54 65 55.8125 65 58H69ZM69 42H65C65 44.25 66.75 46 69 46V42ZM55 56C58.3125 56 61 53.3125 61 50C61 46.6875 58.3125 44 55 44C51.625 44 49 46.6875 49 50C49 53.3125 51.625 56 55 56Z"
              fill="#467CEB"
            />
          </g>
        </g>
        <circle
          cx="55"
          cy="50"
          r="49.8613"
          stroke="#467CEB"
          stroke-width="0.277344"
          stroke-dasharray="1.66 1.66"
        />
        <defs>
          <filter
            id="filter0_d_6118_40699"
            x="0"
            y="7"
            width="110"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6118_40699"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6118_40699"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_6118_40699"
            x="24.4783"
            y="25.4783"
            width="61.0435"
            height="49.0435"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.26087" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6118_40699"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6118_40699"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default RevenueIcon;
