import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";
import config from "../config";

export const submitEditVehicleData = createAsyncThunk(
  "submitEditVehicle/fetchEditSubmitVehicle",
  async ({ vehicleId, payload }) => {
    try {
      const response = await api.post(config.TELEMATICS_URL + `vehicle/edit/${vehicleId}/`, payload);
      return response.data;
    } catch (error) {
      console.error("Error Submitting Data:", error);
      return error.response.data;
    }
  }
);

const SubmitEditVehicleSlice = createSlice({
  name: "submitEditVehicle",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitEditVehicleData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitEditVehicleData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(submitEditVehicleData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

export default SubmitEditVehicleSlice.reducer;
