import React from 'react'

const Duration = () => {
    return (
        <svg width="110" height="115" viewBox="0 0 110 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_33359)">
                <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="8.5" width="83" height="83" rx="41.5" stroke="#B5CBF7" stroke-width="3" />
                <path d="M70.5539 38.8352L68.3742 41.0148C67.7707 40.2988 67.1074 39.6355 66.3914 39.032L68.5711 36.8523C68.8401 36.622 69.1862 36.5016 69.5401 36.5152C69.894 36.5289 70.2297 36.6756 70.4802 36.9261C70.7306 37.1765 70.8773 37.5123 70.891 37.8662C70.9047 38.2201 70.7843 38.5661 70.5539 38.8352ZM59.7187 32.2188H51.2812C50.9083 32.2188 50.5506 32.3669 50.2869 32.6306C50.0232 32.8944 49.875 33.252 49.875 33.625V36.0016C53.5134 34.7079 57.4866 34.7079 61.125 36.0016V33.625C61.125 33.252 60.9768 32.8944 60.7131 32.6306C60.4494 32.3669 60.0917 32.2188 59.7187 32.2188Z" fill="#467CEB" />
                <path d="M68.3739 41.0149C66.7915 39.1421 64.82 37.6367 62.5967 36.6034C60.3733 35.57 57.9515 35.0335 55.4997 35.0313C53.5827 35.0272 51.6795 35.3555 49.8747 36.0016C46.4811 37.1994 43.5593 39.4507 41.5359 42.4269C39.5126 45.403 38.4937 48.948 38.628 52.5443C38.7623 56.1406 40.0428 59.5997 42.2825 62.4167C44.5222 65.2336 47.6038 67.2607 51.0773 68.202C54.5508 69.1433 58.2343 68.9495 61.5898 67.6489C64.9454 66.3483 67.7973 64.0091 69.729 60.9727C71.6608 57.9363 72.5712 54.3619 72.3275 50.7713C72.0837 47.1808 70.6984 43.7623 68.3739 41.0149ZM55.4997 65.9688C52.7184 65.9688 49.9996 65.144 47.687 63.5988C45.3744 62.0536 43.572 59.8574 42.5076 57.2878C41.4433 54.7182 41.1648 51.8907 41.7074 49.1628C42.25 46.435 43.5893 43.9293 45.556 41.9626C47.5227 39.9959 50.0284 38.6566 52.7562 38.114C55.4841 37.5714 58.3116 37.8499 60.8812 38.9142C63.4508 39.9786 65.647 41.781 67.1922 44.0936C68.7374 46.4061 69.5622 49.125 69.5622 51.9063C69.5622 55.6359 68.0806 59.2127 65.4434 61.85C62.8062 64.4872 59.2293 65.9688 55.4997 65.9688Z" fill="#467CEB" />
                <path d="M66.75 51.9062C66.75 52.2792 66.6018 52.6369 66.3381 52.9006C66.0744 53.1643 65.7167 53.3125 65.3438 53.3125H55.5C55.127 53.3125 54.7694 53.1643 54.5056 52.9006C54.2419 52.6369 54.0938 52.2792 54.0938 51.9062V42.0625C54.0938 41.6895 54.2419 41.3319 54.5056 41.0681C54.7694 40.8044 55.127 40.6562 55.5 40.6562C58.4837 40.6562 61.3452 41.8415 63.455 43.9513C65.5647 46.0611 66.75 48.9226 66.75 51.9062Z" fill="#467CEB" />
            </g>
            <circle cx="55" cy="50" r="49.8613" stroke="#467CEB" stroke-width="0.277344" stroke-dasharray="1.66 1.66" />
            <defs>
                <filter id="filter0_d_9271_33359" x="0" y="7" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_33359" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_33359" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default Duration