import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const updateUserCountryData = createAsyncThunk('userCountrydata/fetchUserCountrydata', async (countryId) => {
  try {
    const response = await api.get(`user/account/update/country/${countryId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching updated country data:', error);
    throw error;
  }
});

const UpdateUserCountrySlice = createSlice({
  name: 'updateUserCountry',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserCountryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserCountryData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateUserCountryData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default UpdateUserCountrySlice.reducer;
