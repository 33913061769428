import React from "react";
import { Grid, Typography } from "@mui/material";
import NoteAdd from "@mui/icons-material/NoteAdd";

const ExportExcel = ({ onClick }) => (
  <Grid
    style={{
      display: "flex",
      height: "40px",
      padding: "8px 16px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderRadius: "28px",
      border: "1px solid var(--brand-400, #467CEB)",
      background: "var(--color-styles-white, #FFF)",
      cursor: "pointer"
    }}
    onClick={onClick}
  >
    <NoteAdd
      style={{
        display: "flex",

        width: "14px",
        height: "14px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    />
    <Typography
      style={{
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "18px",
      }}
    >
      Export To Excel
    </Typography>
  </Grid>
);

export default ExportExcel;
