import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';
import config from '../config';

export const fetchEditSubDealerForm = createAsyncThunk('editSubDealer/fetchEditSubDealerForm', async (subDealerId,  { rejectWithValue }) => {
    try {
      const response = await api.get(config.TELEMATICS_URL + `subdealer/edit/${subDealerId}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching edt station form', error);
        throw rejectWithValue(error);
    }
    });

const EditSubDealerSlice = createSlice({
  name: 'editSubDealer',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditSubDealerForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchEditSubDealerForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditSubDealerForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default EditSubDealerSlice.reducer;
