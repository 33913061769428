import React from 'react';
import { InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CustomSearch = ({ placeholder, onSearch, value, searchType }) => {

  const handleSearch = (e) => {
    onSearch(e.target.value, searchType);
  };

  return (
    <InputBase
      placeholder={placeholder}
      onChange={handleSearch}
      value={value}
      style={{ border: '1px solid #EAEBEE', borderRadius: '20px', width: '252px' }}
      startAdornment={
        <IconButton color="black">
          <SearchIcon style={{ color: 'black' }} />
        </IconButton>
      }
    />
  );
};

export default CustomSearch;
