import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { calculateUnitsConsumed, generateTransactionChartPayload } from "./TransactionChart";
import { useTranslation } from 'react-i18next';

const chartSetting = {
  xAxis: [{ dataKey: "created_at", scaleType: "band" }],
  yAxis: [{ dataKey: "total_amount_spent" }],
  series: [{ dataKey: "total_amount_spent", color: '#467CEB' }],
};


export function generateRevenueChartPayload(inputArray, startDate, endDate, facilityFilter = null, units = false) {
  const isLessThanTwoMonths = (start, end) => {
    const diffInYears = end.getFullYear() - start.getFullYear();
    const diffInMonths = end.getMonth() - start.getMonth() + diffInYears * 12;
    return diffInMonths < 2;
  }
  function getDatesInRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  const isWithinTwoMonths = isLessThanTwoMonths(start, end);
  const resultMap = new Map();
  const dateRange = getDatesInRange(start, end);
  dateRange.forEach(date => {
    let key;
    if (isWithinTwoMonths) {
      key = date.toLocaleString('en-US', { day: '2-digit', month: 'short' });
    } else {
      key = isWithinTwoMonths ? date.toISOString().split('T')[0] : `${date.toLocaleString('en-US', { month: 'short' })}-${date.getFullYear()}`;
    }
    const obj = units ? { created_at: key, units_consumed: 0 } : { created_at: key, total_amount_spent: 0 };
    resultMap.set(key, obj);
  });


  inputArray.forEach(({ created_at, total_amount_spent, units_consumed, meter_start_at, meter_stop_at, power_type }) => {
    const currentDate = new Date(created_at);
    if (currentDate >= start && currentDate <= end) {
      let key;
      if (isWithinTwoMonths) {
        key = currentDate.toLocaleString('en-US', { day: '2-digit', month: 'short' });
      } else {
        key = `${currentDate.toLocaleString('en-US', { month: 'short' })}-${currentDate.getFullYear()}`;
      }
      if (resultMap.has(key)) {
        if (!units)
          resultMap.get(key).total_amount_spent += total_amount_spent;
        else
          resultMap.get(key).units_consumed += calculateUnitsConsumed(units_consumed, meter_start_at, meter_stop_at, power_type, facilityFilter, false) / 1000;
      } else {
        if (!units)
          resultMap.set(key, { created_at: key, total_amount_spent });
        else
          resultMap.set(key, { created_at: key, units_consumed: calculateUnitsConsumed(units_consumed, meter_start_at, meter_stop_at, power_type, facilityFilter, false) / 1000 })
      }
    }
  });

  const resultArray = Array.from(resultMap.values()).sort((a, b) => {
    const dateA = isWithinTwoMonths ? new Date().setDate(1, a.created_at) : new Date(a.created_at);
    const dateB = isWithinTwoMonths ? new Date().setDate(1, b.created_at) : new Date(b.created_at);
    return dateA - dateB;
  });

  return resultArray;
}

const RevenueChart = ({ dateFilter, facilityFilter, data, height }) => {

  const valueFormatter = (value) => `${localStorage.getItem('currency') ?? ' '}${value?.toFixed(2)}`;
  const [revenueDetails, setRevenueDetails] = useState([{ created_at: 0, total_amount_spent: 0 ,revenue:0, electricity:0,profit:0,company_profit:0}]);
  chartSetting.height = height || 220;

  const { t } = useTranslation();

  const differenceInDays = (start, end) => {
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  useEffect(() => {
    let interval;
    if (data?.view_facility_revenue) {
      const { is_fleet } = facilityFilter;
      const { start, end } = dateFilter;
      if (!is_fleet) {
        const details = generateRevenueChartPayload(data?.view_facility_revenue, start?.toISOString(), end?.toISOString());
        setRevenueDetails(details?.length > 0 ? details: [{ created_at: 0, total_amount_spent: 0 , revenue:0, electricity:0,profit:0,company_profit:0 }]);
        chartSetting.xAxis = [{ dataKey: "created_at", scaleType: "band" }];
        chartSetting.yAxis = [{ dataKey: "total_amount_spent" }];
        chartSetting.series = [{ dataKey: "total_amount_spent", valueFormatter, color: '#467CEB' , label:"Revenue"}];
      }
      else {
        const details = generateTransactionChartPayload(data?.view_facility_revenue, start?.toISOString(), end?.toISOString(), true, facilityFilter);
        setRevenueDetails(details?.length > 0 ? details: [{ created_at: 0, total_amount_spent: 0 , revenue:0, electricity:0,profit:0,company_profit:0 }]);
        chartSetting.xAxis = [{ dataKey: "created_at", scaleType: "band" }];
        chartSetting.series = [
          { dataKey: "revenue",valueFormatter, label:"Revenue",color: '#467CEB'},
          { dataKey: "electricity",valueFormatter,label: "Electricity",color: "green"},
          {dataKey: "profit",valueFormatter,label: "Terra Profit"},
         { dataKey: "company_profit",valueFormatter, label: "Station Profit"}
        ];
      }
    }
    else{
      const { start, end } = dateFilter;
      setRevenueDetails([{ created_at: 0, total_amount_spent: 0 , revenue:0, electricity:0,profit:0,company_profit:0 }])
      interval = setInterval(() => {
        const daysDifference = differenceInDays(end, start);
        const randomDetails = [];
        for (let i = 0; i <= daysDifference; i++) {
          randomDetails.push({
            created_at: i,
            total_amount_spent: Math.random(),
            revenue: Math.random(),
            electricity: Math.random(),
            profit: Math.random(),
            company_profit: Math.random()
          });
        }
        setRevenueDetails(randomDetails);
      }, 400);
    }
    return () => clearInterval(interval);
  }, [data, facilityFilter, dateFilter])

  return (
    <Box bgcolor={"white"} p={3} borderRadius={"16px"} padding={"24px 24px 8px"}>
      <Typography variant="subtitle" fontSize='20px' fontWeight='600'>{t('revenue')}</Typography>
      {
        revenueDetails?.length > 0 &&
        <Grid container flexDirection='column'>
          <Grid item display='flex'>
            <Grid item display='flex' alignItems='center' sx={{ transform: 'rotate(-90deg)' }}fontSize='12px' fontWeight='600' textTransform='uppercase'>
            {t('amount')}
            </Grid>
            <BarChart
              dataset={revenueDetails}
              layout="vertical"
              {...chartSetting}
            />
          </Grid>
          <Grid item display='flex' justifyContent='center' fontSize='12px' fontWeight='600' textTransform='uppercase'>
          {t('date')}
          </Grid>
        </Grid>
      }
    </Box>
  );
};

export default RevenueChart;
