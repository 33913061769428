import React from 'react'

const VehicleDistance = () => {
    return (
        <svg width="110" height="115" viewBox="0 0 110 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_33329)">
                <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="8.5" width="83" height="83" rx="41.5" stroke="#FFCF52" stroke-width="3" />
                <path d="M53.2369 43.7734C53.2369 43.7734 56.4524 43.7734 60.0529 43.7734C62.5432 43.7734 64.5625 45.7922 64.5625 48.2824C64.5625 50.7727 62.5438 52.7914 60.0535 52.7914H52.4949C50.0685 52.7914 48.1016 54.7584 48.1016 57.1848C48.1016 59.6111 50.0685 61.5781 52.4949 61.5781H59.5369M40.375 40.8187C40.375 38.5697 42.255 36.7188 44.5742 36.7188C46.8934 36.7188 48.7734 38.5697 48.7734 40.8187C48.7734 41.5442 48.5753 42.2643 48.1992 42.8914L46.612 45.5375C45.7055 47.049 45.2522 47.8047 44.5742 47.8047C43.8962 47.8047 43.4429 47.049 42.5364 45.5375L40.9493 42.8914C40.5731 42.2643 40.375 41.5442 40.375 40.8187ZM64.2266 59.2953C64.2266 57.0463 66.1066 55.1953 68.4258 55.1953C70.7451 55.1953 72.625 57.0463 72.625 59.2953C72.625 60.0208 72.4268 60.7409 72.0505 61.368L70.4636 64.014C69.5572 65.5254 69.1037 66.2813 68.4258 66.2813C67.7478 66.2813 67.2945 65.5254 66.388 64.014L64.8008 61.368C64.4247 60.7409 64.2266 60.0208 64.2266 59.2953Z" stroke="#EEAD07" stroke-width="2.5" stroke-linecap="round" />
                <path d="M45.75 40.918C45.75 41.5673 45.2236 42.0938 44.5742 42.0938C43.9249 42.0938 43.3984 41.5673 43.3984 40.918C43.3984 40.2686 43.9249 39.7422 44.5742 39.7422C45.2236 39.7422 45.75 40.2686 45.75 40.918ZM69.6016 59.3945C69.6016 60.0439 69.0751 60.5703 68.4258 60.5703C67.7764 60.5703 67.25 60.0439 67.25 59.3945C67.25 58.7452 67.7764 58.2188 68.4258 58.2188C69.0751 58.2188 69.6016 58.7452 69.6016 59.3945Z" fill="#EEAD07" />
            </g>
            <circle cx="55" cy="50" r="49.8613" stroke="#467CEB" stroke-width="0.277344" stroke-dasharray="1.66 1.66" />
            <defs>
                <filter id="filter0_d_9271_33329" x="0" y="7" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_33329" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_33329" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default VehicleDistance