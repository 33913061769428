import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DealerSelect = ({ searchedDealerList, onDealerClick, onSubDealerClick, selectedDealerCard, subDealerData, selectedSubDealerCard }) => {
    const [selectedDealer, setSelectedDealer] = useState(selectedDealerCard || "All");
    const [selectedSubDealer, setSelectedSubDealer] = useState(selectedSubDealerCard || "All");

    useEffect(() => {
        setSelectedDealer(selectedDealerCard || "All");
        setSelectedSubDealer(selectedSubDealerCard || "All");
    }, [selectedDealerCard, selectedSubDealerCard]);

    const handleDealerChange = (event) => {
        setSelectedDealer(event.target.value);
        onDealerClick(event.target.value);
    };

    const handleSubDealerChange = (event) => {
        setSelectedSubDealer(event.target.value);
        onSubDealerClick(event.target.value);
    };
    return (
        <>
            {searchedDealerList && (
                <>
                    <Typography variant="body1" color="initial" mb={0} style={{ fontSize: "14px", color: "#5C677D" }}>
                        Select Dealer
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="select-dealer-label"
                            id="select-dealer"
                            value={selectedDealer}
                            onChange={handleDealerChange}
                            size="small"
                        >
                            <MenuItem value="All">All</MenuItem>
                            {searchedDealerList.map((dealer,index) => (
                                <MenuItem key={index} value={dealer.id}>
                                    {dealer.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}

            {subDealerData && (
                <>
                    <Typography variant="body1" color="initial" mb={1} style={{ fontSize: "14px", color: "#5C677D" }}>
                        Select Sub Dealer
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="select-sub-dealer-label"
                            id="select-sub-dealer"
                            value={selectedSubDealer}
                            onChange={handleSubDealerChange}
                            size="small"
                        >
                            <MenuItem value="All">All</MenuItem>
                            {subDealerData?.sub_dealers && subDealerData?.sub_dealers?.map((sub_dealers, index) => (
                                <MenuItem key={index} value={sub_dealers.id}>
                                    {sub_dealers.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}
        </>
    );
};

export default DealerSelect;