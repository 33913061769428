import React from "react";

const Users = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 110 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6118_41333)">
          <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
          <rect
            x="13.5"
            y="8.5"
            width="83"
            height="83"
            rx="41.5"
            stroke="#B5CBF7"
            stroke-width="3"
          />
          <g filter="url(#filter1_b_6118_41333)">
            <path
              d="M55 50.5C50.5625 50.5 47 46.9375 47 42.5C47 38.125 50.5625 34.5 55 34.5C59.375 34.5 63 38.125 63 42.5C63 46.9375 59.375 50.5 55 50.5ZM58.125 53.5C64.125 53.5 69 58.375 69 64.375C69 65.5625 68 66.5 66.8125 66.5H43.125C41.9375 66.5 41 65.5625 41 64.375C41 58.375 45.8125 53.5 51.8125 53.5H58.125Z"
              fill="#467CEB"
            />
          </g>
        </g>
        <circle
          cx="55"
          cy="50"
          r="49.8613"
          stroke="#467CEB"
          stroke-width="0.277344"
          stroke-dasharray="1.66 1.66"
        />
        <defs>
          <filter
            id="filter0_d_6118_41333"
            x="0"
            y="7"
            width="110"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6118_41333"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6118_41333"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_6118_41333"
            x="28.4783"
            y="21.9783"
            width="53.0435"
            height="57.0435"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.26087" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6118_41333"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6118_41333"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default Users;
