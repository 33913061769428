import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import api from "../../apiSetup/apiCall.js";
import noStations from "../../assets/emptyData/noStations.svg";
import { customizeName, greetingMessage } from "../../common.js";
import config from '../../config.js';
import CustomSearch from "../customComponents/CustomSearch";
import DynamicBox from "../DynamicBox.js";
import EmptyData from "../EmptyData";
import ProfileSection from "../ProfileSection";
import AlertAndNotification from "./AlertAndNotification.js";
import ChangeDashboardView from "./ChangeDashboardView";
import DealersMap from "./DealersMap.js";
import DynamicCardBox from "./DynamicCardBox.js";
import VehicleList from "./DynamicVehicle.js";
import Alert from "./icons/Alert.js";
import Average from "./icons/Average.js";
import CarbonEmissionSaved from "./icons/CarbonEmissionSaved.js";
import Dealers from "./icons/Dealers.js";
import Distance from "./icons/Distance.js";
import Duration from "./icons/Duration.js";
import Fleet from "./icons/Fleet.js";
import SubDealers from "./icons/SubDealers.js";
import TotalDistanceTravelled from "./icons/TotalDistanceTravelled.js";
import TotalEnergyConsumption from "./icons/TotalEnergyConsumption.js";
import Trips from "./icons/Trips.js";
import VehicleBlue from "./icons/VehicleBlue.js";
import VehicleDistance from "./icons/VehicleDistance.js";
import SubDealersMap from "./SubDealersMap.js";
import VehicleMap from "./VehicleMap.js";
import CircularProgress from '@mui/material/CircularProgress';
import useFetchDataOnPageUrl from "../../utilis/PageVisibleHooks.js";
import { useNavigate } from "react-router-dom";


const TelematicsDashboard = () => {
    const [searchedDealerList, setSearchedDealerList] = useState([]);
    const [searchedSubDealerList, setSearchedSubDealerList] = useState([]);
    const [searchedVehicleList, setSearchedVehicleList] = useState([]);
    const [searchTextDealer, setSearchTextDealer] = useState("");
    const [searchSubDealer, setSearchSubDealer] = useState("");
    const [searchTextVehicle, setSearchTextVehicle] = useState("");
    const [showDealerView, setShowDealerView] = useState(true);
    const [showSubDealerView, setShowSubDealerView] = useState(false);
    const [showVehicleView, setShowVehicleView] = useState(false);
    const [dealerData, setDealerData] = useState(null);
    const [subDealerData, setSubDealerData] = useState(null);
    const [vehicleData, setVehicleData] = useState(null);
    const [dealerloading, setDealerLoading] = useState(true)
    const [subDealerloading, setSubDealerLoading] = useState(false)
    const [vehicleloading, setVehicleLoading] = useState(false)
    const [clickCount, setClickCount] = useState(0);
    const navigate = useNavigate();
    const timer = useRef(null);
    const [dealerID, setDealerID] = useState(null)
    const [subDealerID, setSubDealerID] = useState(null)
    const [selectedVehicleMapData, setSelectedVehicleMapData] = useState(null)

    const handleDealerCard = (id) => {
        setSubDealerLoading(true)
        setShowDealerView(false)
        setShowSubDealerView(true)
        setShowVehicleView(false)
        fetchSubDealerData(id)
        setDealerID(id)
        setSelectedVehicleMapData(null)
    }

    const handleSubDealerCard = (id) => {
        setVehicleLoading(true)
        setShowSubDealerView(false)
        setShowVehicleView(true)
        fetchVehicleData(id)
        setSubDealerID(id)
        setSelectedVehicleMapData(null)
    }



    useEffect(() => {
        setSearchedDealerList(dealerData?.dealers);
        setSearchedSubDealerList(subDealerData?.sub_dealers);
        setSearchedVehicleList(vehicleData?.vehicles)
    }, [dealerData, subDealerData, vehicleData]);

    const handleSearch = (searchText, searchType) => {

        if (searchType == "dealer") {
            setSearchTextDealer(searchText);
            const searchTextLowerCase = searchText.toLowerCase();
            const filteredDealerList = dealerData?.dealers.filter((data) =>
                Object.values(data).some((value) => {
                    if (typeof value === "string") {
                        return value.toLowerCase().includes(searchTextLowerCase);
                    } else if (typeof value === "number") {
                        return value.toString().toLowerCase().includes(searchTextLowerCase);
                    } else if (value && typeof value === "object") {
                        return Object.values(value).some((val) => {
                            if (typeof val === "string") {
                                return val.toLowerCase().includes(searchTextLowerCase);
                            } else if (typeof val === "number") {
                                return val.toString().toLowerCase().includes(searchTextLowerCase);
                            }
                            return false;
                        });
                    }
                    return false;
                })
            );
            setSearchedDealerList(filteredDealerList);
        } else if (searchType == "subdealer") {
            setSearchSubDealer(searchText);
            const searchTextLowerCase = searchText.toLowerCase();
            const filteredSubDealerList = subDealerData?.sub_dealers?.filter((data) =>
                Object.values(data).some((value) => {
                    if (typeof value === "string") {
                        return value.toLowerCase().includes(searchTextLowerCase);
                    } else if (typeof value === "number") {
                        return value.toString().toLowerCase().includes(searchTextLowerCase);
                    } else if (value && typeof value === "object") {
                        return Object.values(value).some((val) => {
                            if (typeof val === "string") {
                                return val.toLowerCase().includes(searchTextLowerCase);
                            } else if (typeof val === "number") {
                                return val.toString().toLowerCase().includes(searchTextLowerCase);
                            }
                            return false;
                        });
                    }
                    return false;
                })
            );
            setSearchedSubDealerList(filteredSubDealerList);
        } else if (searchType == "vehicle") {
            setSearchTextVehicle(searchText);
            const searchTextLowerCase = searchText.toLowerCase();
            const filteredVehicleList = vehicleData?.vehicles.filter((data) =>
                Object.values(data).some((value) => {
                    if (typeof value === "string") {
                        return value.toLowerCase().includes(searchTextLowerCase);
                    } else if (typeof value === "number") {
                        return value.toString().toLowerCase().includes(searchTextLowerCase);
                    } else if (value && typeof value === "object") {
                        return Object.values(value).some((val) => {
                            if (typeof val === "string") {
                                return val.toLowerCase().includes(searchTextLowerCase);
                            } else if (typeof val === "number") {
                                return val.toString().toLowerCase().includes(searchTextLowerCase);
                            }
                            return false;
                        });
                    }
                    return false;
                })
            );
            setSearchedVehicleList(filteredVehicleList);
        }
    };

    const userData = JSON.parse(localStorage.getItem('userData'));

    const fetchData = async () => {
        try {
            const response = await api(config.TELEMATICS_URL + 'dashboard/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            setDealerData(response.data)
            setDealerLoading(false)
        }
        catch (error) {
            console.log(error.response);
        }
    }

    const singleClickHandler = (id) => {
        const selectedVehicle = vehicleData.vehicles && vehicleData.vehicles?.find(item => item.id === id);
        if (selectedVehicle) {
            setSelectedVehicleMapData({ vehicles: [selectedVehicle] });
            window.scrollBy({ top: 300, behavior: 'smooth' });
        }
    };

    const doubleClickHandler = (id) => {
        navigate(`/telematics/dealer-management/${dealerID}/${subDealerID}/${id}`);
    };

    const handleCardClick = (id, cardType) => {
        if (clickCount === 0) {
            setClickCount(1);
            timer.current = setTimeout(() => {
                singleClickHandler(id);
                setClickCount(0);
            }, 250);
        } else if (clickCount === 1) {
            clearTimeout(timer.current);
            doubleClickHandler(id);
            setClickCount(0);
        }
    };

    useFetchDataOnPageUrl(fetchData, '/telematics/dashboard');

    const fetchSubDealerData = async (id) => {
        try {
            const response = await api(config.TELEMATICS_URL + `dealer/${id}/subdealers/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            setSubDealerData(response.data)
            setSubDealerLoading(false)
        }
        catch (error) {
            console.log(error.response);
        }
    }

    const fetchVehicleData = async (id) => {
        try {
            const response = await api(config.TELEMATICS_URL + `subdealer/${id}/vehicles/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            setVehicleData(response.data)
            setVehicleLoading(false)
        }
        catch (error) {
            console.log(error.response);
        }
    }

    return (
        <>
            <Grid container rowSpacing={3} columnSpacing={3} alignItems='center'>
                <Grid item xs={8} style={{ flexGrow: 1, padding: '0 20px' }}>
                    <Typography variant="h4" fontSize='32px' fontWeight='600'>{greetingMessage()},
                        <Typography variant="subtitle" color='#467CEB'> {customizeName(userData?.data?.name ?? '')} !
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <ProfileSection />
                </Grid>
            </Grid>
            <ChangeDashboardView />
            <Grid container spacing={3} columnSpacing={3} sx={{ marginBottom: 2 }}>
                <Grid item xs={3}>
                    <Grid
                        container
                        justifyContent="center"
                        sx={{ flexGrow: 1 }}
                        alignItems="center"
                        rowSpacing={3}
                    >
                        <Grid item xs={12}>
                            {showDealerView && (
                                <DynamicBox title={'TOTAL NO OF DEALERS'} count={dealerData?.dealer_count ?? "0"} IconComponent={Dealers} />
                            )}
                            {showSubDealerView && (
                                <DynamicBox title={'TOTAL NO OF VEHICLES'} count={dealerData?.vehicles_count ?? "0"} IconComponent={VehicleBlue} />
                            )}
                            {showVehicleView && (
                                <DynamicBox title={'TRIPS'} count={"32 trips"} IconComponent={Trips} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid
                        container
                        justifyContent="center"
                        sx={{ flexGrow: 1 }}
                        alignItems="center"
                        rowSpacing={3}
                    >
                        <Grid item xs={12}>
                            {showDealerView && (
                                <DynamicBox title={'Total subdealers'} count={dealerData?.subdealer_count ?? "0"} IconComponent={SubDealers} />
                            )}
                            {showSubDealerView && (
                                <DynamicBox title={'AVERAGE MILAGE PER VEHICLE'} count={"56 km"} IconComponent={Average} />
                            )}
                            {showVehicleView && (
                                <DynamicBox title={'DISTANCE COVERED'} count={"194 KM"} IconComponent={VehicleDistance} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid
                        container
                        justifyContent="center"
                        sx={{ flexGrow: 1 }}
                        alignItems="center"
                        rowSpacing={3}
                    >
                        <Grid item xs={12}>
                            {showDealerView && (
                                <DynamicBox title={'TOTAL VEHICLES'} count={dealerData?.vehicles_count ?? "0"} IconComponent={Fleet} />
                            )}
                            {showSubDealerView && (
                                <DynamicBox title={'DISTANCE COVERED'} count={"688 KM"} IconComponent={Distance} />
                            )}
                            {showVehicleView && (
                                <DynamicBox title={'Total road duration'} count={"23 days"} IconComponent={Duration} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {!showVehicleView && (
                    <Grid item xs={3}>
                        <Grid
                            container
                            justifyContent="center"
                            sx={{ flexGrow: 1 }}
                            alignItems="center"
                            rowSpacing={3}
                        >
                            <Grid item xs={12}>
                                <DynamicBox title={'ALERTS'} count={dealerData?.alert_count ?? "0"} IconComponent={Alert} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                rowSpacing={3}
                columnSpacing={3}
                sx={{ marginTop: 1, marginLeft: "-16px", marginBottom: "20px" }}
            >
                {(showDealerView || showSubDealerView || showVehicleView) && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                    >
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={0}
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                padding: "16px",
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "12px",
                                }}
                            >
                                <Typography variant="body" color="initial">Dealers </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "5px",
                                }}
                            >
                                <CustomSearch
                                    placeholder="Search by Dealer Name"
                                    onSearch={handleSearch}
                                    value={searchTextDealer}
                                    searchType={"dealer"}
                                />
                            </Grid>
                            {dealerloading ? (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        height: "calc(100vh - 260px)",
                                        overflow: "auto",
                                        justifyContent: "center",
                                        display: "flex",
                                    }}
                                >
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                searchedDealerList?.length > 0 ? (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            height: "calc(100vh - 260px)",
                                            overflow: "auto",
                                        }}
                                    >
                                        <PerfectScrollbar
                                            sx={{
                                                overflow: "auto",
                                                maxHeight: "100%",
                                                scrollbarWidth: "thin", // For Firefox
                                                paddingRight: "15px"
                                            }}
                                        >

                                            <DynamicCardBox handleCardClick={handleDealerCard} searchedDealerList={searchedDealerList} type={"telematic"} cardType={"dealer"} />
                                        </PerfectScrollbar>
                                    </Grid>
                                ) : (
                                    <EmptyData
                                        imagePath={noStations}
                                        message="No Dealers Found"
                                    />
                                )
                            )}
                        </Grid>
                    </Grid>
                )}
                {(showSubDealerView || showVehicleView) && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                    >
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={0}
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                padding: "16px",
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "12px",
                                }}
                            >
                                <Typography variant="body" color="initial">Sub Dealers </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "5px",
                                }}
                            >
                                <CustomSearch
                                    placeholder="Search by Sub Dealer Name"
                                    onSearch={handleSearch}
                                    value={searchSubDealer}
                                    searchType={"subdealer"}
                                />
                            </Grid>
                            {subDealerloading ? (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        height: "calc(100vh - 260px)",
                                        overflow: "auto",
                                        justifyContent: "center",
                                        display: "flex",
                                    }}
                                >
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                searchedSubDealerList?.length > 0 ? (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            height: "calc(100vh - 260px)",
                                            overflow: "auto",
                                        }}
                                    >
                                        <PerfectScrollbar
                                            sx={{
                                                overflow: "auto",
                                                maxHeight: "100%",
                                                scrollbarWidth: "thin", // For Firefox
                                                paddingRight: "15px"
                                            }}
                                        >
                                            <DynamicCardBox handleCardClick={handleSubDealerCard} subDealerData={searchedSubDealerList} type={"telematic"} cardType={"subdealer"} />

                                        </PerfectScrollbar>
                                    </Grid>
                                ) : (
                                    <EmptyData
                                        imagePath={noStations}
                                        message="No Sub Dealers Found"
                                    />
                                ))}
                        </Grid>
                    </Grid>

                )}
                {showDealerView && (
                    <Grid item xs={9}>
                        <DealersMap dealerData={dealerData} />
                    </Grid>
                )}
                {showSubDealerView && (
                    <Grid item xs={6}>
                        <SubDealersMap subDealerData={subDealerData} />
                    </Grid>
                )}
                {showVehicleView && (
                    <Grid item xs={6}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={0}
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                padding: "16px",
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "12px",
                                }}
                            >
                                <Typography variant="body" color="initial">Vehicles </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "5px",
                                }}
                            >
                                <CustomSearch
                                    placeholder="Search by Vehicles Name"
                                    onSearch={handleSearch}
                                    value={searchTextVehicle}
                                    searchType={"vehicle"}
                                />
                            </Grid>
                            {vehicleloading ? (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        height: "calc(100vh - 260px)",
                                        overflow: "auto",
                                        justifyContent: "center",
                                        display: "flex",
                                    }}
                                >
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        height: "calc(100vh - 260px)",
                                        overflow: "auto",
                                    }}
                                >
                                    <PerfectScrollbar
                                        sx={{
                                            overflow: "auto",
                                            maxHeight: "100%",
                                            scrollbarWidth: "thin", // For Firefox
                                            paddingRight: "15px"
                                        }}
                                    >
                                        <VehicleList vehicleData={searchedVehicleList} loading={vehicleloading} type={"telematic"} handleCardClick={handleCardClick} cardType="vehicle" />

                                    </PerfectScrollbar>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {showVehicleView && (
                <Grid container spacing={0}>
                    <Grid item xs={12} rowSpacing={3} sx={{ marginBottom: 2 }}
                        columnSpacing={3}>
                        <VehicleMap vehicleData={selectedVehicleMapData ? selectedVehicleMapData : vehicleData} />
                    </Grid>
                </Grid>
            )}
            {showDealerView && (
                <Grid container columnSpacing={3}>

                    <Grid item xs={3}>
                        <Grid
                            container
                            alignItems="center"
                            rowSpacing={3}
                            columnSpacing={3}
                            sx={{ marginBottom: 2 }}
                        >
                            <Grid item xs={12}>
                                <DynamicBox title={'Total Energy Consumption'} count={"119 kWh"} IconComponent={TotalEnergyConsumption} />
                            </Grid>
                            <Grid item xs={12}>
                                <DynamicBox title={'Total distance travelled'} count={"123km"} IconComponent={TotalDistanceTravelled} />
                            </Grid>
                            <Grid item xs={12}>
                                <DynamicBox title={'Carbon Emission saved'} count={"80kg"} IconComponent={CarbonEmissionSaved} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={9}>
                        <AlertAndNotification dealerData={dealerData} />
                    </Grid>
                </Grid>
            )}
            {(showSubDealerView || showVehicleView) && (
                <Grid container columnSpacing={3}>
                    <Grid item xs={12}>
                        <AlertAndNotification dealerData={dealerData} />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default TelematicsDashboard