import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, Tooltip, ListItemButton } from "@mui/material";
import { Link, useLocation, useNavigate, matchPath } from "react-router-dom";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import EvStationIcon from "@mui/icons-material/EvStation";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "./Logo";
import { useDispatch, useSelector } from "react-redux";
import { clearLoginState } from "../slice/LoginSlice";
import MapIcon from '@mui/icons-material/Map';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AssessmentIcon from '@mui/icons-material/Assessment';
const drawerWidth = 100;

const Sidebar = () => {
  const location = useLocation();
  const [_activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.data.data);

  const telematicsMenuItem = [
    { icon: <AutoAwesomeMosaicIcon />, name: "Dashboard", link: "/telematics/dashboard" },
    { icon: <AssessmentIcon />, name: "Dealers", link: "/telematics/dealer-management" },
    { icon: <MapIcon />, name: "Map", link: "/telematics/vehicle-map" },
  ];

  const chargerMenuItem = [
    { icon: <AutoAwesomeMosaicIcon />, name: "Dashboard", link: "/charger/dashboard" },
    { icon: <BookOnlineIcon />, name: "Overview", link: "/charger/station" },
    { icon: <EvStationIcon />, name: "Station Management", link: "/charger/charging-stations" },
    { icon: <AccountBalanceWalletIcon />, name: "Billing And Payment", link: "/charger/billing-payment" },
    { icon: <MapIcon />, name: "Map", link: "/charger/map" },
  ];

  const menuItems = (location.pathname.split('/')).at(1) === 'charger' ? chargerMenuItem : telematicsMenuItem;

  if ((userData?.facility_admin_type === "superadmin" || userData?.facility_admin_type === "countryadmin") && (location.pathname.split('/')).at(1) === 'charger') {
    menuItems.splice(3, 0, { icon: <PersonIcon />, name: "User Management", link: "/charger/user-management" });
    menuItems.push({ icon: <CardGiftcardIcon />, name: "Coupon", link: "/charger/coupon" });
  }

  const handleItemClick = (index, event) => {
    setActiveIndex(index);
  };

  const handleLogout = () => {
    navigate('/login');
    localStorage.clear();
    dispatch(clearLoginState());
  };

  const isActive = (menuItemLink) => {
    return location.pathname.startsWith(menuItemLink);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth - 30,
          boxSizing: "border-box",
          overflowX: "hidden",
          margin: '20px 15px',
          height: 'calc(100% - 40px)',
          borderRadius: '16px'
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Logo />
      <List>
        {menuItems.map((menuItem, index) => (
          <Tooltip title={menuItem.name} key={index} arrow placement="right">
            <ListItem
              sx={{
                padding: 2,
                backgroundColor: isActive(menuItem.link) && (menuItem.link === '/telematics/dealer-management' || location.pathname === menuItem.link) ? "#e0e0e0" : "transparent",
              }}
              onClick={(event) => handleItemClick(index, event)}
              component={Link}
              to={menuItem.link}
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
            </ListItem>
          </Tooltip>
        ))}
      </List>
      <List style={{ position: "absolute", bottom: "0px" }}>
        {["LogoutIcon"].map((iconKey, index) => (
          <Tooltip title="Logout" key={index} arrow placement="right">
            <ListItem
              key={iconKey}
              disablePadding
              component={Link}
              onClick={handleLogout}
            >
              <ListItemButton>
                <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;