import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import carChargingDashboard from "../assets/dashBoard/carChargingDashboard.png";
import CustomOptionsModal from "./customComponents/CustomOptionsModal";
import DynamicFormModal from "./customComponents/DynamicFormModal";
import { useSelector } from "react-redux";

const CustomCard = ({ cardData, onClick, isSelected, onSelect, apiCallType = "editStation"}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState(null);
  // const [options, setOptions] = useState("");
  const [id, setId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalType, setDynamicModalType] = useState("");

  const userStatus = useSelector((state) => state.user.data.data);

  const handleClick = () => {
    if(!isModalOpen && !isDynamicModalOpen) {
      onClick && onClick(cardData.id);
      onSelect && onSelect(cardData.id);
    }
  };

  const handleOpenModal = (id, name, event) => {
    setAnchorEl(event.currentTarget); 
    setIsModalOpen(true);
    // setOptions("Station Options");
    setId(`Station ID: ${id}`);
    setSelectedCharger({ id, name });
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const handleEditFormOpen = () => {
    const newApiCallType = apiCallType;
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsModalOpen(false);    
    setIsDynamicModalOpen(true);
  };

  // const handleDelete = () => {
  //   handleCloseModal();
  // };


  return (
    <>
      <Card
        sx={{
          display: "flex",
          height: "108px",
          marginBottom: "16px",
          cursor: "pointer",
          border: isSelected ? "1px solid #B5CBF7" : "1px solid #EAEBEE",
          background: isSelected ? "#EFF4FF" : "#FFF",
          borderRadius: "8px",
          position: "relative",
          boxShadow: "none",
        }}
        onClick={handleClick}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "var(--light-mode-gray-100, #001233)",
            display: userStatus?.edit_station ? "block" : "none"
          }}
          aria-label="options"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenModal(cardData.id, cardData.name, event);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <CustomOptionsModal
          isOpen={isModalOpen}
          anchorEl={anchorEl}
          onClose={(e) => handleCloseModal(e)}
          // options={options}
          ID={id}
          handleEdit={handleEditFormOpen}
          // handleDelete={handleDelete}
        />
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            sx={{ width: "108px", height: "108px", flexShrink: 0 }}
            image={carChargingDashboard}
            alt="Custom Card Image"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <CardContent>
            <Typography
              component="div"
              style={{
                overflow: "hidden",
                color: "var(--light-mode-gray-100, #001233)",
                textOverflow: "ellipsis",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {cardData.name}
            </Typography>
          </CardContent>
          <Box
            sx={{
              position: "relative",
              marginLeft: "16px",
              paddingBottom: "16px",
              width: "100%",
              overflow: "hidden",
            }}
          >
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            style={{
              overflow: "hidden",
              color: "var(--light-mode-gray-60, #979DAC)",
              textOverflow: "ellipsis",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16px",
              maxWidth: "160px",
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              textTransform: "capitalize"
            }}
            title={`${cardData.address}, ${cardData.pincode}, ${cardData.country}`}
          >
            {`${cardData.address}, ${cardData.pincode}, ${cardData.country}`}
          </Typography>
          </Box>
        </Box>
      </Card>
      <DynamicFormModal
        isOpen={isDynamicModalOpen}
        onClose={() => setIsDynamicModalOpen(false)}
        apiCallType={dynamicModalType}
        id={selectedCharger?.id}
      />
    </>
  );
};

export default CustomCard;
