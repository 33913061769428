import React from "react";

const UnitConsumed = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 110 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6118_41277)">
          <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
          <rect
            x="13.5"
            y="8.5"
            width="83"
            height="83"
            rx="41.5"
            stroke="#B5CBF7"
            stroke-width="3"
          />
          <g filter="url(#filter1_b_6118_41277)">
            <path
              d="M58 48.5H65C65.8125 48.5 66.5625 49.0625 66.8125 49.8125C67.125 50.625 66.9375 51.5 66.3125 52.0625L50.3125 66.0625C49.5625 66.625 48.5625 66.6875 47.8125 66.125C47.0625 65.625 46.75 64.625 47.125 63.75L51.9375 52.5H44.9375C44.125 52.5 43.375 52 43.125 51.25C42.8125 50.4375 43 49.5625 43.625 49L59.625 35C60.375 34.4375 61.375 34.375 62.125 34.9375C62.875 35.4375 63.1875 36.4375 62.8125 37.3125L58 48.5Z"
              fill="#467CEB"
            />
          </g>
        </g>
        <circle
          cx="55"
          cy="50"
          r="49.8613"
          stroke="#467CEB"
          stroke-width="0.277344"
          stroke-dasharray="1.66 1.66"
        />
        <defs>
          <filter
            id="filter0_d_6118_41277"
            x="0"
            y="7"
            width="110"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6118_41277"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6118_41277"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_6118_41277"
            x="30.2908"
            y="21.8533"
            width="49.356"
            height="57.356"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.26087" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6118_41277"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6118_41277"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default UnitConsumed;
