import React, { useState } from "react";
import {
    Grid,
    Paper,
    Typography,
    CircularProgress,
} from "@mui/material";
import "react-perfect-scrollbar/dist/css/styles.css";
import ProfileSection from "../ProfileSection";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GridViewIcon from '@mui/icons-material/GridView';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ApiIcon from '@mui/icons-material/Api';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CreateNewApp from "./CreateNewApp";
import AppCredentials from "./AppCredentials";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function MyTabs({ value, handleChange }) {
    return (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderColor: 'divider', textTransform: "capitalize" }}
            TabIndicatorProps={{ style: { display: 'none' } }}
        >
            <Tab
                icon={<GridViewIcon style={{ borderRadius: '50%', border: "1px solid #90B0F3", padding: "12px", height: "52px", width: "52px", backgroundColor: value === 0 ? '#DAE5FB' : '#F5F5F7' }} />}
                iconPosition="start"
                aria-label="phone"
                label="Create new app"
                {...a11yProps(0)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#EFF4FF',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE",
                    marginBottom: "10px",
                    borderRadius: "8px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<LockOutlinedIcon style={{ borderRadius: '50%', border: "1px solid #90B0F3", padding: "12px", height: "52px", width: "52px", backgroundColor: value === 1 ? '#DAE5FB' : '#F5F5F7' }} />}
                iconPosition="start"
                aria-label="favorite"
                label="App credentials"
                {...a11yProps(1)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#EFF4FF',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE",
                    marginBottom: "10px",
                    borderRadius: "8px",
                    textTransform: "capitalize"

                }}
            />
        </Tabs>
    );
}


function MyNestedTabs({ value, handleChange }) {
    return (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ marginLeft: "20px" }}
            TabIndicatorProps={{ style: { display: 'none' } }}
        >
            <Tab
                icon={<GridViewIcon />}
                iconPosition="start"
                aria-label="phone"
                label="List charger API"
                {...a11yProps(2)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    borderRadius: "8px",
                    minHeight: "52px",
                    textTransform: "capitalize"

                }}
            />
            <Tab
                icon={<LockOutlinedIcon />}
                iconPosition="start"
                aria-label="favorite"
                label="Start charging API"
                {...a11yProps(3)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    borderRadius: "8px",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<ApiIcon />}
                iconPosition="start"
                aria-label="person"
                label="Stop charging API"
                {...a11yProps(4)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    borderRadius: "8px",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<ApiIcon />}
                iconPosition="start"
                aria-label="person"
                label="Charging status"
                {...a11yProps(5)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    borderRadius: "8px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<ApiIcon />}
                iconPosition="start"
                aria-label="person"
                label="Charging logs"
                {...a11yProps(6)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    borderRadius: "8px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<ApiIcon />}
                iconPosition="start"
                aria-label="person"
                label="Clear cache"
                {...a11yProps(7)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    borderRadius: "8px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<ApiIcon />}
                iconPosition="start"
                aria-label="person"
                label="Get configuration"
                {...a11yProps(8)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    borderRadius: "8px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
            <Tab
                icon={<ApiIcon />}
                iconPosition="start"
                aria-label="person"
                label="Unlock connector"
                {...a11yProps(9)}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#F5F5F7',
                    },
                    justifyContent: "start",
                    border: "1px solid #EAEBEE", marginBottom: "10px",
                    borderRadius: "8px",
                    minHeight: "52px",
                    textTransform: "capitalize"
                }}
            />
        </Tabs>
    );
}

const MyApp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [mainTabValue, setMainTabValue] = useState(0);
    const [nestedTabValue, setNestedTabValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
        setNestedTabValue(null);
    };

    const handleSaveAndNextTab = () => {
        setMainTabValue((prevTab) => prevTab + 1);
      };

    const handleNestedTabChange = (event, newValue) => {
        setNestedTabValue(newValue);
        setMainTabValue(null);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            {isLoading && (
                <Grid sx={{ position: "absolute", top: "50%", left: "50%" }}>
                    <CircularProgress />
                </Grid>
            )}
            {!isLoading && (
                <>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={3}
                        alignItems={"center"}
                    >
                        <Grid item xs={8} sx={{ flexGrow: 1 }}>
                            <Typography variant="h4" fontSize='24px' fontWeight='600'>
                                My App
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <ProfileSection />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={3}
                        sx={{ marginTop: 1, marginLeft: "-16px" }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{
                                borderRadius: "16px",
                                background: "#FFF",
                                boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)",
                                padding: "16px",
                            }}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={3}
                                sx={{
                                    maxHeight: "calc(100vh - 130px)",
                                    overflow: "auto",
                                    padding: 0
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <MyTabs value={mainTabValue} handleChange={handleMainTabChange} />
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={handleClick} style={{
                                            border: "1px solid #EAEBEE",
                                            marginBottom: "10px",
                                            borderRadius: "8px"
                                        }}>
                                            <ListItemIcon>
                                                <ApiIcon style={{ borderRadius: '50%', border: "1px solid #90B0F3", padding: "12px", height: "52px", width: "52px", backgroundColor: '#F5F5F7' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="API" />
                                            {open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <MyNestedTabs value={nestedTabValue} handleChange={handleNestedTabChange} />
                                        </Collapse>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            // sx={{ paddingTop: "0" }}
                            style={{ paddingTop: "0" }}
                            sx={{
                                maxHeight: "calc(100vh - 130px)",
                                overflow: "auto",
                                paddingTop: "0",
                                paddingBottom: "30px"
                            }}
                        >
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        borderRadius: "16px",
                                        background: "#fff",
                                        boxShadow: "none",
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            padding: "16px 20px",
                                            alignItems: "flex-start",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Grid sx={{ width: "100%" }}>
                                            <TabPanel value={mainTabValue} index={0} sx={{ padding: 0 }}>
                                                <CreateNewApp onSave={handleSaveAndNextTab}/>
                                            </TabPanel>
                                            <TabPanel value={mainTabValue} index={1}>
                                                <AppCredentials />
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={0}>
                                                Item Three
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={1}>
                                                Item Four
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={2}>
                                                Item Five
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={3}>
                                                Item Six
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={4}>
                                                Item Seven
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={5}>
                                                Item Seven
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={6}>
                                                Item Seven
                                            </TabPanel>
                                            <TabPanel value={nestedTabValue} index={7}>
                                                Item Seven
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

export default MyApp