import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Container } from '@mui/material';
import Sidebar from '../Sidebar';

const NotFound = () => {
  return (
    <>
        <Sidebar/>
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh"}}>
        <Typography variant="h4" gutterBottom>
            404 - Page Not Found
        </Typography>
        <Typography variant="body1" paragraph>
            The page you are looking for does not exist.
        </Typography>
        <Button component={Link} to="/dashboard" variant="contained" color="primary">
            Go to Dashboard
        </Button>
        </Container>
    </>
  );
};

export default NotFound;