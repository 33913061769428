import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

const CustomToggle = ({ label, value, onValueChange }) => {

  const [toggleValue, setToggleValue] = useState(Boolean(value));

  useEffect(() => {
    onValueChange(toggleValue);
  }, []);

  const handleChange = () => {
    const newValue = !toggleValue;
    setToggleValue(newValue);
    onValueChange(newValue);
  };

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center">
        <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>{label}</Typography>
        <Switch
          checked={toggleValue}
          onChange={handleChange}
          inputProps={{ "aria-label": "Toggle" }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomToggle;
