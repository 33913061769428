
export const BharatacOn = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
                </defs>
                <g transform="translate(0.369)">
                    <circle class="a" cx="25" cy="25" r="25" transform="translate(-0.369)" />
                    <g transform="translate(11 9)">
                        <path class="b" d="M32.752,352.414a14.25,14.25,0,1,0-17.514,14.6,3.132,3.132,0,0,0,3.132,3.132h.753a3.132,3.132,0,0,0,3.132-3.132v-.116A14.259,14.259,0,0,0,32.752,352.414Zm-10.5,12.348a11.843,11.843,0,0,1-1.51.38v.542a2,2,0,0,1-3.994,0v-.468a11.9,11.9,0,0,1-1.513-.317,12.211,12.211,0,1,1,7.017-.137Z" transform="translate(-4.27 -338.886)" />
                        <path class="b" d="M26.908,351.86a9.688,9.688,0,1,0,9.688,9.688A9.687,9.687,0,0,0,26.908,351.86Zm-7.224,6.894a3.046,3.046,0,1,1,3.047,3.044A3.049,3.049,0,0,1,19.683,358.754Zm7.453,11.007a3.047,3.047,0,1,1,3.047-3.047A3.049,3.049,0,0,1,27.136,369.761Zm4.216-7.963a3.046,3.046,0,1,1,3.047-3.044A3.047,3.047,0,0,1,31.352,361.8Z" transform="translate(-12.663 -347.295)" />
                        <path class="b" d="M55.228,368.12a1.17,1.17,0,1,0,1.172,1.172A1.171,1.171,0,0,0,55.228,368.12Z" transform="translate(-36.539 -357.833)" />
                        <path class="b" d="M30.722,368.12a1.17,1.17,0,1,0,1.168,1.172A1.171,1.171,0,0,0,30.722,368.12Z" transform="translate(-20.654 -357.833)" />
                        <circle class="b" cx="1.172" cy="1.172" r="1.172" transform="translate(13.301 18.251)" />
                    </g>
                </g>
            </svg>
        </>);
}