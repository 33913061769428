


export const ChademoOn = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
                </defs>
                <g transform="translate(-0.191)">
                    <circle class="a" cx="25" cy="25" r="25" transform="translate(0.191)" />
                    <g transform="translate(12 12)"><path class="b" d="M243.023,79.753a4.081,4.081,0,1,0-4.083,4.083A4.08,4.08,0,0,0,243.023,79.753Zm-4.083,1.568a1.57,1.57,0,1,1,1.571-1.568A1.57,1.57,0,0,1,238.94,81.321Z" transform="translate(-231.659 -67.184)" />
                        <path class="b" d="M274.253,75.67a4.083,4.083,0,1,0,4.083,4.083A4.08,4.08,0,0,0,274.253,75.67Zm0,5.651a1.57,1.57,0,1,1,1.568-1.568A1.573,1.573,0,0,1,274.253,81.321Z" transform="translate(-256.4 -67.184)" />
                        <path class="b" d="M259.087,102.62a3.017,3.017,0,1,0,3.017,3.017A3.016,3.016,0,0,0,259.087,102.62Zm0,.551a.536.536,0,1,1-.536.536A.535.535,0,0,1,259.084,103.171Zm0,4.933a.536.536,0,1,1,.536-.536A.535.535,0,0,1,259.084,108.1Zm.632.1a.9.9,0,1,0-1.263,0,2.65,2.65,0,0,1-1.94-1.94.9.9,0,1,0,0-1.263,2.65,2.65,0,0,1,1.94-1.94.9.9,0,1,0,1.53.638.884.884,0,0,0-.269-.638,2.645,2.645,0,0,1,1.943,1.952.9.9,0,1,0,0,1.233A2.64,2.64,0,0,1,259.716,108.208Zm-3.1-2.571a.536.536,0,1,1,.536.536A.535.535,0,0,1,256.618,105.637Zm4.927,0a.536.536,0,1,1-.536-.536A.535.535,0,0,1,261.545,105.637Z" transform="translate(-246.52 -86.067)" />
                        <path class="b" d="M259.087,62.524a3.017,3.017,0,1,0-3.017-3.017A3.018,3.018,0,0,0,259.087,62.524Zm0-.548a.536.536,0,1,1,.536-.536A.535.535,0,0,1,259.084,61.977Zm0-4.936a.536.536,0,1,1-.536.536A.535.535,0,0,1,259.084,57.041Zm-.629-.1a.9.9,0,1,0,1.263,0,2.645,2.645,0,0,1,1.943,1.952.9.9,0,1,0-.65,1.515.888.888,0,0,0,.65-.281,2.645,2.645,0,0,1-1.943,1.952.9.9,0,1,0-1.263,0,2.65,2.65,0,0,1-1.94-1.94.9.9,0,1,0,0-1.26A2.641,2.641,0,0,1,258.456,56.939Zm3.089,2.568a.536.536,0,1,1-.536-.536A.535.535,0,0,1,261.545,59.507Zm-4.927,0a.536.536,0,1,1,.536.536A.535.535,0,0,1,256.618,59.507Z" transform="translate(-246.52 -53.745)" />
                        <path class="b" d="M247.347,52.834l.26-.94a.639.639,0,0,0-.165-.626l-2.074-2.074a.638.638,0,0,0-.65-.156l-.844.272a.525.525,0,0,1-.443-.057,12.574,12.574,0,0,0-13.395,0,.525.525,0,0,1-.443.057l-.841-.272a.644.644,0,0,0-.65.156l-2.077,2.077a.637.637,0,0,0-.165.626l.26.937a.536.536,0,0,1-.063.422,12.567,12.567,0,1,0,21.348,0A.523.523,0,0,1,247.347,52.834Zm.886,7.387a11.507,11.507,0,0,1-23.009-.329,11.415,11.415,0,0,1,1.353-5.415c.129-.242.365-.629.512-.859a.524.524,0,0,0,.063-.422l-.32-1.143a.639.639,0,0,1,.165-.626l1.209-1.209a.637.637,0,0,1,.65-.156l1.027.332a.522.522,0,0,0,.455-.066q.4-.269.826-.5a11.517,11.517,0,0,1,11.141,0c.281.156.557.323.823.5a.528.528,0,0,0,.458.066l1.03-.332a.644.644,0,0,1,.65.156l1.209,1.209a.637.637,0,0,1,.165.626l-.32,1.149a.515.515,0,0,0,.069.428c.18.278.35.563.506.859A11.411,11.411,0,0,1,248.233,60.221Z" transform="translate(-224.165 -47.32)" />
                    </g>
                </g>
            </svg>
        </>
    );
}