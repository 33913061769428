import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, Typography } from '@mui/material';
import { LineChart, LinePlot, Axis } from '@mui/x-charts';

const VehicleOverview = () => {
    const [selectedMetrics, setSelectedMetrics] = useState(['coordinate', 'battery_current']);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSelectedMetrics((prevSelected) =>
            checked ? [...prevSelected, name] : prevSelected.filter((metric) => metric !== name)
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedMetrics.includes('coordinate')}
                                onChange={handleCheckboxChange}
                                name="coordinate"
                                size="small"
                            />
                        }
                        label="Coordinate"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedMetrics.includes('battery_current')}
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Battery Current"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Sig Bat Thermal Runaway Warning"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="SigBat Severe Thermal Runaway"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Battery Voltage"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Avg. Cell Voltage"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="DiffVoltL1"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="State of charge"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="SOH"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Sig Cycle count"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="SG Key On Signal"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Cell Temperature"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="battery_current"
                                size="small"
                            />
                        }
                        label="Hardware version"
                    />

                </FormGroup>
            </Grid>
            <Grid item xs={9}>
                <Paper>
                    {selectedMetrics.includes('battery_current') && (
                        <div style={{ height: 500 }}>
                            <LineChart
                                xAxis={[{ data: [1, 2, 3, 5, 8, 10], label: 'Time' }]}
                                series={[
                                    {
                                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                                        label: 'Current (amps)',
                                    },
                                ]}
                                xKey="time"
                                yKey="current"
                                width={700}
                                height={500}
                            >
                                <LinePlot />
                            </LineChart>
                        </div>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};


export default VehicleOverview