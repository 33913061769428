

export const Cccs2On=()=>{
    return(
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <defs>
        <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
        </defs><g transform="translate(-698 -577)">
        <circle class="a" cx="25" cy="25" r="25" transform="translate(698 577)"/>
        <g transform="translate(698 577)"><g transform="translate(15.076 12)">
        <path class="b" d="M129.015,191.379h-.168v-.156a9.513,9.513,0,0,0,4.294-14.643c-.124-.165-.254-.329-.392-.485a9.465,9.465,0,0,0-2.522-2.075h-9.38a9.584,9.584,0,0,0-2.522,2.075c-.138.156-.268.319-.392.485a9.513,9.513,0,0,0,4.333,14.657v.142h-.207a4.48,4.48,0,0,0,0,8.96h6.953a4.48,4.48,0,0,0,0-8.96Zm-11.1-9.081a7.6,7.6,0,0,1,2.585-5.72c.2-.17.4-.333.611-.485h8.867a7.745,7.745,0,0,1,.611.485,7.627,7.627,0,1,1-12.673,5.72ZM131.3,197.966a2.968,2.968,0,0,1-2.107.874h-7.317a2.982,2.982,0,0,1,0-5.965h7.317a2.982,2.982,0,0,1,2.107,5.091Z" transform="translate(-116.02 -174.02)"/>
        <path class="b" d="M133.043,257.23a2.443,2.443,0,1,0,2.443,2.443A2.442,2.442,0,0,0,133.043,257.23Zm0,3.977a1.536,1.536,0,1,1,1.536-1.536A1.536,1.536,0,0,1,133.043,261.207Z" transform="translate(-127.201 -237.834)"/>
        <circle class="b" cx="0.811" cy="0.811" r="0.811" transform="translate(4.693 21.837) rotate(-45)"/>
        <path class="b" d="M164.2,257.23a2.443,2.443,0,1,0,2.443,2.443A2.442,2.442,0,0,0,164.2,257.23Zm0,3.977a1.536,1.536,0,1,1,1.536-1.536A1.536,1.536,0,0,1,164.2,261.207Z" transform="translate(-151.098 -237.834)"/>
        <circle class="b" cx="0.811" cy="0.811" r="0.811" transform="translate(12.13 21.234) rotate(-13.25)"/>
        <path class="b" d="M165.05,192.31a.62.62,0,1,0-.62-.62A.62.62,0,0,0,165.05,192.31Z" transform="translate(-153.146 -187.096)"/>
        <path class="b" d="M145.64,192.31a.62.62,0,1,0-.62-.62A.62.62,0,0,0,145.64,192.31Z" transform="translate(-138.26 -187.096)"/>
        <path class="b" d="M173.557,203.95a1.007,1.007,0,1,0,1.007,1.007A1.009,1.009,0,0,0,173.557,203.95Z" transform="translate(-159.373 -196.973)"/>
        <path class="b" d="M153.537,203.95a1.007,1.007,0,1,0,1.007,1.007A1.009,1.009,0,0,0,153.537,203.95Z" transform="translate(-144.02 -196.973)"/>
        <path class="b" d="M134.317,203.95a1.007,1.007,0,1,0,1.007,1.007A1.009,1.009,0,0,0,134.317,203.95Z" transform="translate(-129.28 -196.973)"/>
        <path class="b" d="M163.477,220.75a1.007,1.007,0,1,0,1.007,1.007A1.009,1.009,0,0,0,163.477,220.75Z" transform="translate(-151.643 -209.857)"/>
        <path class="b" d="M144.267,220.75a1.007,1.007,0,1,0,1.007,1.007A1.009,1.009,0,0,0,144.267,220.75Z" transform="translate(-136.91 -209.857)"/>
        <path class="b" d="M141.089,191.3a6.88,6.88,0,0,0-3.051-5.72h-7.664a6.884,6.884,0,1,0,10.715,5.72Zm-4.5-5.235a1.417,1.417,0,1,1-1.417,1.417A1.417,1.417,0,0,1,136.59,186.065Zm-4.524,0a1.417,1.417,0,1,1-1.417,1.417A1.417,1.417,0,0,1,132.066,186.065Zm-4.079,4.8a1.738,1.738,0,1,1,1.739,1.737A1.739,1.739,0,0,1,127.987,190.869Zm4.056,5.655a1.739,1.739,0,1,1,1.737-1.739A1.739,1.739,0,0,1,132.043,196.524Zm.422-5.655a1.738,1.738,0,1,1,1.739,1.737A1.739,1.739,0,0,1,132.464,190.869Zm4.056,5.655a1.739,1.739,0,1,1,1.739-1.739A1.737,1.737,0,0,1,136.52,196.524Zm.611-5.655a1.738,1.738,0,1,1,1.739,1.737A1.739,1.739,0,0,1,137.131,190.869Z" transform="translate(-124.686 -182.885)"/>
        </g>
        </g>
        </g>
        </svg>
        </>
    );
}