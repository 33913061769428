import React from 'react'

const Fleet = () => {
    return (
        <svg width="110" height="115" viewBox="0 0 110 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_8133_19688)">
                <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="8.5" width="83" height="83" rx="41.5" stroke="#7FE0BD" stroke-width="3" />
                <path d="M70.5799 53.838V57.79H68.2239C67.8439 55.852 66.1719 54.37 64.1199 54.37C62.0679 54.37 60.3579 55.852 60.0159 57.79H57.6599V44.87H61.6879C62.4859 44.87 63.2079 45.25 63.6259 45.934L65.9819 49.696C66.4379 50.418 67.1219 50.95 67.9199 51.254L69.0979 51.672C69.9719 52.014 70.5799 52.888 70.5799 53.838ZM39.4199 57.79H42.9159C43.2959 55.852 44.9679 54.37 47.0199 54.37C49.0719 54.37 50.7819 55.852 51.1239 57.79H56.1399V52.47H39.4199V57.79ZM53.8599 50.95H56.1399V38.79H53.8599V50.95ZM41.6999 38.79H39.4199V50.95H41.6999V38.79ZM64.1199 55.89C62.6379 55.89 61.4599 57.068 61.4599 58.55C61.4599 60.032 62.6379 61.21 64.1199 61.21C65.6019 61.21 66.7799 60.032 66.7799 58.55C66.7799 57.068 65.6019 55.89 64.1199 55.89ZM47.0199 55.89C45.5379 55.89 44.3599 57.068 44.3599 58.55C44.3599 60.032 45.5379 61.21 47.0199 61.21C48.5019 61.21 49.6799 60.032 49.6799 58.55C49.6799 57.068 48.5019 55.89 47.0199 55.89ZM52.3399 47.91H43.2199V50.95H52.3399V47.91ZM52.3399 43.35H43.2199V46.39H52.3399V43.35ZM52.3399 38.79H43.2199V41.83H52.3399V38.79Z" fill="#30C48F" />
            </g>
            <circle cx="55" cy="50" r="49.8613" stroke="#467CEB" stroke-width="0.277344" stroke-dasharray="1.66 1.66" />
            <defs>
                <filter id="filter0_d_8133_19688" x="0" y="7" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8133_19688" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8133_19688" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default Fleet