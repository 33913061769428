import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchEditStationForm = createAsyncThunk('editStation/fetchEditStationForm', async (stationId,  { rejectWithValue }) => {
    try {
        const response = await api.get(`prometheus/station/${stationId}/edit/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching edt station form', error);
        throw rejectWithValue(error);
    }
    });

const EditStationSlice = createSlice({
  name: 'editStation',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditStationForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchEditStationForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditStationForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default EditStationSlice.reducer;
