import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchEditUserForm = createAsyncThunk('editStation/fetchEditStationForm', async (selectedUserEdit,  { rejectWithValue }) => {
    try {
        const response = await api.get(`${selectedUserEdit}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching edit user form', error);
        throw rejectWithValue(error);
    }
    });

const EditUserSlice = createSlice({
  name: 'editUser',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    isEditFormOpen: false,
    userId: 0
  },
  reducers: {
    changeEditFormOpen: (state,action)=>{
      state.isEditFormOpen = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditUserForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchEditUserForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditUserForm.rejected, (state) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export const {changeEditFormOpen, setUserId} = EditUserSlice.actions;
export default EditUserSlice.reducer;
