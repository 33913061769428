import React from 'react'

const Trips = () => {
    return (
        <svg width="110" height="115" viewBox="0 0 110 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_33300)">
                <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="8.5" width="83" height="83" rx="41.5" stroke="#C0C4CD" stroke-width="3" />
                <g clip-path="url(#clip0_9271_33300)">
                    <path d="M65.4 56.3333C64.2716 56.3333 63.3183 57.0596 62.9595 58.0667H47.2C45.2881 58.0667 43.7333 56.5119 43.7333 54.6C43.7333 52.6881 45.2881 51.1333 47.2 51.1333H62.8C65.6669 51.1333 68 48.8003 68 45.9333C68 43.0664 65.6669 40.7333 62.8 40.7333H47.0405C46.6817 39.7263 45.7284 39 44.6 39C43.1665 39 42 40.1665 42 41.6C42 43.0335 43.1665 44.2 44.6 44.2C45.7284 44.2 46.6817 43.4737 47.0405 42.4667H62.8C64.7119 42.4667 66.2667 44.0215 66.2667 45.9333C66.2667 47.8452 64.7119 49.4 62.8 49.4H47.2C44.3331 49.4 42 51.7331 42 54.6C42 57.4669 44.3331 59.8 47.2 59.8H62.9595C63.3183 60.8071 64.2716 61.5333 65.4 61.5333C66.8335 61.5333 68 60.3668 68 58.9333C68 57.4999 66.8335 56.3333 65.4 56.3333ZM44.6 42.4667C44.3701 42.4667 44.1497 42.3754 43.9872 42.2128C43.8246 42.0503 43.7333 41.8299 43.7333 41.6C43.7333 41.3701 43.8246 41.1497 43.9872 40.9872C44.1497 40.8246 44.3701 40.7333 44.6 40.7333C44.8299 40.7333 45.0503 40.8246 45.2128 40.9872C45.3754 41.1497 45.4667 41.3701 45.4667 41.6C45.4667 41.8299 45.3754 42.0503 45.2128 42.2128C45.0503 42.3754 44.8299 42.4667 44.6 42.4667ZM65.4 59.8C65.1701 59.8 64.9497 59.7087 64.7872 59.5462C64.6246 59.3836 64.5333 59.1632 64.5333 58.9333C64.5333 58.7035 64.6246 58.483 64.7872 58.3205C64.9497 58.158 65.1701 58.0667 65.4 58.0667C65.6299 58.0667 65.8503 58.158 66.0128 58.3205C66.1754 58.483 66.2667 58.7035 66.2667 58.9333C66.2667 59.1632 66.1754 59.3836 66.0128 59.5462C65.8503 59.7087 65.6299 59.8 65.4 59.8Z" fill="#7D8597" />
                </g>
            </g>
            <circle cx="55" cy="50" r="49.8613" stroke="#467CEB" stroke-width="0.277344" stroke-dasharray="1.66 1.66" />
            <defs>
                <filter id="filter0_d_9271_33300" x="0" y="7" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_33300" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_33300" result="shape" />
                </filter>
                <clipPath id="clip0_9271_33300">
                    <rect width="26" height="22.5333" fill="white" transform="translate(42 39)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Trips