import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import VehicleCard from './VehicleCard';


const VehicleList = ({ vehicleData, type, handleCardClick, cardType, selectedVehicle }) => {
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);

    const handleSingleCardClick = (id) => {
        setSelectedVehicleId(id);
        handleCardClick(id, cardType)
    };
    useEffect(() => {
        setSelectedVehicleId(selectedVehicle);
    }, [selectedVehicle])


    return (
        <Box>
            <Grid container spacing={2}>
                {vehicleData && vehicleData?.map(vehicle => (
                    <Grid item xs={12} sm={type == "dealer" ? 12 : 6} key={vehicle.id}>
                        <VehicleCard
                            vehicle={vehicle}
                            isSelected={vehicle.id == selectedVehicleId}
                            onClick={() => handleSingleCardClick(vehicle.id)}
                            type={type}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default VehicleList;