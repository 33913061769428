import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchAddStationForm = createAsyncThunk('addStation/fetchAddStationForm', async (_,{rejectWithValue}) => {
    try {
        const response = await api.get('/prometheus/station/add/');
        return response.data;
    } catch (error) {
        console.error('Error fetching station form', error);
        throw rejectWithValue(error);
    }
    });

const AddStationSlice = createSlice({
  name: 'addStation',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddStationForm.pending, (state) => {
        state.isLoading = true;
        state.error = false
      })
      .addCase(fetchAddStationForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddStationForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default AddStationSlice.reducer;
