import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import api from "../../apiSetup/apiCall";
import { getCurrentLocation } from "../../utilis/common";
import FacilityInfoCard from "./FacilityInfoCard";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const mapContainerStyle = { width: "100%", height: "calc(100vh - 120px)" };

const SubDealersMap = ({ subDealerData }) => {
    const [subDealerList, setSubDealerList] = useState([]);
    const [currentLocation, setCurrentLocation] = useState({ latitude: null, longitude: null });
    const [activeMarker, setActiveMarker] = useState(null);
    const [loading, setLoading] = useState(true)

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const transformLocation = (location) => {
        if (location && location !== undefined) {
            return { lat: parseFloat(location.split(',')[0]), lng: parseFloat(location.split(',')[1]) };
        }
        return null;
    };

    const loadFacilityList = async () => {
        try {
            if (subDealerData?.sub_dealers?.length > 0) {
                const transformedData = subDealerData?.sub_dealers && subDealerData?.sub_dealers?.map(subdealer => ({
                    ...subdealer,
                    location: transformLocation(subdealer.coordinates),
                }));
                setSubDealerList(transformedData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleOnLoad = useCallback((map) => {
        if (!window.google) {
            console.error('Google Maps JavaScript API not loaded.');
            return;
        }

        const bounds = new window.google.maps.LatLngBounds();
        subDealerList.forEach(({ location }) => {
            if (location) {
                bounds.extend(location);
            }
        });
        // map.fitBounds(bounds); // Show all the markers
    }, [subDealerList]);

    useEffect(() => {
        loadFacilityList();
    }, [subDealerData]);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const location = await getCurrentLocation();
                setCurrentLocation({ latitude: location.latitude, longitude: location.longitude });
                setLoading(false)
            } catch (error) {
                console.error('Error getting current location:', error);
            }
        };

        fetchLocation();
    }, []);

    if (!subDealerList.length || !currentLocation.latitude || !currentLocation.longitude) {
        return null;
    }

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    const { latitude, longitude } = currentLocation;


    const customMarkerSvg = `
    <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0001 9.56903C18.8419 9.56903 20.5374 10.1954 21.8851 11.2467C21.1492 12.1283 20.706 13.2629 20.706 14.5009C20.706 15.6308 21.0752 16.6746 21.6991 17.5185H12.3011C12.9251 16.6746 13.2942 15.6309 13.2942 14.5009C13.2942 13.2629 12.8511 12.1283 12.1151 11.2467C13.4629 10.1954 15.1584 9.56903 17.0001 9.56903ZM8.21249 19.5937C11.7832 19.5937 14.804 21.9479 15.8075 25.1887C15.9839 25.7581 15.888 26.3246 15.5343 26.8044C15.1806 27.2842 14.6678 27.5432 14.0718 27.5432H2.35314C1.75708 27.5432 1.24429 27.2842 0.890607 26.8044C0.536927 26.3246 0.441103 25.7581 0.617411 25.1887C1.62101 21.948 4.6419 19.5937 8.21249 19.5937ZM25.7878 10.4817C23.568 10.4817 21.7685 12.2812 21.7685 14.501C21.7685 16.7207 23.568 18.5202 25.7878 18.5202C28.0075 18.5202 29.807 16.7207 29.807 14.501C29.807 12.2812 28.0075 10.4817 25.7878 10.4817ZM8.21249 10.4817C5.99273 10.4817 4.19326 12.2812 4.19326 14.501C4.19326 16.7207 5.99273 18.5202 8.21249 18.5202C10.4323 18.5202 12.2317 16.7207 12.2317 14.501C12.2317 12.2812 10.4323 10.4817 8.21249 10.4817ZM25.7877 19.5937C29.3584 19.5937 32.3792 21.9479 33.3827 25.1887C33.5591 25.7581 33.4632 26.3246 33.1095 26.8044C32.7558 27.2842 32.2431 27.5432 31.647 27.5432H19.9284C19.3324 27.5432 18.8196 27.2842 18.4659 26.8044C18.1122 26.3246 18.0164 25.7581 18.1927 25.1887C19.1963 21.948 22.2172 19.5937 25.7877 19.5937ZM17.0002 0.457031C19.2199 0.457031 21.0194 2.25651 21.0194 4.47627C21.0194 6.69603 19.2199 8.49551 17.0002 8.49551C14.7804 8.49551 12.9809 6.69603 12.9809 4.47627C12.9809 2.25651 14.7804 0.457031 17.0002 0.457031Z" fill="#1849D6"/>
    </svg>
`;

    const customMarkerUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(customMarkerSvg);


    return (
        <div>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }} style={mapContainerStyle}>
                    <CircularProgress />
                </Box>
            ) : (
                <GoogleMap
                    onLoad={handleOnLoad}
                    onClick={() => setActiveMarker(null)}
                    center={{ lat: latitude, lng: longitude }}
                    zoom={5}
                    mapContainerStyle={mapContainerStyle}
                >
                    {subDealerList.map((subDealer) => {
                        if (subDealer.location) {
                            return (
                                <Marker
                                    key={subDealer.id}
                                    position={subDealer.location}
                                    onClick={() => handleActiveMarker(subDealer.id)}
                                    icon={customMarkerUrl}
                                >
                                    {activeMarker === subDealer.id ? (
                                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                            <FacilityInfoCard facility={subDealer} />
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            );
                        }
                        return null;
                    })}
                </GoogleMap>
            )}
        </div>
    );
}

export default SubDealersMap