import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

export const toastContainer = () => {
	return (
		<ToastContainer
			position="bottom-center"
			autoClose={800}
			hideProgressBar={false}
			newestOnTop={true}
			closeOnClick={true}
			rtl={false}
			pauseOnFocusLoss={false}
			draggable
			pauseOnHover
			theme="colored"
		/>
	);
};

export const greetingMessage = () => {
	const hour = (new Date()).getHours();
	if (hour > 0 && hour < 12)
		return 'Good Morning';
	else if (hour >= 12 && hour < 18)
		return 'Good Afternoon';
	else
		return 'Good Evening';
}
export const customizeName = (name) => {
	let newName = name.split(' ').at(0);
	newName = newName.charAt(0).toUpperCase() + newName.substring(1);
	return newName;
}

export const formatDate = (dateString) => {
	return dayjs(dateString).format('MMMM D, YYYY h:mm A');
};