import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const submitChargerData = createAsyncThunk('submitCharger/fetchChargerdata', async ({stationId, payload}, { rejectWithValue }) => {
  try {
      const response = await api.post(`prometheus/station/${stationId}/charger/add/`, payload);
      return response.data;
  } catch (error) {
      console.error('Error Submitting Data:', error);

      if ('isAxiosError' in error && error.response) {
        return rejectWithValue({
          data: error.response.data,
        });
      } else {
        return rejectWithValue(error);
      }
  }
});

const SubmitChargerSlice = createSlice({
  name: 'submitCharger',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    apiErrorData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitChargerData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.apiErrorData = null;
      })
      .addCase(submitChargerData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(submitChargerData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
        state.apiErrorData = action.payload.data;
      });
  },
});

export default SubmitChargerSlice.reducer;

