import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        back: 'Back',
        save: 'Save',
        next: 'Next',
        dashboard: 'Dashboard',
        logout: 'Logout',
        chargerOperationStatus: 'Charger Operation Status',
        stationManagementOverview: 'Station Management - Overview',
        chargerType: 'Charger Type',
        userManagementOverview: 'User Management - Overview',
        totalUsers: 'Total Users',
        usersThisMonth: 'Users This Month',
        usersLastMonth: 'Users Last Month',
        activeUsers: 'Active Users',
        registerFacilityStatus: 'Register Facility Status',
        totalNumberOfFacilities: 'Total Number of Facilities',
        numberOfRegistrationByFacilitiesType: 'Number of Registration by Facilities Type',
        chargerStatus: 'Charger Status',
        chargers: 'Chargers',
        chargingSessions: 'Charging Sessions',
        sessions: 'Sessions',
        date: 'Date',
        totalRevenue: 'Total Revenue',
        type: 'Type',
        units: 'Units',
        amount: 'Amount',
        stationProfit: 'Station Profit',
        terraProfit: 'Terra Profit',
        unitsConsumed: 'Units Consumed',
        revenue: 'Revenue',
        totalTransactions: 'Total Transactions',
        perDay: 'Per Day',
        perMonth: 'Per Month',
        electricityBill: 'Electricity Bill'
      },
    },
    th: {
      translation: {
        back: 'ย้อนกลับ',
        save: 'บันทึก',
        next: 'ถัดไป',
        dashboard: 'แดชบอร์ด',
        logout: 'ออกจากระบบ',
        chargerOperationStatus: 'สถานะการทำงานของเครื่องชาร์จ',
        stationManagementOverview: 'การจัดการสถานี - ภาพรวม',
        chargerType: 'ประเภทเครื่องชาร์จ',
        userManagementOverview: 'การจัดการผู้ใช้ - ภาพรวม',
        totalUsers: 'ผู้ใช้ทั้งหมด',
        usersThisMonth: 'ผู้ใช้เดือนนี้',
        usersLastMonth: 'ผู้ใช้เดือนที่แล้ว',
        activeUsers: 'ผู้ใช้ที่ใช้งาน',
        registerFacilityStatus: 'ลงทะเบียนสถานะสิ่งอำนวยความสะดวก',
        totalNumberOfFacilities: 'จำนวนทั้งหมดของสิ่งอำนวยความสะดวก',
        numberOfRegistrationByFacilitiesType: 'จำนวนการลงทะเบียนตามประเภทสิ่งอำนวยความสะดวก',
        chargerStatus: 'สถานะเครื่องชาร์จ',
        chargers: 'เครื่องชาร์จ',
        chargingSessions: 'เซสชันการชาร์จ',
        sessions: 'เซสชัน',
        date: 'วันที่',
        totalRevenue: 'รายได้ทั้งหมด',
        type: 'ประเภท',
        units: 'หน่วย',
        amount: 'จำนวนเงิน',
        stationProfit: 'สถานีกำไร',
        terraProfit: 'เทอร์ร่า กำไร',
        unitsConsumed: 'หน่วยที่ใช้',
        revenue: 'รายได้',
        totalTransactions: 'ยอดรวมการทำธุรกรรม',
        perDay: 'ต่อวัน',
        perMonth: 'ต่อเดือน',
        electricityBill:'ค่าไฟฟ้า'
      },
    },
    ja: {
      translation: {
        back: '戻る',
        save: '保存',
        next: '次へ',
        dashboard: 'ダッシュボード',
        logout: 'ログアウト',
        chargerOperationStatus: '充電器の動作状況',
        stationManagementOverview: 'ステーション管理 - 概要',
        chargerType: '充電器タイプ',
        userManagementOverview: 'ユーザー管理 - 概要',
        totalUsers: '総ユーザー数',
        usersThisMonth: '今月のユーザー',
        usersLastMonth: '先月のユーザー',
        activeUsers: 'アクティブユーザー',
        registerFacilityStatus: '登録施設の状態',
        totalNumberOfFacilities: '施設の総数',
        numberOfRegistrationByFacilitiesType: '施設タイプ別の登録数',
        chargerStatus: '充電器の状態',
        chargers: '充電器',
        chargingSessions: '充電セッション',
        sessions: 'セッション',
        date: '日付',
        totalRevenue: '総収益',
        type: 'タイプ',
        units: '単位',
        amount: '金額',
        stationProfit: 'ステーション利益',
        terraProfit: 'テラプロフィット',
        unitsConsumed: '使用単位',
        revenue: '収益',
        totalTransactions: '総取引回数',
        perDay: '日ごと',
        perMonth: '月ごと',
        electricityBill:'電気料金請求書'
      },
    },
    id: {
      translation: {
        back: 'Kembali',
        save: 'Simpan',
        next: 'Selanjutnya',
        dashboard: 'Dasbor',
        logout: 'Keluar',
        chargerOperationStatus: 'Status Operasi Pengisi Daya',
        stationManagementOverview: 'Manajemen Stasiun - Gambaran',
        chargerType: 'Tipe Charger',
        userManagementOverview: 'Manajemen Pengguna - Gambaran',
        totalUsers: 'Total Pengguna',
        usersThisMonth: 'Pengguna Bulan Ini',
        usersLastMonth: 'Pengguna Bulan Lalu',
        activeUsers: 'Pengguna Aktif',
        registerFacilityStatus: 'Status Registrasi Fasilitas',
        totalNumberOfFacilities: 'Total Jumlah Fasilitas',
        numberOfRegistrationByFacilitiesType: 'Jumlah Registrasi berdasarkan Jenis Fasilitas',
        chargerStatus: 'Status Charger',
        chargers: 'Charger',
        chargingSessions: 'Sesi Pengisian',
        sessions: 'Sesi',
        date: 'Tanggal',
        totalRevenue: 'Total Pendapatan',
        type: 'Tipe',
        units: 'Unit',
        amount: 'Jumlah',
        stationProfit: 'Keuntungan Stasiun',
        terraProfit: 'Keuntungan Terra',
        unitsConsumed: 'Unit yang Digunakan',
        revenue: 'Pendapatan',
        totalTransactions: 'Total Transaksi',
        perDay: 'Per Hari',
        perMonth: 'Per Bulan',
        electricityBill: 'Tagihan listrik'
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
