import React, { useState } from "react";
import dayjs from 'dayjs';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { fetchBillingTableData, setSelectedMonthYear } from "../../slice/BillingTableSlice";
import { useDispatch, useSelector } from "react-redux";

const CustomMonthYearPicker = () => {
    // const [selectedMonthYear, setSelectedMonthYear] = useState(0);
    const {selectedMonthYear}=useSelector(store => store.billingTable)
    const {selectedFacility} = useSelector(state => state.stationList);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        dispatch(setSelectedMonthYear(selectedValue));
        const [monthName, year] = selectedValue.split(' ');
        const monthNumber = dayjs(`${monthName} 1, ${year}`, 'MMMM D, YYYY').format('M');
        dispatch(fetchBillingTableData({ facilityId: selectedFacility?.name === "All" ? 0 : selectedFacility?.id, selectedMonth: monthNumber, selectedYear: year }));
    };
    
    const generateOptions = () => {
        const options = [];
        const currentDate = dayjs();
        const minDate = dayjs('August 2023', 'MMMM YYYY');
        const maxDate = currentDate.subtract(1, 'month');

        for (let date = maxDate; date.isAfter(minDate) || date.isSame(minDate, 'month'); date = date.subtract(1, 'month')) {
            const formattedDate = date.format('MMMM YYYY');
            options.push(
                <MenuItem key={formattedDate} value={formattedDate} style={{ fontSize: "14px" }}>
                    {formattedDate}
                </MenuItem>
            );
        }

        return options;
    };


    
    return (
        <FormControl sx={{ minWidth: '250px' }}>
            <InputLabel id="month-select-label">Select month and year </InputLabel>
            <Select
                labelId="month-select-label"
                label="Select month and year"
                value={selectedMonthYear}
                onChange={handleChange}
                sx={{ height: '49px', backgroundColor: 'white' }}
                style={{ fontSize: '15px' }}
            >
                {generateOptions()}
            </Select>
        </FormControl>
    );
};

export default CustomMonthYearPicker;