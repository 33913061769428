import React from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Box
} from "@mui/material";

const DynamicBox = ({ title, count, IconComponent }) => {
    return (
        <Box>
            <Box
                display={"flex"}
                alignItems={"center"}
                bgcolor={"white"}
                justifyContent={"space-between"}
                borderRadius={"16px"}
                padding='24px'
                height='130px'
                key="front"
            >
                <Typography>
                    <Typography variant="subtitle2" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>
                        {title}
                    </Typography>
                    <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>
                        {count}
                    </Typography>
                </Typography>
                <IconComponent />
            </Box>
        </Box>
    );
}

DynamicBox.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    IconComponent: PropTypes.elementType.isRequired,
};

export default DynamicBox;