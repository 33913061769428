import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";

export const submitStationData = createAsyncThunk(
  "submitStation/fetchSubmitStation",
  async (payload) => {
    try {
      const response = await api.post("prometheus/station/add/", payload);
      return response.data;
    } catch (error) {
      console.error("Error Submitting Data:", error);
      return error.response.data;
    }
  }
);

const SubmitStationSlice = createSlice({
  name: "submitStation",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitStationData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitStationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(submitStationData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message; // Access the error message
      });
  },
});

export default SubmitStationSlice.reducer;
