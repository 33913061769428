import { Box } from "@mui/system";
import { API_CHARGER_GRAPH_SUBSCRIPTION } from "../graphql/query";
import { Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";




const generateResult = (data) => {
    const result = data.map(item => ({
        units_consumed: item.units_consumed/1000,
        charger_type: item.charger_name + item.connector_id
    }));
    let reducedArray = [];
    result.forEach(item => {
        const index = reducedArray.findIndex(value => value.charger_type === item.charger_type);
        if (index < 0)
            reducedArray.push(item);
        else {
            reducedArray[index].units_consumed += item.units_consumed;
        }
    })
     reducedArray.sort((a, b) => {
        const nameA = a.charger_type.toUpperCase();
        const nameB = b.charger_type.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return reducedArray;
}


const ChargerUsageChart = ({ facilityFilter, dateFilter }) => {

    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id
        }
    }

    const valueFormatter = (value) => `${value?.toFixed(2)} kWh`;

    const { data } = useSubscription(API_CHARGER_GRAPH_SUBSCRIPTION, variables);
    const [results, setResults] = useState([{  units_consumed: 0, charger_type: 0 }]);

    useEffect(() => {
        if (data?.view_facility_revenue?.length > 0) {
            setResults(generateResult(data?.view_facility_revenue));
        }
        else
        setResults([{ units_consumed: 0, charger_type: 0}])
    }, [data, facilityFilter, dateFilter])



    return (
        <Box bgcolor={"white"} p={3} borderRadius={"16px"} padding={"24px 24px 8px"}>
            <Typography variant="subtitle" fontSize='20px' fontWeight='600'>Charger Wise Usage</Typography>
            {
                results?.length > 0 &&
                <Grid container flexDirection='column'>
                    <Grid item display='flex'>
                        <Grid item display='flex' alignItems='center' sx={{ transform: 'rotate(-90deg)', textAlign: 'center' }} fontSize='12px' fontWeight='600' textTransform='uppercase'>
                            Units Consumed
                        </Grid>
                        <BarChart
                            dataset={results}
                            xAxis={[{ dataKey: "charger_type", scaleType: "band" }]}
                            yAxis={[{ dataKey: "units_consumed" }]}
                            series={[{ dataKey: "units_consumed", valueFormatter, color: '#467CEB' }]}
                            layout="vertical"
                            height='230'
                        />
                    </Grid>
                    <Grid item display='flex' justifyContent='center' fontSize='12px' fontWeight='600' textTransform='uppercase'>
                        Charger Type
                    </Grid>
                </Grid>
            }
        </Box>
    );
}
export default ChargerUsageChart;