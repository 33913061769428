import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Grid, Typography } from "@mui/material";

const CustomRadioButton = ({
  label,
  required,
  value: propValue,
  options,
  onValueChange,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(propValue!== null ? propValue:"");
  }, [propValue]);


  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onValueChange(newValue);
  };

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center">
        <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
          {label}
          {required && (
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          )}
        </Typography>
        <FormControl component="fieldset" style={{ marginLeft: "10px" }}>
          <RadioGroup
            style={{ flexDirection: "row" }}
            onChange={handleChange}
            value={selectedValue}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CustomRadioButton;
