import React, { useState, useEffect, useRef } from "react";
import { fetchChargerTransitions } from "../slice/ChargerTransitionsSlice";
import {
  styled,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Paper,
  Box,
  Slider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import EvStation from "@mui/icons-material/EvStation";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import AvailableIcon from "../assets/dashBoard/availableIcon.svg";
import DisconnectedIcon from "../assets/dashBoard/disconnectedIcon.svg";
import HeartbeatIcon from "../assets/dashBoard/heartbeatIcon.svg";
import ConnectedIcon from "../assets/dashBoard/connectedIcon.svg";
import BootNotificationIcon from "../assets/dashBoard/bootNotificationIcon.svg";
import CustomDialog from "./customComponents/CustomDialog";

const Frame = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  position: "relative",
});

const Preparing = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
});

const ElementWrapper = styled(Box)({
  backgroundColor: "#30c48f",
  borderRadius: "12px",
  display: "flex",
  justifyContent: "center",
  padding: "6px 20px",
});

const Element = styled(Typography)({
  flex: 1,
  fontFamily: "Inter, Helvetica",
  fontWeight: 600,
  textAlign: "center",
});

const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  width: "100%",
});

const Line = styled("img")({
  objectFit: "cover",
  position: "relative",
});

const FlexContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Text = styled(Typography)({
  fontFamily: "Inter, Helvetica",
  fontWeight: 400,
  textAlign: "center",
});

const TextWrapper = styled("p")({
  fontWeight: 600,
});

const Span = styled(Typography)({
  fontFamily: "Inter, Helvetica",
  fontWeight: 400,
});

const Point = styled("div")({
  position: "absolute",
});

const OverlapGroup = styled("div")({
  backgroundSize: "100% 100%",
  position: "relative",
});

const SliderContainer = styled(Box)({
  border: "1px solid #C0C4CD",
  borderRadius: 15,
  display: "flex",
  paddingLeft: "17px",
  position: "relative",
});

const TimeMarkers = styled("div")({
  position: "absolute",
  top: -32,
  left: 0,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: 30,
  padding: "0 16px",
});

const Marker = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const MarkerLine = styled("div")({
  width: 1,
  height: 8,
  backgroundColor: "#333",
});

const MarkerText = styled(Typography)({
  fontFamily: "Inter, Helvetica",
  fontWeight: 400,
  fontSize: 12,
  marginBottom: 4,
});

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 68,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#5C677D",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const QontoConnector2 = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 90,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#5C677D",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const getColorForType = (type) => {
  switch (type) {
    case "Heartbeat":
      return "#FF6060";
    case "StatusNotification":
      return "#30C48F";
    case "BootNotification":
      return "#30C48F";
    case "Connected":
      return "#33415C";
    case "Disconnected":
      return "#EEAC05";
    default:
      return "black";
  }
};

const getIconForType = (type) => {
  switch (type) {
    case "Heartbeat":
      return <img src={HeartbeatIcon} alt="Heartbeat Icon" />;
    case "StatusNotification":
      return <img src={AvailableIcon} alt="Available Icon" />;
    case "BootNotification":
      return <img src={BootNotificationIcon} alt="BootNotification Icon" />;
    case "Connected":
      return <img src={ConnectedIcon} alt="Connected Icon" />;
    case "Disconnected":
      return <img src={DisconnectedIcon} alt="Disconnected Icon" />;
    default:
      return <img src={EvStation} alt="Ev Station Icon" />;
  }
};

const useStyles = {
  frame: Frame,
  preparing: Preparing,
  elementWrapper: ElementWrapper,
  element: Element,
  content: Content,
  line: Line,
  flexContainer: FlexContainer,
  text: Text,
  textWrapper: TextWrapper,
  span: Span,
  point: Point,
  overlapGroup: OverlapGroup,
};

const ChargerTransitions = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { chargerId, data } = useSelector((state) => state.chargerTransition);
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const dispatch = useDispatch();
  const [scrollValue, setScrollValue] = useState(0);
  const [sliderMax, setSliderMax] = useState(0);
  const sliderContainerRef = useRef(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);

  const getFormatedDate = (timeString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const dateObject = new Date(timeString);
    const formatted = dateObject.toLocaleString("en-US", options);
    return formatted;
  };

  useEffect(() => {
    const { $y, $M, $D } = currentDate;
    const date = $y + "-" + ($M + 1) + "-" + $D;
    dispatch(fetchChargerTransitions({ chargerId: chargerId, date: date }));
    setScrollValue(0);
  }, [currentDate]);

  useEffect(() => {
    const updateSliderMax = () => {
      if (sliderContainerRef.current) {
        const containerWidth = sliderContainerRef.current.clientWidth;
        const contentWidth = data?.data?.length * 25;
        const maxScroll = Math.max(0, contentWidth - containerWidth);
        setSliderMax(maxScroll);
      }
    };

    updateSliderMax();
    window.addEventListener("resize", updateSliderMax);
    return () => {
      window.removeEventListener("resize", updateSliderMax);
    };
  }, [data?.data, sliderContainerRef]);

  const handleScrollChange = (_event, newValue) => {
    setScrollValue(newValue);
  };

  const handleTypeClick = (type, log) => {
    setSelectedType(type);
    setSelectedLog(log);
    setIsSecondDialogOpen(true);
  };

  const renderLogData = (log) => {
    const keys = Object.keys(log);
    const values = Object.values(log);

    return (
      <div
        style={{ display: "flex", flexDirection: "column", overflowX: "auto" }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: 8,
            justifyContent: "space-between",
          }}
        >
          {keys.map((key, index) => (
            <div
              key={index}
              style={{
                width: "300px",
                fontWeight: "bold",
                color: "#FFF",
                textTransform: "capitalize",
              }}
            >
              {key}
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {values.map((value, index) => (
            <div
              key={index}
              style={{
                width: "300px",
                overflowX: "auto",
                backgroundColor: "#fff",
                padding: "8px 16px",
                borderRadius: "6px",
                border: "1px solid var(--Light-Mode-Gray-30, #EAEBEE)",
              }}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Box
        mb={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Date"
              value={currentDate}
              onChange={(newValue) => setCurrentDate(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Typography variant="body1">
          <strong>ChargerId: </strong> {chargerId}
        </Typography>
      </Box>
      <Stepper
        className={useStyles.frame}
        activeStep={activeStep}
        alternativeLabel
        connector={
          <>
            <QontoConnector />
            <QontoConnector2 />
          </>
        }
        style={{
          overflowX: "hidden",
          marginLeft: `-${scrollValue}%`,
          marginBottom: "60px",
        }}
      >
        {data?.data?.map((item, index) => (
          <Step key={index}>
            <ElementWrapper
              style={{
                backgroundColor: getColorForType(item?.type),
                width: "150px",
              }}
              onClick={() => handleTypeClick(item?.type, item?.log)}
            >
              <Typography
                className={useStyles.element}
                style={{ color: "#FFF", cursor: "pointer" }}
              >
                {item?.type === "StatusNotification"
                  ? item?.log?.status
                  : item?.type}
              </Typography>
            </ElementWrapper>
            <StepLabel icon={getIconForType(item?.type)}>
              <Paper
                elevation={3}
                className={useStyles.preparing}
                style={{ boxShadow: "none" }}
              >
                <div
                  className={useStyles.content}
                  style={{ marginTop: "-45px" }}
                >
                  <useStyles.line
                    alt="Line"
                    src="https://c.animaapp.com/E4RZ9OyI/img/line-2-9.svg"
                  />
                  <div
                    className={useStyles.flexContainer}
                    style={{ opacity: "0.6" }}
                  >
                    <p className={useStyles.textWrapper}>
                      <useStyles.text>
                        {getFormatedDate(item?.created_at)}
                      </useStyles.text>
                    </p>
                  </div>
                </div>
              </Paper>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <SliderContainer
        mb={2}
        style={{ width: "100%" }}
        ref={sliderContainerRef}
      >
        <TimeMarkers>
          {Array.from({ length: 12 }).map((_, index) => {
            const time = dayjs()
              .subtract(index * 120, "minute")
              .format("hh:mm A");
            return (
              <Marker key={index}>
                <MarkerText>{time}</MarkerText>
                <MarkerLine />
              </Marker>
            );
          })}
        </TimeMarkers>
        <Slider
          value={scrollValue}
          onChange={handleScrollChange}
          aria-labelledby="scrollbar"
          orientation="horizontal"
          max={sliderMax}
          sx={{
            "& .MuiSlider-rail": {
              display: "none",
            },
          }}
        />
      </SliderContainer>
      <CustomDialog
        isOpen={isSecondDialogOpen}
        onClose={() => {
          setIsSecondDialogOpen(false);
          setSelectedType(null);
        }}
        top="25px"
        right="25px"
        color="#FFF"
        topPosition="28%"
        isTransition={true}
        component={
          <div
            style={{
              backgroundColor: "#30c48f",
              padding: 16,
              borderRadius: 12,
            }}
          >
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                marginBottom: 16,
                color: "#FFF",
                borderBottom: "1px solid var(--Success-200, #7FE0BD)",
              }}
            >
              {selectedType}
            </Typography>
            {selectedLog && renderLogData(selectedLog)}
          </div>
        }
      />
    </>
  );
};

export default ChargerTransitions;
