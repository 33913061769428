import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';
import config from '../config';


export const fetchAddDealerForm = createAsyncThunk('addDealer/fetchAddDealerForm', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(config.TELEMATICS_URL + 'dealer/add/');
    return response.data;
  } catch (error) {
    console.error('Error fetching station form', error);
    throw rejectWithValue(error);
  }
});

const AddDealerSlice = createSlice({
  name: 'addDealer',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddDealerForm.pending, (state) => {
        state.isLoading = true;
        state.error = false
      })
      .addCase(fetchAddDealerForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddDealerForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default AddDealerSlice.reducer;
