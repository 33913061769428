


export const Type1on = () => {

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
                </defs>
                <circle class="a" cx="25" cy="25" r="25" />
                <g transform="translate(13 10)">
                    <path class="b" d="M16.685,35.652c-.109-1.043-.217-1.842-.323-2.374a1.87,1.87,0,0,0-1.789-1.519.1.1,0,0,0-.1.1v.693H9.908v-.693a.1.1,0,0,0-.1-.1,1.875,1.875,0,0,0-1.792,1.519c-.1.5-.2,1.246-.306,2.212A12,12,0,0,0,7.943,58v1.169a1.833,1.833,0,0,0,1.833,1.83H14.6a1.832,1.832,0,0,0,1.83-1.83V57.849a12,12,0,0,0,.253-22.2ZM9.853,56.562A9.76,9.76,0,0,1,8.087,56a10.094,10.094,0,0,1-.426-18.416.022.022,0,0,0,.012-.006,10,10,0,0,1,2.156-.737,10.243,10.243,0,0,1,4.718.091,10.123,10.123,0,0,1,2.156.837l.015.009a10.091,10.091,0,0,1-.429,18.051h0a9.583,9.583,0,0,1-1.765.635.1.1,0,0,0-.076.1v1.307a.851.851,0,0,1-.112.423.867.867,0,0,1-.752.441H10.793a.866.866,0,0,1-.705-.367.858.858,0,0,1-.156-.5V56.659A.1.1,0,0,0,9.853,56.562Zm4.618-21.6A11.952,11.952,0,0,0,12,34.7a12.081,12.081,0,0,0-2.089.182v-1.21h4.562v1.284Z" transform="translate(0 -31.76)" />
                    <path class="b" d="M29.494,63.177a8.087,8.087,0,1,0-8.087,8.087A8.087,8.087,0,0,0,29.494,63.177Zm-1.319,2.5a1.66,1.66,0,1,1-1.66-1.66A1.66,1.66,0,0,1,28.175,65.68Zm-3.058-8.4a2.542,2.542,0,1,1-2.544,2.541A2.544,2.544,0,0,1,25.117,57.284Zm-7.2,0a2.542,2.542,0,1,1-2.541,2.541A2.544,2.544,0,0,1,17.917,57.284ZM16.446,67.337a1.66,1.66,0,1,1,1.66-1.66A1.66,1.66,0,0,1,16.446,67.337Zm2.609.705A2.542,2.542,0,1,1,21.6,70.583,2.545,2.545,0,0,1,19.054,68.042Z" transform="translate(-9.407 -48.237)" />
                    <path class="b" d="M51.128,69.843a.977.977,0,1,0-.978-.978A.98.98,0,0,0,51.128,69.843Z" transform="translate(-35.418 -57.277)" />
                    <path class="b" d="M26.625,69.843a.977.977,0,1,0-.975-.978A.978.978,0,0,0,26.625,69.843Z" transform="translate(-18.115 -57.277)" />
                    <path class="b" d="M40.123,96.828a.977.977,0,1,0-.975.975A.977.977,0,0,0,40.123,96.828Z" transform="translate(-26.957 -77.023)" />
                    <circle class="b" cx="0.637" cy="0.637" r="0.637" transform="translate(6.401 16.806)" />
                    <path class="b" d="M56.07,89.607a.637.637,0,1,0,.637-.637A.639.639,0,0,0,56.07,89.607Z" transform="translate(-39.599 -72.164)" />
                </g>
            </svg>
        </>
    );
}