import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";

export const fetchChargerTransitions = createAsyncThunk('chargerTransition/fetchChargerTransitions', async (data) => {
    try {
        const response = await api.get(`prometheus/charger/${data.chargerId}/timeline/${data.date}/`)
        return response.data;
    } catch (error) {
        console.error('Error fetching edt station form', error);
        throw error;
    }
    });

const ChargerTransitionSlice = createSlice({
    name: 'chargerTransition',
    initialState: {
        data:[],
        chargerId: null,
        isLoading: false,
        error: null
    },
    reducers:{
        setChargerId: (state,action)=>{
            state.chargerId=action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchChargerTransitions.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchChargerTransitions.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
          })
          .addCase(fetchChargerTransitions.rejected, (state, action) => {
            state.isLoading = false;
          });
      },
})

export const {setChargerId} = ChargerTransitionSlice.actions;
export default ChargerTransitionSlice.reducer;