import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const userLinkedStationData = createAsyncThunk('userLinkedStation/fetchUserLinkedStationData', async (phoneOrEmailText) => {
  try {
    const response = await api.get(`prometheus/user/search/${phoneOrEmailText}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching logs data:', error);
    throw error;
  }
});

const UserLinkedStationSlice = createSlice({
  name: 'userLinkedStation',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLinkedStationData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLinkedStationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(userLinkedStationData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default UserLinkedStationSlice.reducer;
