import React from "react";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

const DownloadButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    style={{
      border: "1px solid #467CEB",
      borderRadius: "28px",
      color: "#467CEB",
      textTransform: "capitalize",
      padding: "8px 16px",
    }}
  >
    <Download style={{ marginRight: "8px" }} /> Download
  </Button>
);

export default DownloadButton;
