import { Padding } from "@mui/icons-material";
import React from "react";
import Box from '@mui/material/Box';

const Logo = () => {
  return (
    <Box sx={{ padding: '10px 12px' }}>
      <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.7821 23.025C40.7821 16.0921 33.2684 10.4717 24 10.4717V11.6463C25.6864 11.6463 27.311 11.8568 28.8355 12.2454L26.6525 15.5629L28.3171 16.4148C28.3171 16.4148 28.9873 14.1103 29.4986 13.2413C29.6929 12.911 29.967 12.7429 30.2437 12.6643C35.9052 14.5977 39.8717 19.0802 39.8717 24.3018C39.8717 25.9991 39.4514 27.6183 38.6911 29.097C40.0229 27.2975 40.7821 25.2282 40.7821 23.0255V23.025Z" fill="#467CEB" />
        <path d="M24.0009 10.4722C14.7325 10.4722 7.21875 16.0926 7.21875 23.0255C7.21875 25.2278 7.97797 27.2976 9.30975 29.097C8.54953 27.6178 8.1292 25.9991 8.1292 24.3018C8.1292 19.0802 12.0957 14.5977 17.7572 12.6644C18.0334 12.7429 18.308 12.9111 18.5023 13.2413C19.0136 14.1109 19.6838 16.4148 19.6838 16.4148L21.3484 15.5629L19.1654 12.2454C20.6899 11.8568 22.3145 11.6463 24.0009 11.6463V10.4717V10.4722Z" fill="#467CEB" />
        <path d="M24 0C10.7453 0 0 8.05875 0 18C0 27.9413 10.7453 36 24 36C37.2547 36 48 27.9413 48 18C48 8.05875 37.2547 0 24 0ZM24 35.5776C12.1166 35.5776 2.483 28.2675 2.483 19.2507C2.483 10.2338 12.1166 2.92374 24 2.92374C35.8834 2.92374 45.517 10.2338 45.517 19.2507C45.517 28.2675 35.8834 35.5776 24 35.5776Z" fill="#467CEB" />
        <path d="M2.48351 19.2502C2.48351 13.9701 5.78642 9.27566 10.9097 6.29102C5.19716 7.41529 1.03741 13.1157 0.0116356 17.4457C0.00404855 17.6295 0 17.8142 0 17.9995C0 20.2083 0.531097 22.3244 1.50174 24.2794C2.40663 25.4249 3.61955 26.3528 5.02974 26.961C3.40509 24.6646 2.48351 22.0389 2.48351 19.2502Z" fill="#467CEB" />
        <path d="M44.2145 8.29301C43.7016 7.76234 42.3637 6.68086 39.3774 5.77257C37.3143 5.14523 35.1363 4.78828 33.4727 4.58789C40.6071 7.24628 45.5179 12.8138 45.5179 19.2518C45.5179 19.633 45.5002 20.0106 45.4663 20.3847C45.8796 20.072 46.3201 19.6929 46.7253 19.2513C47.1841 18.7519 47.6008 17.8013 47.9468 16.7863C47.6701 13.6717 46.3373 10.7737 44.2145 8.29252V8.29301Z" fill="#467CEB" />
        <path d="M24 10.0478C33.4242 10.0478 41.0639 15.7628 41.0639 22.8121C41.0639 29.8613 33.4242 35.5763 24 35.5763C34.8015 35.5763 43.558 29.16 43.558 21.2447C43.558 13.3295 34.8015 6.91309 24 6.91309" fill="#467CEB" />
        <path d="M23.9994 10.0478C14.5752 10.0478 6.93554 15.7628 6.93554 22.8121C6.93554 29.8613 14.5752 35.5763 23.9994 35.5763C13.1979 35.5763 4.44141 29.16 4.44141 21.2447C4.44141 13.3295 13.1979 6.91309 23.9994 6.91309V10.0478Z" fill="#467CEB" />
        <path d="M24 10.0479V10.4698C24.0066 10.4698 24.0132 10.4698 24.0197 10.4698V35.2204C24.0197 35.2204 24.8877 35.5628 25.2933 33.2302C25.5938 31.5017 28.1269 18.0401 28.6286 15.9517C29.1304 13.8632 29.2614 11.666 30.1278 11.5296C30.3534 11.4943 30.6878 11.5417 31.059 11.6318C36.8004 13.6261 40.7821 17.9762 40.7821 23.0231C40.7821 29.956 33.2684 35.5764 24 35.5764C33.4242 35.5764 41.0639 29.8614 41.0639 22.8121C41.0639 15.7629 33.4242 10.0479 24 10.0479Z" fill="#467CEB" />
        <path d="M16.8397 11.6681C17.2671 11.5558 17.6591 11.4903 17.913 11.5301C18.7794 11.6665 18.9104 13.8637 19.4122 15.9522C19.914 18.0406 22.447 31.5023 22.7475 33.2307C23.1526 35.5633 24.0211 35.221 24.0211 35.221V10.2261L24.0014 10.0479C14.5772 10.0479 6.9375 15.7629 6.9375 22.8121C6.9375 29.8614 14.5772 35.5764 24.0014 35.5764C14.733 35.5764 7.21923 29.956 7.21923 23.0231C7.21923 18.0064 11.1539 13.6775 16.8402 11.6676L16.8397 11.6681ZM20.61 10.7265C20.6271 10.724 20.6443 10.7215 20.662 10.719L20.61 10.7265Z" fill="#467CEB" />
        <path d="M20.6094 10.7273C20.6266 10.7248 20.6438 10.7223 20.6615 10.7197L20.6094 10.7273Z" fill="#467CEB" />
        <path d="M16.8382 11.6692C17.2656 11.5569 17.6576 11.4914 17.9115 11.5312L18.5109 10.7236C11.7791 12.433 6.93555 17.1999 6.93555 22.8133C6.93555 29.863 14.5752 35.5775 23.9994 35.5775C14.731 35.5775 7.21728 29.9572 7.21728 23.0242C7.21728 18.0075 11.1519 13.6786 16.8382 11.6687V11.6692Z" fill="#467CEB" />
        <path d="M24.0499 35.2289C24.0277 35.2244 24.0201 35.2204 24.0201 35.2204C24.0201 35.2204 24.015 35.2234 23.9902 35.2289V6.91309H24.0499V35.2289Z" fill="#467CEB" />
      </svg>
    </Box>
  );
};

export default Logo;
