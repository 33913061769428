import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchCouponList = createAsyncThunk('couponList/fetchCouponList', async () => {
  try {
    const response = await api.get('prometheus/coupon/list/');
    return response.data;
  } catch (error) {
    console.error('Error fetching coupon list:', error);
    throw error;
  }
});

const CouponListSlice = createSlice({
  name: 'couponList',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCouponList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCouponList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchCouponList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default CouponListSlice.reducer;
