import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';
export const fetchBillingTableData = createAsyncThunk(
  'station/fetchTableData',
  async ({ facilityId, selectedMonth, selectedYear }) => {
    try {
      const response = await api.get(`prometheus/bills/${facilityId}/${selectedMonth}/${selectedYear}/`);
      if (response.data)
        return response.data;
      else
        throw new Error('error')
    } catch (error) {
      console.error('Error fetching logs data:', error);
      throw error;
    }
  }
);
const BillingTableSlice = createSlice({
  name: 'billingTable',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    selectedMonthYear: ''
  },
  reducers: {
    setSelectedMonthYear : (state,action)=>{
      state.selectedMonthYear=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingTableData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBillingTableData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchBillingTableData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export const {setSelectedMonthYear}=BillingTableSlice.actions;
export default BillingTableSlice.reducer;