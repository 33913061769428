import React, { useEffect, useState } from 'react';
import { Grid, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VehicleSearch from './VehicleSearch';
import NewDatePicker from '../NewDatePicker';


function ChangeDashboardView() {
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const url = window.location.href

    useEffect(() => {
        if (url.includes('/telematics')) {
            setValue('telematics');
        } else if (url.includes('/charger')) {
            setValue('charger');
        } 
    }, [url]);


    const handleChange = (event) => {
        setValue(event.target.value);
        navigate(`/${event.target.value}/dashboard`);
    };

    const labelStyle = { typography: { sx: { fontSize: "14px", fontWeight: "500" } } };

    return (
        <Grid container rowSpacing={3} columnSpacing={3} alignItems="center" sx={{ padding: '0px 0px 20px 0px' }}>
            <Grid item xs={12} >
                <Typography variant="caption">Please select the dashboard view of</Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "0px" }} sx={{ paddingTop: "0px", display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            size="small"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="charger"
                                control={<Radio />}
                                label="Charger"
                                componentsProps={labelStyle}
                            />
                            <FormControlLabel
                                value="telematics"
                                control={<Radio />}
                                label="Telematics"
                                componentsProps={labelStyle}
                            />
                            <FormControlLabel
                                value="both"
                                control={<Radio />}
                                label="Both (Telematics and Charger)"
                                componentsProps={labelStyle}
                                disabled
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                {/* <Box >
                    <NewDatePicker />
                </Box> */}
               {
                value === 'telematics' && <Box>
                    <VehicleSearch />
                </Box>
                }
            </Grid>
        </Grid>
    );
}

export default ChangeDashboardView;