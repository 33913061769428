import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AlertAndNotification = ({ dealerData }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }} bgcolor={"white"}
            borderRadius={"16px"}
            padding='24px'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {dealerData?.logs?.tabs.map((item, index) => (
                        <Tab label={item.name} {...a11yProps(index)} key={index} />
                    ))}
                </Tabs>
            </Box>
            {dealerData?.logs?.tabs.map((tablecontent, id) => (
                <CustomTabPanel value={value} index={id} key={id}>
                    {tablecontent?.table_content.map((item, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            style={{ borderBottom: '1px solid #E0E0E0', paddingTop: "5px", paddingBottom: "5px" }}
                        >
                            <Box display="flex" alignItems="center" paddingLeft={1} flexGrow={1}>
                                <Typography variant="body1" style={{ fontSize: '13px', fontWeight: '500' }}>
                                    {tablecontent.name == "Live" ? "Live IOT Logs" : "Resolved IOT Logs"}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" paddingLeft={1}>
                                <Typography variant="body1" style={{ fontSize: '13px', fontWeight: '400' }}>
                                    {`Model: ${item.model} || Chasis: ${item?.chasis_number ? item?.chasis_number : "N/A"} || Registration NUmber: ${item.license_plate_number} || Alert Type: ${item.alert_type} || SOC: ${item.soc} `}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </CustomTabPanel>
            ))}
        </Box>
    )
}

export default AlertAndNotification