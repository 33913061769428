

export const Ccs1On = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
                </defs>
                <circle class="a" cx="25" cy="25" r="25" />
                <g transform="translate(15 10)"><path class="b" d="M133.5,11.925A9.561,9.561,0,0,0,127.632,3.1c-.071-.685-.161-1.44-.262-1.948A1.415,1.415,0,0,0,126.01,0V.64h-3.83V0a1.412,1.412,0,0,0-1.358,1.155c-.1.477-.184,1.171-.25,1.82a9.565,9.565,0,0,0,.191,17.972v.977h-.21a4.538,4.538,0,0,0,0,9.076H127.6a4.538,4.538,0,1,0,0-9.076h-.17V20.834A9.564,9.564,0,0,0,133.5,11.925ZM122.181,1.372h3.83V2.59a9.469,9.469,0,0,0-2.066-.224,9.666,9.666,0,0,0-1.764.163Zm5.6,22.066a3.021,3.021,0,0,1,0,6.042h-7.411a3.021,3.021,0,0,1,0-6.042Zm-1.787-3.582v1.825H122.2V19.927a8.192,8.192,0,1,1,5.227-.59A7.856,7.856,0,0,1,125.991,19.856Z" transform="translate(-114.38)" />
                    <path class="b" d="M129.19,104.064a2.474,2.474,0,1,0,2.474-2.474A2.474,2.474,0,0,0,129.19,104.064Zm4.03,0a1.556,1.556,0,1,1-1.556-1.556A1.556,1.556,0,0,1,133.22,104.064Z" transform="translate(-125.693 -77.605)" />
                    <path class="b" d="M136.19,109.412a.822.822,0,1,0,.822-.822A.822.822,0,0,0,136.19,109.412Z" transform="translate(-131.041 -82.952)" />
                    <path class="b" d="M162.824,106.539a2.474,2.474,0,1,0-2.474-2.474A2.474,2.474,0,0,0,162.824,106.539Zm0-4.03a1.556,1.556,0,1,1-1.556,1.556A1.556,1.556,0,0,1,162.824,102.508Z" transform="translate(-149.496 -77.605)" />
                    <path class="b" d="M168.172,110.243a.822.822,0,1,0-.822-.822A.822.822,0,0,0,168.172,110.243Z" transform="translate(-154.844 -82.959)" />
                    <path class="b" d="M140.34,29.48a6.5,6.5,0,1,0-6.5,6.5A6.5,6.5,0,0,0,140.34,29.48Zm-3.518-4.736a2.043,2.043,0,1,1-2.045,2.045A2.045,2.045,0,0,1,136.822,24.744Zm-5.784,0a2.043,2.043,0,1,1-2.045,2.045A2.045,2.045,0,0,1,131.037,24.744Zm-1.183,8.079a1.334,1.334,0,1,1,1.334-1.334A1.334,1.334,0,0,1,129.854,32.823ZM134,35.432a2.043,2.043,0,1,1,2.042-2.045A2.045,2.045,0,0,1,134,35.432Zm2.618-3.941a1.334,1.334,0,1,1,1.334,1.334A1.334,1.334,0,0,1,136.614,31.491Z" transform="translate(-124.28 -17.554)" />
                    <path class="b" d="M164.956,37.35a.785.785,0,1,0-.786-.784A.785.785,0,0,0,164.956,37.35Z" transform="translate(-152.415 -27.332)" />
                    <path class="b" d="M140.456,37.35a.785.785,0,1,0-.786-.784A.785.785,0,0,0,140.456,37.35Z" transform="translate(-133.699 -27.332)" />
                    <path class="b" d="M152.986,63.73a.786.786,0,1,0,.784.786A.786.786,0,0,0,152.986,63.73Z" transform="translate(-143.271 -48.683)" />
                    <path class="b" d="M136.332,56.85a.512.512,0,1,0,.512.512A.512.512,0,0,0,136.332,56.85Z" transform="translate(-130.758 -43.428)" />
                    <path class="b" d="M170.612,56.85a.512.512,0,1,0,.512.512A.512.512,0,0,0,170.612,56.85Z" transform="translate(-156.944 -43.428)" />
                </g>
            </svg>
        </>
    );
}