import React from 'react'

const TotalEnergyConsumption = () => {
    return (

        <svg width="101" height="103" viewBox="0 0 101 103" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_35588)">
                <rect x="15.6992" y="3.20312" width="69.6" height="69.6" rx="34.8" fill="white" />
                <rect x="14.1992" y="1.70312" width="72.6" height="72.6" rx="36.3" stroke="#B5CBF7" stroke-width="3" />
                <path d="M52.1442 36.3527L56.5142 25.0527H54.8542L39.1992 40.4527L46.6392 41.8627L42.2992 53.3527H44.0992L60.2092 37.9327L52.1442 36.3527ZM49.3442 40.3427L43.3242 39.2027L52.3242 30.3527L49.4142 37.8627L56.0342 39.1527L46.2642 48.5027L49.3442 40.3427Z" fill="#467CEB" />
            </g>
            <defs>
                <filter id="filter0_d_9271_35588" x="0.699219" y="0.203125" width="99.5996" height="103.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.731999 0 0 0 0 0.745852 0 0 0 0 0.769401 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_35588" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_35588" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default TotalEnergyConsumption