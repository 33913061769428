import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    try {
        const response = await api.get('prometheus/user/');
        localStorage.setItem('userData', JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        console.error('Error fetching user data', error);
        throw error;
    }
    });

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    isLoading: false,
    error: null,
    currency: '',
    userCurrency: '',
    monthNumber: null,
    year: null
  },
  reducers: {
    setCurrency: (state,action) => {
      state.currency = action.payload;
    },
    setUserCurrency: (state,action) => {
      state.userCurrency = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.currency = action.payload.data.currency;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; 
        
      });
  },
});

export const {setCurrency, setUserCurrency} = UserSlice.actions;
export default UserSlice.reducer;
