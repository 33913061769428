import { useSubscription } from "@apollo/client"
import { useState } from "react"
import { useEffect } from "react"
import { API_CHARGER_NOT_USED_FACILITY_OPERATION_SUBSCRIPTION, API_CHARGER_NOT_USED_OPERATION_SUBSCRIPTION, API_CHARGER_OPERATION_FACILITY_SUBSCRIPTION, API_CHARGER_OPERATION_SUBSCRIPTION } from "../../graphql/query"
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const calculateRange = (days) => {
    const today = new Date();
    const start = new Date(today);
    start.setDate(today.getDate() - days);
    return {
        start: start.toISOString().split('T')[0],
        end: today.toISOString().split('T')[0],
    };
}

const colors = ['indigo', 'lightGreen', 'lime', '#FFADAD'];

const ChargerOperationStatus = ({ boxColor ,facilityFilter}) => {

    const [chargerStatus, setChargerStatus] = useState(null);

    const apiUsed=facilityFilter?.name==='All' ? API_CHARGER_OPERATION_SUBSCRIPTION: API_CHARGER_OPERATION_FACILITY_SUBSCRIPTION;
    const apiNotUsed=facilityFilter?.name==='All'? API_CHARGER_NOT_USED_OPERATION_SUBSCRIPTION : API_CHARGER_NOT_USED_FACILITY_OPERATION_SUBSCRIPTION;

    const { t } = useTranslation();

    const { data } = useSubscription(apiUsed, {
        variables: {
            start: calculateRange(15).start,
            end: calculateRange(15).end,
            facility_id: facilityFilter?.id
        }
    })
    const { data: data1 } = useSubscription(apiUsed, {
        variables: {
            start: calculateRange(30).start,
            end: calculateRange(30).end,
            facility_id: facilityFilter?.id
        }
    })
    const { data: data2 } = useSubscription(apiUsed, {
        variables: {
            start: calculateRange(60).start,
            end: calculateRange(60).end,
            facility_id: facilityFilter?.id
        }
    })
    const { data: data3 } = useSubscription(apiNotUsed, {
        variables: {
            start: calculateRange(180).start,
            end: calculateRange(180).end,
            facility_id: facilityFilter?.id
        }
    })

    useEffect(() => {
        setChargerStatus([
            { heading: "Used Within 15 days", status: data?.view_charger_operations_aggregate?.aggregate?.count ?? 0 },
            { heading: "Used Within 30 days", status: data1?.view_charger_operations_aggregate?.aggregate?.count ?? 0 },
            { heading: "Used Within 60 days", status: data2?.view_charger_operations_aggregate?.aggregate?.count ?? 0 },
            { heading: "Unused For Over 6 Months", status: data3?.view_charger_operations_aggregate?.aggregate?.count ?? 0 },
        ])
    }, [data, data1, data2, data3,facilityFilter])

    return (
        <Grid item backgroundColor={boxColor} sx={{ borderRadius: '16px', marginBottom: '24px' }}>
            {chargerStatus &&
                <Box sx={{padding: '20px'}}>
                    <Typography variant="h5" component='h5' sx={{ marginBottom: '24px', fontWeight: '600' ,fontSize:'20px'}}>
                    {t('chargerOperationStatus')}
                    </Typography>
                    <Grid container justifyContent='space-between'>
                        {
                            chargerStatus?.map((item, index) => <Grid item xs={2.3} sx={{
                                padding: '4px 7px',
                                justifyContent: 'space-between',
                                borderLeft: `4px solid ${colors[index]}`,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                                key={item?.key}>
                                <Typography variant='overline' component='span' sx={{ marginRight: '15px', lineHeight: '16px', textTransform: 'capitalize', fontSize:'12px', fontWeight:'500', color: '#5C677D'}}>
                                    {item?.heading}
                                </Typography>
                                <Typography variant='subtitle2' component='span' sx={{ fontWeight: '600', fontSize: '14px', color: '#001233'}}>
                                    {item?.status}
                                </Typography>
                            </Grid>)
                        }
                    </Grid>
                </Box>
            }
        </Grid>
    );
}

export default ChargerOperationStatus;