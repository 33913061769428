


export const TeslaOn = () => {

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a{fill:#1776FE;}.b{fill:#fff;}`}</style>
                </defs>
                <g transform="translate(0.403)">
                    <circle class="a" cx="25" cy="25" r="25" transform="translate(-0.403)" />
                    <path class="b" d="M255.946,224.328a12.584,12.584,0,0,0-1.651-4.107c-.586-.257-1.291-.533-2.108-.8-.5-.163-1.037-.32-1.623-.467-.144-.038-.288-.072-.439-.107h0a30.8,30.8,0,0,0-6.6-.8,26.218,26.218,0,0,0-6.635.692c-.094.022-.188.047-.282.069h0c-.73.188-1.388.395-1.98.608a17.69,17.69,0,0,0-1.635.68s0,0,0,0a4.684,4.684,0,0,0-.379.37l0,0a12.572,12.572,0,0,0-1.375,3.33v0a12.688,12.688,0,1,0,24.718.52ZM232.93,224a3.481,3.481,0,0,1,1.9-3.1,3.438,3.438,0,0,1,1.588-.385A3.484,3.484,0,1,1,232.93,224Zm5.316,11.926a.918.918,0,1,1,.918-.918A.917.917,0,0,1,238.246,235.925Zm5.282.235a1.455,1.455,0,1,1,1.457-1.457A1.453,1.453,0,0,1,243.528,236.16Zm5.379-.235a.918.918,0,1,1,.918-.918A.917.917,0,0,1,248.907,235.925Zm5.182-6.018a10.8,10.8,0,0,1-2.115,4.185.289.289,0,0,1-.4.044,13.38,13.38,0,0,0-8.048-2.672,12.354,12.354,0,0,0-7.829,2.751.288.288,0,0,1-.4-.034,10.948,10.948,0,0,1-.9-1.241c-.172-.273-.329-.554-.476-.843a10.818,10.818,0,0,1-.862-2.378.478.478,0,0,1,.175-.486l.561-.439a.471.471,0,0,1,.486-.063,5.193,5.193,0,0,0,4.586-9.311h9.455a5.19,5.19,0,0,0,4.5,9.345.477.477,0,0,1,.517.094l.614.583A.49.49,0,0,1,254.088,229.907Zm.063-5.075a3.486,3.486,0,1,1,.1-.833A3.479,3.479,0,0,1,254.151,224.832Z" transform="translate(-218.84 -204.045)" />
                </g>
            </svg>
        </>
    );
}