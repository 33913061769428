import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchAddAdminForm = createAsyncThunk('addAdmin/fetchAddAdminForm', async (stationId, { rejectWithValue }) => {
  try {
    const response = await api.get(`prometheus/${stationId}/admin/add/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching admin form', error);
    throw rejectWithValue(error);
  }
});

const AddAdminSlice = createSlice({
  name: 'addAdmin',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddAdminForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchAddAdminForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddAdminForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default AddAdminSlice.reducer;

