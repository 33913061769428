import React from "react";
import {
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const CustomOptionsModal = ({
  isOpen,
  anchorEl,
  onClose,
  handleEdit,
  handleGetConfiguration
  // showtransitionBtn,
  // handleOpenTransition,
}) => {

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={{ p: 2, maxWidth: 400 }}>
        <List>
          <ListItem button onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </ListItem>
          {handleGetConfiguration && (
            <ListItem button onClick={handleGetConfiguration}>
              <ListItemIcon>
              <CompareArrowsIcon />
              </ListItemIcon>
              <ListItemText>Get Configuration</ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
    </Popover>
  );
};

export default CustomOptionsModal;
