import React from "react";
import { Box, Typography } from "@mui/material";

const EmptyData = ({imagePath, message, height}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height || "calc(100vh - 270px)",
        width: "100%"
      }}
    >
      <img
        src={imagePath}
        alt="No Stations Found"
        style={{ width: "100px", marginBottom: "16px" }}
      />
      <Typography variant="h6" gutterBottom>
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyData;
