import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { formatDate } from '../../common';

const VehicleTable = ({ data }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Pagination calculation
    const paginatedData = data?.table_content.length && data?.table_content?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        data?.table_content.length ? (
            <Paper style={{ width: '100%' }}>
                <TableContainer style={{ width: '100%', overflowX: 'auto' }}>
                    <Table style={{ minWidth: '100%' }}>
                        <TableHead>
                            <TableRow>
                                {data?.headers.map((column) => (
                                    <TableCell key={column.id} style={{ whiteSpace: "nowrap" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {data?.headers.map((column) => (
                                        <TableCell key={column.id} style={{ whiteSpace: "nowrap" }}>
                                            {column.id === "last_sync_at"
                                                ? formatDate(row[column.id])
                                                : row[column.id] === true
                                                    ? "Yes"
                                                    : row[column.id] === false
                                                        ? "No"
                                                        : row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[30, 60, 90]}
                    component="div"
                    count={data?.table_content.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        ) : "No Data Available"
    );
};

export default VehicleTable;