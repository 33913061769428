import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const CustomMultilineInput = ({
  label,
  rows = 2,
  placeholder,
  col_size,
  required,
  value: propValue,
  onValueChange,
  disabled,
  errorMessage,
  isError
}) => {
  const [value, setValue] = useState("");
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setValue(propValue!== null ? propValue:"");
  }, [propValue]);


  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onValueChange(newValue);
    setHelperText("");
  };

  const onBlur = () => {
    if (required && !value.trim()) {
      setHelperText("Please enter a value");
    }
  };

  const hasError = Boolean(helperText);

  return (
    <Grid item xs={col_size}>
      <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      <TextareaAutosize
        minRows={rows}
        placeholder={placeholder}
        style={{
          width: "100%",
          borderRadius: "12px",
          border: `1px solid ${
            (hasError || isError) && "#d32f2f"
          }`,
          padding: "12px 12px 12px 27px",
          marginTop: "16px",
          resize: "none",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Helvetica",
          letterSpacing: "0.5px",
          opacity: "0.7",
        }}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
      />
      {isError ? 
        <Typography
          variant="caption"
          color="error"
          style={{ position: "relative", top: "-7px" }}
        >
          {errorMessage}
        </Typography>
      :
        <Typography
          variant="caption"
          color="error"
          style={{ position: "relative", top: "-7px" }}
        >
          {helperText}
        </Typography>
      }
    </Grid>
  );
};

export default CustomMultilineInput;
