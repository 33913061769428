import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";

export const userRangeData = createAsyncThunk("user/fetchUserRangeData", async ({ userId, startDate, endDate }) => {
    try {
      const response = await api.get(`prometheus/user/${userId}/history/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
});

const UserRangeSlice = createSlice({
  name: "userRange",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userRangeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userRangeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(userRangeData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default UserRangeSlice.reducer;
