import React from 'react'

const Average = () => {
    return (

        <svg width="110" height="115" viewBox="0 0 110 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9271_30344)">
                <rect x="15" y="10" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="8.5" width="83" height="83" rx="41.5" stroke="#7FE0BD" stroke-width="3" />
                <path d="M51.8303 40.9641L52.007 40.7873L51.8303 40.6105L45.9641 34.7444L45.7873 34.5676L45.6105 34.7444L39.7444 40.6105L39.5676 40.7873L39.7444 40.9641L45.6105 46.8303L45.7873 47.007L45.9641 46.8303L51.8303 40.9641ZM56.1697 57.0359L55.993 57.2127L56.1697 57.3895L62.0359 63.2556L62.2127 63.4324L62.3895 63.2556L68.2556 57.3895L68.4324 57.2127L68.2556 57.0359L62.3895 51.1697L62.2127 50.993L62.0359 51.1697L56.1697 57.0359ZM63.3106 33.8232L63.1338 33.6464L62.9571 33.8232L38.8232 57.9571L38.6464 58.1338L38.8232 58.3106L44.6894 64.1768L44.8662 64.3536L45.0429 64.1768L69.1768 40.0429L69.3536 39.8662L69.1768 39.6894L63.3106 33.8232ZM42.621 40.7873L45.7873 37.621L48.9537 40.7873L45.7873 43.9537L42.621 40.7873ZM62.2127 60.379L59.0463 57.2127L62.2127 54.0463L65.379 57.2127L62.2127 60.379ZM41.6998 58.1338L63.1338 36.6998L66.3002 39.8662L44.8662 61.3002L41.6998 58.1338Z" fill="#30C48F" stroke="#30C48F" stroke-width="0.5" />
            </g>
            <circle cx="55" cy="50" r="49.8613" stroke="#467CEB" stroke-width="0.277344" stroke-dasharray="1.66 1.66" />
            <defs>
                <filter id="filter0_d_9271_30344" x="0" y="7" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9271_30344" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9271_30344" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default Average