import { useSubscription } from "@apollo/client";
import { API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION, API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION } from "../graphql/query";
import { useEffect, useState } from "react";
import ProfitIcon from "./dashboard/icons/ProfitIcon";
import { generateTotalRevenue } from "./TotalRevenue";
import { useSelector } from "react-redux";
import ReactCardFlip from "react-card-flip";
import {
    Typography,
    Box,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Paper,
    TableContainer,
    Tooltip,
    IconButton
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { useTranslation } from 'react-i18next';

const generateElectricityConsumedPayload=(data,  facilityFilter,currency=null)=>{
    return [{
        type: 'Electricity Bill',
        units: data.units_consumed?.toFixed(2),
        price: (currency ? currency : ' ')+ facilityFilter?.price_per_unit_electricity?.toFixed(2),
        amount: data.electricity_total?.toFixed(2)
    }];
}


const ElectricityConsumed = ({ dateFilter, facilityFilter }) => {

    const api = facilityFilter?.name === 'All' ?
        API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION : API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION;
    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id
        }
    };

    const { data } = useSubscription(api, variables);
    const [electricityConsumed, setElectricityConsumed] = useState(0.00);
    const {currency} = useSelector(state => state.user);
    const stationlist = useSelector(state => state.stationList.data.data);
    const [rows, setRows] = useState(null);
    const [isFlipped, setIsFlipped] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        let interval;
        if (data?.view_facility_revenue) {
            const facilityValues = generateTotalRevenue(data?.view_facility_revenue, stationlist);
            if (/^[0-9.]+$/.test(facilityValues?.electricity_total)) {
                setElectricityConsumed((facilityValues.electricity_total)?.toFixed(2));
            }
            else {
                setElectricityConsumed(0)
            }
            if(facilityFilter?.is_fleet)
            setRows(generateElectricityConsumedPayload(facilityValues,facilityFilter,currency));
        }
        else {
            setElectricityConsumed((0));
            interval = setInterval(() => {
                setElectricityConsumed(prev => prev + 500);
            }, 50);
        }
        return () => clearInterval(interval);
    }, [data, dateFilter, facilityFilter])

    useEffect(() => {
        setIsFlipped(false);
    }, [dateFilter, facilityFilter])

    const handleFlip = () => {
        setIsFlipped(prev => !prev);
    }


    return (
        <Box>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedFrontToBack="0.6" flipSpeedBackToFront="0.6">
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    bgcolor={"white"}
                    justifyContent={"space-between"}
                    borderRadius={"16px"}
                    padding='24px'
                    height='130px'
                    key="front"
                >
                    <Typography>
                        <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{textWrap:'nowrap'}}>
                            {t('electricityBill')}
                            </Typography>
                        <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{textWrap:'nowrap'}}>{`${currency} ${electricityConsumed}`}</Typography>
                    </Typography>
                    <ProfitIcon />
                    {
                        facilityFilter?.is_fleet &&
                        <Tooltip title="Info" sx={{ position: "absolute", top: "-3px", right: "0px", color: "grey" }} >
                            <IconButton>
                                <Box onClick={handleFlip} >
                                    <InfoOutlinedIcon />
                                </Box>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                {facilityFilter?.is_fleet &&
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        borderRadius={"16px"}
                        height='130px'
                        key="back"
                        overflow="hidden"
                    >
                        <TableContainer component={Paper} sx={{ height: '100%', width: '100%', overflow: 'hidden', padding: '5px 5px 0', border: "none", backgroundColor: "grey", color: 'white' }}>
                            <Typography variant="h5" fontSize='12px' fontWeight='600' padding='5px' textAlign='center'>ELECTRICITY BILL</Typography>
                            <Table aria-label="simple table" sx={{ margin: '10px 0 0' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>Type</TableCell>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>Units&nbsp;(kWh)</TableCell>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{currency || ' '}/kWh</TableCell>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows &&
                                        rows.map((row) => (
                                            <TableRow
                                                key={row.type}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" align="center" sx={{ padding: '2px', fontSize: "10px", color: 'white' }}>
                                                    {row.type}
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{row.units}</TableCell>
                                                <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{row.price}</TableCell>
                                                <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{currency || ' '}{row.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Tooltip title="Rotate" sx={{ position: "absolute", top: "-3px", right: "0px", color: "white" }} >
                            <IconButton>
                                <Box onClick={handleFlip} >
                                    <ThreeSixtyIcon />
                                </Box>
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </ReactCardFlip>
        </Box >
    );
}
export default ElectricityConsumed;
