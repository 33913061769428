import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, useJsApiLoader, DirectionsRenderer, Marker } from "@react-google-maps/api";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';

const mapContainerStyle = { width: "100%", height: "350px" };

const RouteMap = ({ mapData, vehicle_Data }) => {
    const [vehicleList, setVehicleList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mapLoading, setMapLoading] = useState(false);
    const [directions, setDirections] = useState(null);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    const loadFacilityList = async () => {
        try {
            if (mapData?.data?.length > 0) {
                setMapLoading(true);
                const filteredData = mapData.data
                    .filter(vehicle => vehicle.latitude !== "0" && vehicle.latitude !== "0")
                    .slice(0, 27);
                const transformedData = filteredData.map(vehicle => ({
                    location: { lat: Number(vehicle.latitude), lng: Number(vehicle.longitude) },
                }));

                setVehicleList(transformedData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const calculateRoute = () => {
        if (vehicleList.length < 2) return;

        const origin = vehicleList[0].location;
        const destination = vehicleList[vehicleList.length - 1].location;
        const waypoints = vehicleList.slice(1, -1).map(vehicle => ({
            location: vehicle.location,
            stopover: true,
        }));

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin,
                destination,
                waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`Error fetching directions: ${status}`);
                }
            }
        );
        setMapLoading(false);
    };

    const handleOnLoad = useCallback((map) => {
        if (!window.google) {
            console.error('Google Maps JavaScript API not loaded.');
            return;
        }

        const bounds = new window.google.maps.LatLngBounds();
        vehicleList.forEach(({ location }) => {
            if (location) {
                bounds.extend(location);
            }
        });
        map.fitBounds(bounds);
    }, [vehicleList]);

    useEffect(() => {
        loadFacilityList();
    }, [mapData]);

    useEffect(() => {
        if (isLoaded && vehicleList.length > 1) {
            calculateRoute();
        }
    }, [isLoaded, vehicleList]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    const startIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
    const endIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';

    return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }} style={mapContainerStyle}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Table>
                        <TableBody>
                            {vehicle_Data?.map((vehicle) => (
                                <TableRow>
                                    <TableCell width={"30%"}>
                                        <Typography variant="body1" color="initial" fontWeight="500">
                                            {vehicle.name}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1" color="initial">
                                            {vehicle.value}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {mapLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }} style={mapContainerStyle}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        mapData.data.length > 0 && (
                            <GoogleMap
                                onLoad={handleOnLoad}
                                zoom={12}
                                mapContainerStyle={mapContainerStyle}
                            >
                                {directions && (
                                    <DirectionsRenderer
                                        directions={directions}
                                        options={{ suppressMarkers: true }}
                                    />
                                )}

                                {vehicleList.length > 0 && (
                                    <>
                                        <Marker
                                            position={vehicleList[0].location}
                                            icon={startIcon}
                                            label={"Start"}
                                        />
                                        <Marker
                                            position={vehicleList[vehicleList.length - 1].location}
                                            icon={endIcon}
                                            label={"end"}
                                        />
                                    </>
                                )}
                            </GoogleMap>
                        )

                    )}
                </>
            )}
        </div>
    );
}

export default RouteMap;