import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  Box,
  Typography,
  Container,
  ThemeProvider,
  createTheme,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../slice/LoginSlice";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Logo from "./Logo";

const Login = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.login.apiError);
  const loginState = useSelector((state) => state.login);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loginState.data.success === true) {
      navigate("/charger/dashboard");
    }
  }, [loginState.data.success, navigate]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (newPassword.length >= 4 || e.inputType === "insertText") {
      setIsPasswordValid(newPassword.length >= 4);
    } else {
      setIsPasswordValid(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const isEmailAndPasswordValid = isEmailValid && isPasswordValid;

  const isSmallScreen = window.innerWidth < 600;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    setIsEmailValid(isValid);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const handleLogin = () => {
    dispatch(loginUser({ email, password }));
  };

  const theme = createTheme();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth={false}
        style={{ paddingInline: "0px" }}
      >
        <CssBaseline />
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundImage: `url("/bg-login.png")`,
              height: "100%",
              p: { xs: 2, sm: 7 }, // Set padding 2 on xs, 7 on sm
              backgroundSize: "cover",
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                borderImageSource:
                  "radial-gradient(102.1% 102.1% at 17.33% 0%, #FFFFFF 0%, rgba(228, 228, 228, 0.750969) 63.85%, rgba(255, 255, 255, 0.61) 100%)",
                borderImageSlice: "fill", // Specify the border image slice property
                borderRadius: "17px",
                pt: { xs: 2, sm: 5 },
              }}
            >
              <Logo />
              <Typography
                variant="h1"
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#33415C",
                  px: { xs: 2, sm: 7 },
                }}
              >
                Keep tracking of electric vehicle charging experience,
                <Link
                  href="#"
                  color="inherit"
                  style={{
                    color: "#1858DC",
                    textDecoration: "none",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  monitor charging sessions ,
                </Link>
                <Link
                  href="#"
                  color="inherit"
                  style={{
                    color: "#1858DC",
                    textDecoration: "none",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  manage payment ,
                </Link>
                <Link
                  href="#"
                  color="inherit"
                  style={{
                    color: "#1858DC",
                    textDecoration: "none",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  reports
                </Link>{" "}
                and all...
              </Typography>
              <img
                src="/round-charge.svg"
                alt="Car Charging"
                style={{
                  marginTop: "30px",
                  width: isSmallScreen ? "100%" : "auto",
                }}
              />
              <img
                src="/carcharging.png"
                alt="Car Charging"
                style={{ marginTop: "-20px", width: "100%" }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ px: { xs: 2, sm: 7 }, py: { xs: 7, sm: 7 } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    fontWeight: 600,
                    fontSize: "36px",
                    lineHeight: "36px",
                    letterSpacing: "1%",
                    textAlign: "center",
                    color: "#0C1421",
                    marginBottom: "16px",
                  }}
                >
                  Welcome 👋
                </Typography>
                <Typography
                  variant="h2"
                  style={{
                    width: "254px",
                    height: "29px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "24px",
                    lineHeight: "29.05px",
                    color: "#33415C",
                    marginBottom: "60px",
                  }}
                >
                  Login to your account
                </Typography>
                <Box component="form" noValidate sx={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29.05px",
                      color: "#33415C",
                    }}
                  >
                    Enter your email address 📧
                  </Typography>
                  <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                    <InputLabel htmlFor="email">Enter email address</InputLabel>
                    <Input
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Enter email address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={handleEmailChange}
                      variant="standard"
                      endAdornment={
                        isEmailValid && (
                          <InputAdornment position="end">
                            <Avatar
                              sx={{
                                backgroundColor: "green",
                                marginLeft: "8px",
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              ✓
                            </Avatar>
                          </InputAdornment>
                        )
                      }
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16.8px",
                        color: isEmailValid ? "#7D8597" : "#7D8597",
                      }}
                    >
                      {isEmailValid
                        ? "For example xyz@gmail.com"
                        : "For example xyz@gmail.com"}
                    </Typography>
                  </FormControl>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29.05px",
                      color: "#33415C",
                      marginTop: "10px",
                    }}
                  >
                    Enter your password
                  </Typography>
                  <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                    <InputLabel htmlFor="password">Enter password</InputLabel>
                    <Input
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Enter password"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={handleKeyPress}
                      variant="standard"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      color: isPasswordValid ? "#7D8597" : "#7D8597",
                    }}
                  >
                    {isPasswordValid
                      ? "Password is Valid"
                      : "Password must be at least 8 characters"}
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: "primary.main",
                      py: 2,
                    }}
                    onClick={handleLogin}
                    style={{ borderRadius: "28px" }}
                    disabled={!isEmailAndPasswordValid}
                  >
                    Continue
                  </Button>
                  {apiError && (
                    <Typography color="error" mt={2}>
                      {apiError}
                    </Typography>
                  )}
                </Box>
              </Paper>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "16.8px",
                  color: "#33415C",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                By proceeding you agree to our
                <Link
                  to="/terms"
                  style={{
                    color: "#467CEB",
                    marginLeft: "4px",
                    marginRight: "4px",
                  }}
                >
                  Terms & conditions
                </Link>
                and
                <Link
                  to="/privacy"
                  style={{ color: "#467CEB", marginLeft: "4px" }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
