import React from 'react'

const Alert = () => {
    return (
        <svg width="110" height="108" viewBox="0 0 110 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_8029_20554)">
                <rect x="15" y="3" width="80" height="80" rx="40" fill="white" />
                <rect x="13.5" y="1.5" width="83" height="83" rx="41.5" stroke="#FFADAD" stroke-width="3" />
                <path d="M70.8387 47.45L59.3337 28.37C58.8685 27.6423 58.2275 27.0433 57.4699 26.6284C56.7123 26.2136 55.8624 25.9961 54.9987 25.9961C54.1349 25.9961 53.2851 26.2136 52.5275 26.6284C51.7699 27.0433 51.1289 27.6423 50.6637 28.37L39.1587 47.45C38.7523 48.1275 38.5314 48.9001 38.5182 49.69C38.505 50.4799 38.7001 51.2594 39.0837 51.95C39.5272 52.7274 40.1692 53.3731 40.944 53.8212C41.7188 54.2692 42.5987 54.5035 43.4937 54.5H66.5037C67.3928 54.5095 68.2687 54.2843 69.043 53.8471C69.8173 53.4099 70.4625 52.7763 70.9137 52.01C71.3086 51.3122 71.5098 50.5215 71.4966 49.7198C71.4835 48.9181 71.2563 48.1345 70.8387 47.45ZM68.2887 50.525C68.1084 50.8349 67.8468 51.0896 67.5321 51.2614C67.2175 51.4333 66.8618 51.5158 66.5037 51.5H43.4937C43.1355 51.5158 42.7799 51.4333 42.4652 51.2614C42.1506 51.0896 41.889 50.8349 41.7087 50.525C41.577 50.297 41.5077 50.0383 41.5077 49.775C41.5077 49.5117 41.577 49.2531 41.7087 49.025L53.2287 29.93C53.4357 29.6566 53.7032 29.4348 54.0103 29.2821C54.3174 29.1295 54.6557 29.05 54.9987 29.05C55.3416 29.05 55.6799 29.1295 55.987 29.2821C56.2941 29.4348 56.5617 29.6566 56.7687 29.93L68.2737 49.01C68.4104 49.2385 68.4839 49.4993 68.4865 49.7655C68.4892 50.0317 68.4209 50.2939 68.2887 50.525Z" fill="#FF4949" />
                <path d="M54.998 48.5C55.8265 48.5 56.498 47.8284 56.498 47C56.498 46.1716 55.8265 45.5 54.998 45.5C54.1696 45.5 53.498 46.1716 53.498 47C53.498 47.8284 54.1696 48.5 54.998 48.5Z" fill="#FF4949" />
                <path d="M54.998 35.001C54.6002 35.001 54.2187 35.159 53.9374 35.4403C53.6561 35.7216 53.498 36.1032 53.498 36.501V42.501C53.498 42.8988 53.6561 43.2803 53.9374 43.5616C54.2187 43.8429 54.6002 44.001 54.998 44.001C55.3959 44.001 55.7774 43.8429 56.0587 43.5616C56.34 43.2803 56.498 42.8988 56.498 42.501V36.501C56.498 36.1032 56.34 35.7216 56.0587 35.4403C55.7774 35.159 55.3959 35.001 54.998 35.001Z" fill="#FF4949" />
            </g>
            <defs>
                <filter id="filter0_d_8029_20554" x="0" y="0" width="110" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8029_20554" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8029_20554" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default Alert