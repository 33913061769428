import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const CustomSelect = ({
  label,
  options,
  placeholder,
  col_size,
  required,
  onValueChange,
  value: propValue,
  errorMessage,
  isError
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setSelectedValue(propValue!== null ? propValue:"");
  }, [propValue]);


  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onValueChange(newValue);
  };

  const onBlur = () => {
    if (required && !selectedValue) {
      // Validate if required and not selected
      setHelperText("Please select a value");
    } else {
      setHelperText(""); // Reset the helper text
    }
  };

  return (
    <Grid item xs={col_size}>
      <Typography style={{fontSize: "14px", fontWeight: "600", lineHeight: "18px"}}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      <TextField
        style={{ marginLeft: "0" }}
        select
        label={
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                color: "var(--gray-500, #979DAC)",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selectedValue === "" ? placeholder : ""}
            </Typography>
          </Grid>
        }
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleChange}
        onBlur={onBlur}
        helperText={ <Typography
          variant="caption"
          color="error"
          style={{ marginTop: "5px", marginLeft: "0" }}
        >
          {isError ? errorMessage: helperText}
        </Typography>}
        error={isError}
        value={selectedValue}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          style: {
            borderRadius: "12px",
            display: "flex",
            height: "44px",
            padding: "12px",
            alignItems: "center",
            gap: "12px",
            border: helperText ? "1px solid red" : "none"
          },
        }}
        FormHelperTextProps={{
          style: { marginLeft: "0" }
        }}
      >
        {placeholder && (
          <MenuItem disabled value={placeholder}>
            {placeholder}
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default CustomSelect;