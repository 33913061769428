import React from "react";
import { Dialog, DialogContent, IconButton, styled, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });  

const CustomDialog = ({ isOpen, onClose, component, top, right, color, topPosition, isTransition = false }) => {
  return (
    <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="xl"
        TransitionComponent={isTransition ? Transition : undefined}
        style={{width: "100%"}}
        sx={{
            "& .MuiPaper-root": {
            width: "100%",
            top: topPosition || "0"
          },
        }}
      >
        <DialogContent>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: top || "8px",
              right: right || "8px",
              color: color || "var(--light-mode-gray-80, #5C677D)",
            }}
          >
            <CloseIcon />
          </IconButton>
          {component}
        </DialogContent>
      </BootstrapDialog>
  );
};

export default CustomDialog;
