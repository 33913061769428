import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchGetConfigurationForm = createAsyncThunk('getConfiguration/fetchGetConfigurationForm', async (selectedChargerGetConfiguration,  { rejectWithValue }) => {
    try {
        const response = await api.post(`${selectedChargerGetConfiguration}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching edit charger form', error);
        throw rejectWithValue(error);
    }
    });

const GetConfigurationSlice = createSlice({
  name: 'getConfiguration',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetConfigurationForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchGetConfigurationForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload?.data?.type ? action.payload : [];
        if (!action.payload?.data?.type)
        state.error=true
      })
      .addCase(fetchGetConfigurationForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default GetConfigurationSlice.reducer;
