import React from 'react';
import { Modal, Grid } from '@mui/material';

const CustomModal = ({ open, onClose, children, bgColor, width, maxHeight, overflow, height }) => {
  
  return (
    <Modal open={open} onClose={onClose} >
      <Grid
        style={{
          width: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: bgColor || 'lightgrey',
          borderRadius: '16px',
          boxShadow: '0px 8px 32px 0px rgba(16, 24, 43, 0.16)',
          padding: '16px',
          overflow: overflow || 'auto', 
          maxHeight: maxHeight || '70vh',
          width: width || 'normal',
          height: height || 'normal'
        }}
      >
        {children}
      </Grid>
    </Modal>
  );
};

export default CustomModal;
