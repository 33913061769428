import {
    Box, Grid
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from "react";
import noVehicle from "../../assets/emptyData/car-parking.svg";
import DynamicFormModal from "./DynamicFormModal.js";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux";
import FilterButton from "../buttonComponents/FilterButton.js";
import SearchButton from "../buttonComponents/SearchButton.js";
import EmptyData from "../EmptyData.js";
import NewDatePicker from "../NewDatePicker.js";
import CustomTabs from "./CustomTabs.js";
import VehicleTable from "./VehicleTable.js";
import VehicleTabs from "./VehicleTabs.js";


const VehicleDetails = ({
    vehicleData,
    vehicleloading
}) => {
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [searchedChargerData, setSearchedChargerData] = useState([]);
    const [tableSearchText, setTableSearchText] = useState("");
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
    const [dynamicModalType, setDynamicModalType] = useState("");
    const { selectedFacility } = useSelector(state => state.stationList);

    const apiTabs = vehicleData && vehicleData.tabs ? vehicleData.tabs : [];

    const handleTabChange = (_event, newValue) => {
        setTabValue(newValue);
        setTableSearchText("");
        const currentTabData = apiTabs[newValue];
        if (currentTabData && currentTabData.table_content) {
            setSearchedChargerData(currentTabData.table_content);
        }
    };

    const handleTabSearch = (searchText) => {
        setTableSearchText(searchText);
        const currentTabData = apiTabs[tabValue];
        if (currentTabData && currentTabData.table_content) {
            const filteredChargerData = currentTabData.table_content.filter((data) => {
                const searchTextLowerCase = searchText.toLowerCase();
                return Object.values(data).some(value => {
                    if (typeof value === "string") {
                        return value.toLowerCase().includes(searchTextLowerCase);
                    } else if (typeof value === "number") {
                        return value.toString().toLowerCase().includes(searchTextLowerCase);
                    } else if (value && typeof value === "object") {
                        return Object.values(value).some(val => {
                            if (typeof val === "string") {
                                return val.toLowerCase().includes(searchTextLowerCase);
                            } else if (typeof val === "number") {
                                return val.toString().toLowerCase().includes(searchTextLowerCase);
                            }
                            return false;
                        });
                    }
                    return false;
                });
            });
            setSearchedChargerData(filteredChargerData);
        }
    };
    const handleSearchButton = (searchText) => {
        handleTabSearch(searchText)
    };


    const renderButtons = () => {
        const currentTabData = apiTabs[tabValue];

        if (currentTabData && currentTabData.filters_and_buttons) {
            const placeholder = `Search ${currentTabData.name || "Item"}`;
            return currentTabData.filters_and_buttons.map((button, index) => {
                switch (button) {
                    case "Search":
                        return (
                            <SearchButton
                                key={index}
                                placeholder={placeholder}
                                handleOnSearch={(searchText) =>
                                    handleSearchButton(searchText)
                                }
                                value={tableSearchText}
                            />
                        );
                    case "Filters":
                        return <FilterButton key={index} onClick={handleOpenModal} />;
                    case "date_range_filter":
                        return (
                            <NewDatePicker facilityId={selectedFacility?.id} key={index} />
                        );
                    default:
                        return null;
                }
            });
        }
    };

    const renderContent = () => {
        const currentTabData = apiTabs[tabValue];

        if (currentTabData && currentTabData?.tab_content) {
            return (
                <VehicleTabs data={currentTabData} />
            );
        }
        if (currentTabData && currentTabData?.table_content) {
            return (
                <VehicleTable data={currentTabData} />
            );
        }
    };


    const handleAddFormOpen = () => {
        const newApiCallType = "addVehicle";
        localStorage.setItem("dynamicModalType", newApiCallType);
        setDynamicModalType(newApiCallType);
        setIsDynamicModalOpen(true);
    };

    const handleOpenModal = (vehicleID) => {
        setSelectedVehicle(vehicleID)
        // handleEditFormOpen()
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%", // Set the width of the parent container to 100%
                }}
            >
                <Box
                    style={{ boxShadow: "none" }}
                    sx={{
                        minHeight: "calc(100vh - 265px)",
                        width: "100%",
                    }}
                    p={2}
                >
                    {vehicleloading ? (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                height: "calc(100vh - 260px)",
                                overflow: "auto",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <CircularProgress />
                        </Grid>
                    ) : (
                        vehicleData ? (
                            <>
                                <Box>
                                    <CustomTabs formTabs={apiTabs}
                                        tabValue={tabValue}
                                        handleTabChange={handleTabChange} />
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "12px",
                                            margin: "16px 20px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {renderButtons()}
                                    </Grid>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "12px",
                                            margin: "16px 20px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {renderContent()}
                                    </Grid>
                                </Box>
                            </>
                        ) : (
                            <EmptyData imagePath={noVehicle} message={`No Vehicle Found`} />
                        )
                    )}
                </Box>
            </Box>
            <DynamicFormModal
                isOpen={isDynamicModalOpen}
                onClose={() => setIsDynamicModalOpen(false)}
                apiCallType={dynamicModalType}
                id={selectedVehicle}
            />
        </>
    );
};

export default VehicleDetails;
