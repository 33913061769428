import AddIcon from "@mui/icons-material/Add";
import {
    CircularProgress,
    Fab,
    Grid,
    Paper,
    Typography,
    styled
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

import DynamicFormModal from "../customComponents/DynamicFormModal";
import VehicleDetails from "../customComponents/VehicleDetails";
import ChangeDashboardView from "../dashboard/ChangeDashboardView";
import DynamicCardBox from "../dashboard/DynamicCardBox";
import EmptyData from "../EmptyData";
import ProfileSection from "../ProfileSection";
import DealerSelect from "./DealerSelect";
import api from "../../apiSetup/apiCall";
import noStations from "../../assets/emptyData/Dealer.png";
import config from '../../config';
import VehicleList from "../dashboard/DynamicVehicle";
import { useParams } from 'react-router-dom';


const StyledFab = styled(Fab)({
    zIndex: 1,
    top: -45,
    color: "#fff",
    background: "#467CEB",
    "&:hover": {
        background: "#467CEB",
    },
});

export default function DealerManagement() {
    const [searchedDealerList, setSearchedDealerList] = useState([]);
    const { dealerid, subdealerid, vehicleid } = useParams();
    const [dealerId, setDealerId] = useState(null);
    const [subDealerId, setSubDealerId] = useState(null);
    const [id, setId] = useState(null);
    const [modalState, setModalState] = useState({ isOpen: false, type: "" });
    const [subDealerData, setSubDealerData] = useState(null);
    const [vehicleData, setVehicleData] = useState(null);
    const [vehicleDetailsData, setVehicleDetailsData] = useState(null);
    const [loadingState, setLoadingState] = useState({
        dealer: true,
        subDealer: false,
        vehicle: false,
        vehicleDetails: false,
    });

    const { isLoading: loginLoading } = useSelector((state) => state.login);
    const { isLoading: dealerLoading, data: dealerData, selectedDealer } = useSelector((state) => state.dealerList);
    const { selectedDate } = useSelector(state => state.datePicker);
    const [vehicleId, setVehicleId] = useState(null);

    const timeoutRef = useRef();

    useEffect(() => {
        if (dealerData) {
            setLoadingState(prev => ({ ...prev, dealer: false }));
            setSearchedDealerList(dealerData.dealers || []);
            // setDealerDataList(dealerData.dealers || []);
        }
    }, [dealerData]);

    const fetchSubDealerData = async (dealerId) => {
        setLoadingState(prev => ({ ...prev, subDealer: true }));
        try {
            const response = await api(`${config.TELEMATICS_URL}dealer/${dealerId}/subdealers/`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            setSubDealerData(response.data);
            setVehicleData(null);
        } catch (error) {
            console.error("Error fetching sub-dealers:", error.response);
        } finally {
            setLoadingState(prev => ({ ...prev, subDealer: false }));
        }
    };

    const fetchVehicleData = async (subDealerId) => {
        setLoadingState(prev => ({ ...prev, vehicle: true }));
        try {
            const response = await api(`${config.TELEMATICS_URL}subdealer/${subDealerId}/vehicles/`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            setVehicleData(response.data);
        } catch (error) {
            console.error("Error fetching vehicles:", error.response);
        } finally {
            setLoadingState(prev => ({ ...prev, vehicle: false }));
        }
    };

    const fetchVehicleDeatilsData = async (vehicleId, startISOString, endISOString) => {
        console.log("startISOString", startISOString, endISOString)
        setLoadingState(prev => ({ ...prev, vehicleDetails: true }));
        try {
            const response = await api(`${config.TELEMATICS_URL}vehicle/detail/${vehicleId}/${startISOString}/${endISOString}/`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            setVehicleDetailsData(response.data.data);
        } catch (error) {
            console.error("Error fetching vehicles:", error.response);
        } finally {
            setLoadingState(prev => ({ ...prev, vehicleDetails: false }));
        }
    };

    useEffect(() => {
        if (vehicleId) {
            const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
            const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
            fetchVehicleDeatilsData(vehicleId, startISOString, endISOString);
        }
    }, [selectedDate, vehicleId]);

    const handleModalOpen = (type) => {
        localStorage.setItem("dynamicModalType", type);
        setModalState({ isOpen: true, type });
    };

    const handleCardClick = async (id, cardType) => {
        if (cardType === "dealer") {
            await fetchSubDealerData(id);
            setDealerId(id);
            setId(id);
        }
        if (cardType === "subdealer") {
            await fetchVehicleData(id);
            setSubDealerId(id);
            setId(id);
        }

        if (cardType === "vehicle") {
            setVehicleId(id)
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };
    const handleDealerSelectClick = (dealerId) => {
        if (dealerId !== 'All') {
            handleCardClick(dealerId, "dealer");
        } else {
            setSubDealerData(null);
        }
    };

    const handleSubDealerSelectClick = (subdealerId) => {
        if (subdealerId !== 'All') {
            handleCardClick(subdealerId, "subdealer");
        } else {
            setVehicleData(null);
        }
    };
    useEffect(() => {
        if (dealerid) handleCardClick(dealerid, "dealer");
        if (subdealerid) handleCardClick(subdealerid, "subdealer");
        if (vehicleid) handleCardClick(vehicleid, "vehicle");
    }, [dealerid, subdealerid, vehicleid])


    if (loginLoading || dealerLoading) {
        return (
            <Grid sx={{ position: "absolute", top: "50%", left: "50%" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            <Grid container rowSpacing={3} columnSpacing={3} alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="h4" fontSize="24px" fontWeight="600">
                        {selectedDealer?.name !== "All" ? `${selectedDealer?.name} - Dealers` : "Dealer Management - Dealers List"}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <ProfileSection />
                </Grid>
            </Grid>

            <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12}>
                    <ChangeDashboardView />
                </Grid>
            </Grid>

            <Grid container rowSpacing={3} columnSpacing={3} sx={{ marginTop: 1, marginLeft: "-16px" }}>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ marginBottom: subDealerId ? "0px" : "20px" }}>
                            <DealerSelect
                                searchedDealerList={searchedDealerList}
                                onDealerClick={handleDealerSelectClick}
                                selectedDealerCard={dealerId}
                            />
                        </Grid>
                        {subDealerId && (
                            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                                <DealerSelect
                                    subDealerData={subDealerData}
                                    onSubDealerClick={handleSubDealerSelectClick}
                                    selectedSubDealerCard={subDealerId}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ height: "calc(100vh - 260px)", overflow: "auto", borderRadius: "16px", background: "#FFF", boxShadow: "0px 4px 32px 0px rgba(152, 162, 179, 0.12)", padding: "16px" }}>
                            <PerfectScrollbar>
                                {loadingState?.vehicle ? (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            height: "calc(100vh - 260px)",
                                            overflow: "auto",
                                            justifyContent: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : vehicleData ? (
                                    <>
                                        <Typography mb={2} variant="body1">
                                            Vehicle
                                        </Typography>
                                        {vehicleData?.vehicles?.length ? (
                                            <VehicleList vehicleData={vehicleData?.vehicles} type={"dealer"} handleCardClick={handleCardClick} cardType="vehicle" selectedVehicle={vehicleid} />
                                        ) : (
                                            <EmptyData imagePath={noStations} message="No Vehicle found" />
                                        )}
                                    </>
                                ) : subDealerData ? (
                                    <>
                                        <Typography mb={2} variant="body1">
                                            Sub Dealers
                                        </Typography>
                                        {subDealerData.sub_dealers?.length ? (
                                            <DynamicCardBox
                                                handleCardClick={handleCardClick}
                                                subDealerData={subDealerData.sub_dealers}
                                                cardType="subdealer"
                                                loading={loadingState}
                                            />
                                        ) : (
                                            <EmptyData imagePath={noStations} message="No Sub dealer found" />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Typography mb={2} variant="body1">
                                            Dealers
                                        </Typography>
                                        {searchedDealerList?.length ? (
                                            <DynamicCardBox
                                                handleCardClick={handleCardClick}
                                                searchedDealerList={searchedDealerList}
                                                cardType="dealer"
                                                loading={loadingState}
                                            />
                                        ) : (
                                            <EmptyData imagePath={noStations} message="No dealer found" />
                                        )}
                                    </>
                                )}

                                <Grid
                                    position="fixed"
                                    sx={{ bottom: "15px", left: "25%" }}
                                    onClick={() => handleModalOpen(
                                        vehicleData ? "addVehicle" : subDealerData ? "addSubDealer" : "addDealer"
                                    )}
                                >
                                    <StyledFab aria-label="add">
                                        <AddIcon sx={{ fontSize: "30px" }} />
                                    </StyledFab>
                                </Grid>
                            </PerfectScrollbar>
                        </Grid>
                    </Grid>
                </Grid>

                {loadingState.vehicleDetails ? (
                    <Grid sx={{ position: "absolute", top: "50%", left: "60%" }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid item xs={9} sx={{ paddingTop: 0 }}>
                        {!vehicleDetailsData ? (
                            <Typography variant="h5" style={{ position: "absolute", top: "50%", left: "50%" }}>
                                Please select a Vehicle to see the data
                            </Typography>
                        ) : (
                            <Paper sx={{ borderRadius: "16px", background: "#fff", boxShadow: "none" }}>
                                <VehicleDetails vehicleData={vehicleDetailsData} vehicleloading={loadingState.vehicleDetails} subDealerData={subDealerData} />
                            </Paper>
                        )}
                    </Grid>
                )}

                <DynamicFormModal
                    isOpen={modalState.isOpen}
                    onClose={() => setModalState({ isOpen: false, type: "" })}
                    apiCallType={modalState.type}
                    id={id}
                />
            </Grid>
        </>
    );
}