import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';
import config from '../config';

export const fetchEditDealerForm = createAsyncThunk('editDealer/fetchEditDealerForm', async (dealerId,  { rejectWithValue }) => {
    try {
      const response = await api.get(config.TELEMATICS_URL + `dealer/edit/${dealerId}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching edt station form', error);
        throw rejectWithValue(error);
    }
    });

const EditDealerSlice = createSlice({
  name: 'editDealer',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditDealerForm.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchEditDealerForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditDealerForm.rejected, (state, action) => {
        state.isLoading = false;
        state.data = []
        state.error = true
      });
  },
});

export default EditDealerSlice.reducer;
