import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomDateInput from '../customComponents/CustomDateInput';
import CustomPasswordInput from '../customComponents/CustomPasswordInput';
import CustomInput from '../customComponents/CustomInput';
import CustomFileUpload from '../customComponents/CustomFileUpload';
import CustomMultilineInput from '../customComponents/CustomMultiLineInput';

const AppCredentials = () => {
    const [formValues, setFormValues] = useState({});

    const handleInputChange = (inputName, inputValue) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [inputName]:
                inputValue !== null
                    ? typeof inputValue === "string" || typeof inputValue === "number"
                        ? inputValue
                        : typeof inputValue === "boolean"
                            ? Boolean(inputValue)
                            : { ...inputValue }
                    : "", // Set to blank value if inputValue is null
        }));
    };

    const handleSave = async () => {
    }
    return (
        <Box p={0}>
            <Typography p={0} variant="body1" color="initial">Apps Credentials</Typography>
            <Typography p={0} variant="caption" color="initial">The Terra Charge API can be used by your app with these credentials. They are not public. Kindly refrain from disclosing your app credentials to third parties, putting them in open source code repositories, or storing them in an unsafe manner.</Typography>
            <Box style={{ marginTop: "16px" }}>
                {/* <Grid item xs={12} mb={"16px"}>
                    <TextFields label={"App name"} placeholder={"Enter your app name"} required={true} />
                </Grid> */}
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"App id"} placeholder={"A06KEMCN64X"} required={true} onValueChange={(value) => handleInputChange('app_id', value)} disabled={true} />
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomDateInput label={"Date of App Creation"} placeholder={"A06KEMCN64X"} required={false} onValueChange={(value) => handleInputChange('creation_date', value)} disabled={true}/>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomPasswordInput label={"Client ID"} placeholder={"A06KEMCN64X"} required={false} showPasswordIcon={true} showRegenrate={false} onValueChange={(value) => handleInputChange('client_id', value)} disabled={true}/>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomPasswordInput label={"Client secret"} placeholder={"A06KEMCN64X"} required={false} showPasswordIcon={true} showRegenrate={true} onValueChange={(value) => handleInputChange('client_secret', value)} disabled={true}/>
                </Grid>
                {/* <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"Signing Secret"} placeholder={"A06KEMCN64X"} required={false} showPasswordIcon={true} showRegenrate={true} />
                    <Typography p={0} variant="caption" color="initial">Terra Charge signs the requests we send you using this secret. Confirm that each request comes from Terra Charge by verifying its unique signature</Typography>
                </Grid> */}
                {/* <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"Verification Token"} placeholder={"A06KEMCN64X"} required={false} showPasswordIcon={false} showRegenrate={true} inputType="text" />
                    <Typography p={0} variant="caption" color="initial">You can still use this deprecated Verification Token to confirm that requests originate from Terra Charge, but we strongly advise using the more secure signing secret mentioned above</Typography>
                </Grid> */}
                <Grid item xs={12} mb={"16px"}>
                    <Typography p={0} variant="body1" color="initial">Display information</Typography>
                    <Typography p={0} variant="caption" color="initial">This information will appear in both the Terra Charge App Directory and within the Terra Charge App. Refer to our app detail guidelines for further details</Typography>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"App name"} placeholder={"Enter your app name"} required={true} onValueChange={(value) => handleInputChange('app_name', value)} disabled={true}/>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomInput label={"Short description"} placeholder={"Add a short description"} required={true} onValueChange={(value) => handleInputChange('short_desc', value)} disabled={true}/>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomMultilineInput label={"Long description"} placeholder={"Add a detailed description for your app that will appear on the App Detail page. Your description can be formatted with bold text, lists, and other elements."} required={true} onValueChange={(value) => handleInputChange('long_desc', value)} disabled={true}/>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <CustomFileUpload label={"Please upload the app icon and preview"} placeholder={"Add a short description"} required={false} onValueChange={(value) => handleInputChange('app_icon', value)} disabled={true}/>
                </Grid>
                <Grid item xs={12} mb={"16px"}>
                    <Typography p={0} variant="body1" color="error">Delete app</Typography>
                    <Typography p={0} variant="caption" color="initial">If your app is listed in the App Directory, notify users of any deprecation plans and be ready to address inquiries and support requests post-deletion</Typography>
                </Grid>
                <Grid item xs={12} mb={"16px"} justifyContent="center" display={"flex"}>
                    <Button
                        variant="contained"
                        style={{
                            borderRadius: "28px",
                            padding: "16px",
                            height: "40px",
                            backgroundColor: "Red",
                            textTransform: "capitalize",
                        }}
                        onClick={handleSave}
                    >
                        {"delete"}
                    </Button>
                </Grid>
            </Box>
        </Box>
    )
}

export default AppCredentials