import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import api from "../../apiSetup/apiCall";
import { t } from "i18next";

const CustomInputWithButton = ({ label, required, col_size, placeholder, error_message, is_error, disabled, value: propValue, button }) => {

    const [value, setValue] = useState("");
    const [helperText, setHelperText] = useState("");
    const [isError, setIsError] = useState(is_error);
    const [errorMessage, setErrorMessage] = useState(error_message);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const handleBlur = () => {
        if (required && (!value || !value.trim())) { // Check if value is undefined or empty
            setIsError(true);
            setErrorMessage("Please enter a value");
        }
    };

    useEffect(() => {
        setValue(propValue);
    }, [propValue])

    const submitHandler = async () => {
        if (window.confirm('Are you confirm?'));
        {
            try {
                setLoading(true);
                setIsError(false)
                setErrorMessage('');
                setHelperText('');
                const payload = {
                    ...button.payload,
                    value
                }
                const response = await api.post(button?.api, payload)
                setHelperText(response.data?.data);
            }
            catch (error) {
                console.log(error.message)
                setIsError(true)
                setErrorMessage(error?.response?.data?.message ?? error.message)
            }
            setLoading(false);
        }
    }

    return (
        <>
            <Grid item xs={col_size}>
                <Typography style={{ fontSize: "14px", fontWeight: "600", lineHeight: "18px" }}>
                    {label}
                    {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
                </Typography>
                <TextField
                    style={{ marginLeft: "0" }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={<Typography
                        variant="caption"
                        color="error"
                        style={{ marginTop: "5px", marginLeft: "0" }}
                    >
                        {error_message}
                    </Typography>}
                    error={is_error}
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                        style: {
                            borderRadius: "12px",
                            display: "flex",
                            height: "44px",
                            padding: "12px",
                            alignItems: "center",
                            gap: "12px",
                            border: is_error ? "1px solid red" : "none",
                        },
                    }}
                    FormHelperTextProps={{
                        style: { marginLeft: "0" }
                    }}
                    required={required}
                    disabled={disabled || loading}
                />
                {
                    helperText?.length > 0 &&
                    <Typography
                        variant="caption"
                        color="green"
                        style={{ marginTop: "5px", marginLeft: "0" }}
                    >
                        {helperText}
                    </Typography>
                }
            </Grid>
            <Grid xs={button?.col_size} sx={{ display: 'inline-flex', alignItems: 'flex-end', justifyContent: 'flex-end', padding: '16.5px 14px' }}>
                <Button
                    variant="contained"
                    style={{
                        borderRadius: "28px",
                        padding: "10px",
                        height: "40px",
                        fontSize: '12px',
                        textTransform: "capitalize",
                    }}
                    onClick={submitHandler}
                    disabled={disabled || loading}
                >
                    {t("save")}
                </Button>
            </Grid>
        </>
    );
};

export default CustomInputWithButton;
