import React, {useState} from 'react';
import { Modal, Box, Button, TextField } from '@mui/material';

const CustomCalendar = ({ open, onClose, onApply, onClear }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: '330px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#FFF',
          borderRadius: '16px',
          boxShadow: '0px 8px 32px 0px rgba(16, 24, 43, 0.16)',
          padding: '16px',
        }}
      >
        <TextField
          id="date"
          label="Select Date"
          type="date"
          fullWidth
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* Apply and Clear buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          <Button variant="outlined" onClick={onClear}>
            Clear
          </Button>
          <Button variant="contained" color="primary" onClick={() => onApply(selectedDate)}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomCalendar;
