


export const PlugpointOn = () => {

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <style>{`.a,.c{fill:#1776FE;}.b{fill:#fff;}.c{opacity:0;}`}</style>
                </defs>
                <g transform="translate(-850 -576)">
                    <circle class="a" cx="25" cy="25" r="25" transform="translate(850 576)" />
                    <g transform="translate(850 576)">
                        <path class="b" d="M155.119,353.67H139.864a4,4,0,0,0-4,4v16.778a4.006,4.006,0,0,0,4,4h15.255a4.006,4.006,0,0,0,4-4V357.674A4.008,4.008,0,0,0,155.119,353.67ZM142.9,374.746a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,142.9,374.746Zm4.392-7.94a2.4,2.4,0,1,1,2.4-2.4A2.394,2.394,0,0,1,147.289,366.806Zm4.79,7.94a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,152.079,374.746Z" transform="translate(-123.051 -340.67)" />
                    </g>
                    <circle class="c" cx="25" cy="25" r="25" transform="translate(850 576)" />
                </g>
            </svg>
        </>
    );
}