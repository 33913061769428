import React from "react";

const TotalBooking = () => {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 110 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6118_42874)">
          <rect x="15" y="10.5" width="80" height="80" rx="40" fill="white" />
          <rect
            x="13.5"
            y="9"
            width="83"
            height="83"
            rx="41.5"
            stroke="#B5CBF7"
            stroke-width="3"
          />
          <g filter="url(#filter1_b_6118_42874)">
            <path
              d="M51 36.5V38.5H59V36.5C59 35.4375 59.875 34.5 61 34.5C62.0625 34.5 63 35.4375 63 36.5V38.5H66C67.625 38.5 69 39.875 69 41.5V44.5H41V41.5C41 39.875 42.3125 38.5 44 38.5H47V36.5C47 35.4375 47.875 34.5 49 34.5C50.0625 34.5 51 35.4375 51 36.5ZM41 46.5H69V63.5C69 65.1875 67.625 66.5 66 66.5H44C42.3125 66.5 41 65.1875 41 63.5V46.5ZM46 50.5C45.4375 50.5 45 51 45 51.5V57.5C45 58.0625 45.4375 58.5 46 58.5H52C52.5 58.5 53 58.0625 53 57.5V51.5C53 51 52.5 50.5 52 50.5H46Z"
              fill="#467CEB"
            />
          </g>
        </g>
        <circle
          cx="55"
          cy="50.5"
          r="49.8613"
          stroke="#467CEB"
          stroke-width="0.277344"
          stroke-dasharray="1.66 1.66"
        />
        <defs>
          <filter
            id="filter0_d_6118_42874"
            x="0"
            y="7.5"
            width="110"
            height="114"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.437264 0 0 0 0 0.604627 0 0 0 0 0.889144 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6118_42874"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6118_42874"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_6118_42874"
            x="28.4783"
            y="21.9783"
            width="53.0435"
            height="57.0435"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.26087" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6118_42874"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6118_42874"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default TotalBooking;
