import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchStationList = createAsyncThunk('stationList/fetchStationList', async () => {
  try {
    const response = await api.get('prometheus/facility/list/');
    return response.data;
  } catch (error) {
    console.error('Error fetching station list:', error);
    throw error;
  }
});

const StationListSlice = createSlice({
  name: 'stationList',
  initialState: {
    data: [],
    isLoading: false,
    selectedFacility: { name: 'All', id: [] },
    facilities: [],
    error: null,
  },
  reducers: {
    setSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload;
      if (action.payload.name!=='All')
        localStorage.setItem('currency', action.payload.currency);
      else
        localStorage.setItem('currency', localStorage.getItem('userCurrency'))
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        if (action.payload?.data?.length > 0) {
          const allFilter = { name: 'All', id: action.payload.data.map(item => item.id) };
          state.facilities = [allFilter, ...(action.payload.data)];
          state.selectedFacility = allFilter;
        }
      })
      .addCase(fetchStationList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedFacility } = StationListSlice.actions;
export default StationListSlice.reducer;
