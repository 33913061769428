import React, { useState } from "react";
import FleetVehicleCard from "./FleetVehicleCard.js";

const DynamicCardBox = ({ handleCardClick, searchedDealerList, subDealerData, cardType, loading, type }) => {
    const [selectedCardId, setSelectedCardId] = useState(null);
    const handleCardClickChange = (id, cardType) => {
        setSelectedCardId(id);
        handleCardClick(id, cardType)
    }
    return (
        <>
            {searchedDealerList?.map((dealer, index) => (
                <FleetVehicleCard
                    dealer={dealer}
                    key={index}
                    onClick={handleCardClickChange}
                    isSelected={selectedCardId === dealer.id}
                    cardType={cardType}
                    loading={loading}
                    type={type}
                />
            ))}

            {subDealerData && subDealerData?.map((subdealer, index) => (
                <FleetVehicleCard
                    subdealer={subdealer}
                    key={index}
                    onClick={handleCardClickChange}
                    isSelected={selectedCardId === subdealer.id}
                    cardType={cardType}
                    loading={loading}
                    type={type}
                />
            ))}
        </>
    );
}

export default DynamicCardBox;
