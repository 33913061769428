import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  Box, Typography, Grid
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const chartSettings = {
  xAxis: [
    {
      scaleType: "band",
      dataKey: "created_at"
    },
  ],
  yAxis: [
    {
      scaleType: "linear"
    }
  ],
  height: 220
};

export const isLessThanTwoMonths = (start, end) => {
  const diffInYears = end.getFullYear() - start.getFullYear();
  const diffInMonths = end.getMonth() - start.getMonth() + diffInYears * 12;
  return diffInMonths < 2;
}

const calculateProfit = (facilityFilter,) => [

]

export function generateTransactionChartPayload(transactions, startDate, endDate, is_fleet = false, facilityFilter = null) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const oneMonthInMillis = isLessThanTwoMonths(start, end);
  const datesInRange = generateDatesInRange(start, end);

  if (oneMonthInMillis) {
    const result = datesInRange.reduce((acc, date) => {
      const key = formatDate(date);
      const dailyTransactions = transactions.filter(transaction => formatDate(new Date(transaction.created_at)) === key)
      const count = dailyTransactions.length;
      if (!is_fleet)
        acc.push({ created_at: key, count });
      else {
        const revenue = (is_fleet ?
          dailyTransactions.reduce((sum, transaction) => sum + calculateUnitsConsumed(transaction.units_consumed,
            transaction.meter_start_at,
            transaction.meter_stop_at,
            transaction.power_type,
            facilityFilter), 0) : 0)/1000;
        const units_consumed = (is_fleet ?
          dailyTransactions.reduce((sum, transaction) => sum + calculateUnitsConsumed(transaction.units_consumed,
            transaction.meter_start_at,
            transaction.meter_stop_at,
            transaction.power_type,
            facilityFilter,
            false), 0) : 0)/1000;
        const electricity = Number(((units_consumed ) * facilityFilter?.price_per_unit_electricity).toFixed(2));
        const profit = revenue - electricity;
        const company_profit =Number((checkCompanyProfit(facilityFilter,units_consumed,profit)).toFixed(2));
        acc.push({ created_at: key, count, revenue: revenue ,profit: profit- company_profit, company_profit ,electricity,units_consumed});
      }
      return acc;
    }, []);

    return result.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  } else {
    const result = transactions.reduce((acc, transaction) => {
      const date = new Date(transaction.created_at);
      const key = `${getMonthName(date.getMonth())}-${date.getFullYear()}`;
      acc[key] = acc[key] || { count: 0, units_consumed: 0 ,revenue:0, electricity:0,company_profit:0,profit:0};
      acc[key].count += 1;
      if(is_fleet){
      acc[key].revenue += (calculateUnitsConsumed(transaction.units_consumed, transaction.meter_start_at, transaction.meter_stop_at, transaction.power_type, facilityFilter))/1000 ;
      acc[key].units_consumed += (calculateUnitsConsumed(transaction.units_consumed,
        transaction.meter_start_at,
        transaction.meter_stop_at,
        transaction.power_type,
        facilityFilter,
        false))/1000;
      acc[key].electricity=Number(((acc[key].units_consumed ) * (facilityFilter?.price_per_unit_electricity ?? 0)).toFixed(2));
      const profit=acc[key].revenue - acc[key].electricity;
      acc[key].company_profit=Number((checkCompanyProfit(facilityFilter,acc[key].units_consumed,profit)).toFixed(2));
      acc[key].profit=profit - acc[key].company_profit;
      }
      return acc;
    }, {});

    const allMonths = generateAllMonthsInRange(start, end);

    let finalResult = allMonths.map(month => (
      {
        created_at: month,
        count: result[month] ? result[month].count : 0,
        revenue: is_fleet ? (result[month] ? result[month].revenue  : 0) : 0,
        electricity: is_fleet ? (result[month] ? result[month].electricity  : 0): 0,
        company_profit: is_fleet ? (result[month] ? result[month].company_profit  : 0) : 0,
        profit:  is_fleet ? (result[month] ? result[month].profit : 0) : 0
      }
    ));

    return finalResult.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  }
}

function givePrice(power_type, facilityFilter) {
  if (power_type === 'AC')
    return facilityFilter?.price_per_unit_ac ?? 0;
  else
    return facilityFilter?.price_per_unit_dc ?? 0;
}

const checkCompanyProfit=(facilityFilter, units_consumed, profit)=>{
if(facilityFilter?.is_profit_sharing){
  if(facilityFilter?.profit_sharing_type==='Fixed')
  return units_consumed * (facilityFilter?.profit_sharing_amount ?? 0);
return (profit * (facilityFilter?.profit_sharing_amount ?? 0)) /100 
}
return 0;
}

function formatDate(date) {
  const day = getMonthName(date.getMonth()).substring(0, 3) + ' ' + date.getDate().toString().padStart(2, '0');
  return `${day}`;
}

export function calculateUnitsConsumed(units, start, stop, power_type, facilityFilter, includePrice = true) {
  if (units >= 0)
    return includePrice ? units * givePrice(power_type, facilityFilter) : units;
  else if (start && stop && stop > start) {
    return includePrice ? (stop - start) * givePrice(power_type, facilityFilter) : (stop - start);
  }
  else
    return 0;
}

export function getMonthName(monthIndex) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[monthIndex];
}

function generateDatesInRange(start, end) {
  const datesInRange = [];
  const currentDate = new Date(start);

  while (currentDate <= end) {
    datesInRange.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesInRange;
}

function generateAllMonthsInRange(start, end) {
  const allMonths = [];
  const currentDate = new Date(start);

  while (currentDate <= end) {
    const key = `${getMonthName(currentDate.getMonth())}-${currentDate.getFullYear()}`;
    allMonths.push(key);
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return allMonths;
}


const TransactionChart = ({ dateFilter, facilityFilter, data, height=null }) => {

  const [transactionDates, setTransactionDates] = useState([]);
  if(height)
  chartSettings.height=height;

  const { t } = useTranslation();

  useEffect(() => {
    if (data?.view_facility_revenue) {
      const newData = generateTransactionChartPayload(data.view_facility_revenue, dateFilter?.start?.toISOString(), dateFilter?.end?.toISOString());
      setTransactionDates(newData);
    }
  }, [data, dateFilter, facilityFilter])



  return (
    <Box bgcolor={"white"} p={3} borderRadius={"16px"} padding={"24px 24px 8px"}>
      <Typography variant="subtitle" fontSize='20px' fontWeight='600'>
      {t('chargingSessions')}
      </Typography>
      <Grid container flexDirection='column'>
        <Grid item display='flex'>
          <Grid item display='flex' alignItems='center' sx={{ transform: 'rotate(-90deg)' }} fontSize='12px' fontWeight='600' textTransform='uppercase'>
          {t('sessions')}
          </Grid>
          <LineChart
            dataset={transactionDates}
            {...chartSettings}
            series={[{ dataKey: "count", color: '#467CEB'}]}
          />
        </Grid>
        <Grid item display='flex' justifyContent='center' fontSize='12px' fontWeight='600' textTransform='uppercase'>
        {t('date')}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionChart;
